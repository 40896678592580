import React, {useState} from 'react';
import TicketPrice from "./TicketPrice";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import {IAirFare, IFlight} from "../../../../../types/flightStoreTypes";
import FlyFragment from "./FlyFragment";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";
import {addFlight, setActiveFare, setFlightID} from "../../../../../store/actions/airFareActions";
import SeatClass from "./SeatClass";
import {useMutation} from "@apollo/react-hooks";
import {
  ADD_AIR_FARE,
  ALL_AIR_TICKETS,
  TICKETS_BY_DATE,
  UPDATE_AIR_FARE
} from "../../../../../graphql/mutations/AirFareMutations";
import {getDateTime} from "../../../../../utils/CommonFunctions";
import {AirFaresType, AllFaresType} from "../../../../../types/FlightTypes";
import {removeTypeNameArray} from "../../../../../utils/common";
import BaggageSize from "./BaggageSize";
import InFlightFacilities from "./InFlightFacilities";

type TicketProps = {
  tourID: string
}

const Ticket: React.FC<TicketProps> = (props) => {
  const activeFare: IAirFare | null = useSelector((state: AppState) => state.flight.activeFare);
  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();

  // const tourID = '5e09bf2612a6112a343ec5ec';
  // const tourID : string | null = useSelector((state: AppState) => state.tour.id);
  const {tourID} = props;

  const dispatchActiveFare = (ticketDetails: IAirFare) => {
    // console.log(ticketDetails);
    // const ticketDetails: IAirFare = {...ticket};
    if (ticketDetails.flights) {
      const flights = ticketDetails.flights;
      flights.forEach((flight: IFlight, idx) => {
        // ticketDetails.flights.map((flight: IFlight) => {
        flight.departureDateTime = getDateTime(flight.departureDateTime);
        flight.arrivalDateTime = getDateTime(flight.arrivalDateTime);

        flight = {
          orderNumber: flight.orderNumber,
          departurePort: flight.departurePort, departureDateTime: flight.departureDateTime,
          arrivalPort: flight.arrivalPort, arrivalDateTime: flight.arrivalDateTime,
          carrier: flight.carrier
        };

        flights[idx] = flight;
      });
    }
    dispatch(setActiveFare(ticketDetails));
  };

  //add a new air fare
  const [addAirFare] = useMutation(ADD_AIR_FARE, {
    onCompleted: async data => {
      // console.log(data);
      dispatch(setFlightID(data.addAirFare.fareID));
      const ticketDetails: IAirFare = data.addAirFare;
      dispatchActiveFare(ticketDetails);
      setIsSaving(false);
    },
    onError: error => {
      setIsSaving(false);
      alert('problem saving changes.');
    },
    update: (cache, {data: {addAirFare}}) => {
      const departureDate = getDateTime(addAirFare.availableStartDate);
      departureDate.setHours(0);
      departureDate.setMinutes(0);
      departureDate.setSeconds(0);

      try {
        const airTicketsQueryType = cache.readQuery<AirFaresType>({
          query: TICKETS_BY_DATE,
          variables: {tourID: tourID, departure: departureDate}
        });


        if (airTicketsQueryType) {
          const {airTicketsByDate} = airTicketsQueryType;
          // console.log(airTicketsQueryType);
          const newTicketsByDate = airTicketsByDate ? airTicketsByDate.concat([addAirFare]) : [addAirFare];

          cache.writeQuery({
            query: TICKETS_BY_DATE,
            variables: {tourID: tourID, departure: departureDate},
            data: {airTicketsByDate: newTicketsByDate},
          });
        }
      } catch (e) {
        // no tickets for tourID & on this departure exception from readQuery'
        cache.writeQuery({
          query: TICKETS_BY_DATE,
          variables: {tourID: tourID, departure: departureDate},
          data: {airTicketsByDate: [addAirFare]},
        });
      }


      const allTicketsQueryType = cache.readQuery<AllFaresType>({
        query: ALL_AIR_TICKETS,
        variables: {tourID: tourID}
      });

      if (allTicketsQueryType) {
        const {airTickets} = allTicketsQueryType;
        const airTicket = (({__typename, tour, availableType, availableStartDate, availableEndDate, seatClass}) =>
          ({__typename, tour, availableType, availableStartDate, availableEndDate, seatClass}))(addAirFare);

        const newTicket = {_id: addAirFare.fareID, ...airTicket};

        // console.log(newTicket);

        cache.writeQuery({
          query: ALL_AIR_TICKETS,
          variables: {tourID: tourID},
          data: {airTickets: airTickets.concat([newTicket])},
        });
      }

    }
  });

  // const updateCache =

  //update air fare
  const [updateAirFare] = useMutation(UPDATE_AIR_FARE, {
    onCompleted: async data => {
      // console.log(data);
      dispatch(setFlightID(data.updateFare.fareID));
      const ticketDetails: IAirFare = data.updateFare;
      dispatchActiveFare(ticketDetails);
      setIsSaving(false);
    },
    onError: error => {
      setIsSaving(false);
      alert('problem saving changes.');
    },
    update: (cache, {data: {updateFare}}) => {
      const departureDate = getDateTime(updateFare.availableStartDate);
      departureDate.setHours(0);
      departureDate.setMinutes(0);
      departureDate.setSeconds(0);

      const airTicketsQueryType = cache.readQuery<AirFaresType>({
        query: TICKETS_BY_DATE,
        variables: {tourID: tourID, departure: departureDate}
      });

      if (airTicketsQueryType) {
        const {airTicketsByDate} = airTicketsQueryType;
        const updatedTickets = airTicketsByDate.slice();

        const cacheTicketIndex = updatedTickets.findIndex((ticket: IAirFare) => ticket.fareID === updateFare.fareID);
        updatedTickets[cacheTicketIndex] = updateFare;

        // console.log(airTicketsQueryType);
        cache.writeQuery({
          query: TICKETS_BY_DATE,
          variables: {tourID: tourID, departure: departureDate},
          data: {airTicketsByDate: updatedTickets},
        });
      }
    }
  });

  const getFlightsWithoutTypename = (flights: IFlight[]) => {
    return flights.map((flight: any) => {
      const {__typename, ...flightData} = flight;
      return flightData;
    })
  };

  const saveAirFare = () => {
    if (tourID && activeFare) {
      setIsSaving(true);

      const flights: IFlight[] | null = activeFare.flights ? getFlightsWithoutTypename(activeFare.flights) : null;

      if (!activeFare.fareID) { //save new AirFare
        addAirFare({
          variables: {
            tour: tourID,
            activeDateType: activeFare.availableType,
            availableStartDate: activeFare.availableStartDate,
            availableEndDate: activeFare.availableEndDate,
            flights: flights,
            price: {adult: activeFare.price.adult, master: activeFare.price.kid},
            seatClass: activeFare.seatClass,
            meals: activeFare.meals,
            wifi: activeFare.wifi,
            baggage: activeFare.baggage
          }
        });
      } else { //update existing
        // console.log('update AF');
        updateAirFare({
          variables: {
            id: activeFare.fareID,
            tour: tourID,
            activeDateType: activeFare.availableType,
            availableStartDate: activeFare.availableStartDate,
            availableEndDate: activeFare.availableEndDate,
            flights: activeFare.flights,
            price: {adult: activeFare.price.adult, master: activeFare.price.kid},
            seatClass: activeFare.seatClass,
            meals: activeFare.meals,
            wifi: activeFare.wifi,
            baggage: activeFare.baggage
          }
        });
      }

    }
  };

  const loadFlyFragments = (activeFare: IAirFare | null) => {
    if (activeFare && activeFare.flights) {
      return (
        activeFare.flights.map((flight, idx) => {
          return <FlyFragment key={idx} flight={flight}/>;
        })
      );
    }

    return;
  };

  const addFlightConnect = () => {
    dispatch(addFlight());
  };

  if (activeFare) {
    return (
      <React.Fragment>

        {loadFlyFragments(activeFare)}

        <Row>
          <Col xs={{span: 2, offset: 10}} className='text-right mb-2'>
            <i className='feather icon-plus add-flight-connect' onClick={addFlightConnect}/>
          </Col>
        </Row>

        <TicketPrice/>

        <Row>
          <Col md={6}>
            <SeatClass/>
          </Col>
          <Col md={5} className='flight-baggage'>
            <BaggageSize activeFare={activeFare}/>
          </Col>
        </Row>

        <Row>
          <Col md={6} className='pt-2 mt-3'>
            <InFlightFacilities activeFare={activeFare}/>
          </Col>

          <Col md={6} className='pt-1 pr-1 text-right mt-3'>
            {!isSaving &&
            <Button variant='primary' className='flight-done' onClick={saveAirFare}>
              <i className='feather icon-check-circle'/>
              Done
            </Button>
            }
            {isSaving &&
            <Button variant='primary' className='flight-done' disabled>
              <span className="spinner-border spinner-border-sm mr-1" role="status"/>Saving...
            </Button>
            }
          </Col>
        </Row>

      </React.Fragment>
    );
  }

  return <React.Fragment></React.Fragment>;
};

export default Ticket;