import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Row, Form, Alert} from "react-bootstrap";
import EditorClose from "../EditorClose";
import TourTitleEdit from "./TourTitleEdit";
import Destinations from "./Destinations";
import ErrorSummary from "./ErrorSummary";
import NumberFormat from 'react-number-format';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";
import {setPublishStatus, setTourPrices} from "../../../../store/actions/tourlineActions";
import {Prices} from "../../../../types/storeTypes";
import {useMutation} from "@apollo/react-hooks";
import {SET_PRICES_MUTATION} from "../../../../graphql/mutations/NewTourSave";
import {PROCESSING_TIMEOUT} from "../../../../constants/values";
import {getAlertWarning} from "../../../../utils/warning/Alerts";
import {getLoadingSpan} from "../../../../utils/loading/loading";
import {RECENT_TOURS} from "../../../../graphql/queries/AllTours";
import {LIST_TOURS} from "../../../../graphql/queries/TourQueries";


const SummaryEditor: React.FC = () => {
  const tourPrice: Prices = useSelector((state: AppState) => state.tour.price);
  const [price, setPrice] = useState(tourPrice.originalPrice);
  const [marketPrice, setMarketPrice] = useState(tourPrice.market);
  const [discountedPrice, setDiscountedPrice] = useState(tourPrice.discountedPrice);
  const [processing, setProcessing] = useState(false);
  const [isStartDisabled, setIsStartDisabled] = useState(true); //set status of start button.

  const [validated, setValidated] = useState(false);
  const [isHighPrice, setIsHighPrice] = useState(false); //if discounted price is too high (> 95% of market)

  const initMinPriceFeedback = 'Please provide a price that is lower than market min.';
  const highMinPriceFeedback = 'Please provide a price that is at least 5% lower than market price';
  const [minPriceFeedback, setMinPriceFeedback] = useState(initMinPriceFeedback);

  const tourID: string | null = useSelector((state: AppState) => state.tour.id);
  const isPublished: boolean = useSelector((state: AppState) => state.tour.published);
  const dispatch = useDispatch();

  const defaultOriginalPrice: number | string = tourPrice.originalPrice > 0 ? tourPrice.originalPrice : '';
  const defaultMarketPrice: number | string = tourPrice.market > 0 ? tourPrice.market : '';
  const defaultDiscountedPrice: number | string = tourPrice.discountedPrice > 0 ? tourPrice.discountedPrice : '';

  const [publishPrices] = useMutation(SET_PRICES_MUTATION, {
    onCompleted: data => {
      // console.log(data);
      const publishedState = data ? data.setPrices.published : false;
      if (publishedState) {
        dispatch(setPublishStatus(publishedState));
      }

      setTimeout(() => {
        setProcessing(false);
      }, PROCESSING_TIMEOUT);
    },
    refetchQueries: ({data: {setPrices}}) => [
      {query: RECENT_TOURS}, /**to show in all tours recent on top */
      // {query: LIST_TOURS} /**to show in all tours table - work with filter recent */
    ],

    update: (cache, {data: {setPrices}}) => {
      // const recentToursQuery =
      // console.log('clearing recent cache');
      // cache.writeQuery({
      //   query: RECENT_TOURS,
      //   data: {recentTours: null}
      // })
    },

    onError: ({graphQLErrors, networkError}) => {
    // onError: error => {
    //   console.log(graphQLErrors);
      setProcessing(false);
      if (graphQLErrors) {
        alert(graphQLErrors[0].message);
        return;
      }
      alert('problem saving changes.');
    }
  });

  useEffect(() => {
    setPrice(tourPrice.originalPrice);
    setDiscountedPrice(tourPrice.discountedPrice);
  }, [tourPrice]);

  const savePrices = async () => {
    // await checkPrices();
    // if (isHighPrice) {
    //   alert('set discount price');
    //   return;
    // }

    const currentPrices: Prices = {originalPrice: price, market: marketPrice, discountedPrice: discountedPrice};
    setProcessing(true);

    // console.log(currentPrices);
    const tourPrices = (price > 0 && discountedPrice > 0) ? currentPrices : null;
    if (tourPrices) {
      dispatch(setTourPrices(tourPrices));
    }

    publishPrices({
      variables: {
        id: tourID,
        price: {
          original: currentPrices.originalPrice, market: currentPrices.market,
          price: currentPrices.discountedPrice
        }
      }
    });
  };

  const getPublishStateWarning = () => {
    const warningMsg = 'Warning: click start to publish changes. Tour will NOT be visible to users until you start it.';
    return (
      <Col xs={12} className='mt-3 mb-2 text-left'>
        {/*{getAlertWarning(warningMsg)}*/}
      </Col>
    );
  };

  const handleLowestPriceAcknowledgement = () => {
    setIsStartDisabled(!isStartDisabled);
  };

  const checkPrices = async () => {
    if (marketPrice * .95 >= discountedPrice) { // discount price lower than at least 5% market min
      setIsHighPrice(false);
      setValidated(true);
      return;
    }

    setIsHighPrice(true);
    setMinPriceFeedback(highMinPriceFeedback);
    setValidated(true);
  };

  return (
    <Card className='editors summary-editor'>
      <Card.Body className='wide-card-body'>

        <EditorClose/>

        <Row>
          <Col xs={11} className='pl-0 pr-0'>
            <TourTitleEdit/>
          </Col>
        </Row>
        <Destinations/>

        <ErrorSummary/>

        <Button variant='outline-secondary' size='sm' className='price-btn'><i
          className='feather icon-tag'/>Price</Button>

        <Row>
          {/*<Col md={{span: 7, offset: 5}}>*/}
          <Col md={7} className='mt-3'>
            <label className='into-label'>Tour Pricing</label>
            <hr className='mt-0'/>
          </Col>
          {/*<Col md={5}/>*/}
          <Col md={7} className='tour-pricing'>
            <Form noValidate validated={validated}>

              <Row>
                <Form.Label column sm={5}>
                  Original Price* ($)
                </Form.Label>
                <Col sm={7} className='pl-0'>
                  <NumberFormat className="form-control form-control-sm" placeholder="before discounts"
                                thousandSeparator
                                prefix="$ "
                                required
                                defaultValue={defaultOriginalPrice}
                    // suffix=" /-"
                                onValueChange={values => {
                                  setPrice(values.floatValue);
                                }}/>

                  <Form.Control.Feedback type="invalid">
                    Please provide original price
                  </Form.Control.Feedback>
                </Col>
              </Row>

              <Row>
                <Form.Label column sm={5} className='mt-2'>
                  Market Min. Price* ($)
                </Form.Label>
                <Col sm={7} className='mt-2 pl-0'>
                  <NumberFormat className="form-control form-control-sm" placeholder="min price elsewhere"
                                thousandSeparator prefix="$ "
                                required
                                defaultValue={defaultMarketPrice}
                    // suffix=" /-"
                                onValueChange={values => {
                                  setMarketPrice(values.floatValue);
                                }}/>
                  <Form.Control.Feedback type="invalid">
                    Please provide minimum price available elsewhere
                  </Form.Control.Feedback>
                </Col>
              </Row>

            </Form>

            <Row>
              <Form.Label column sm={5} className='mt-2'>
                Discounted Price* ($)
              </Form.Label>
              <Col sm={7} className='mt-2 pl-0'>
                <NumberFormat
                  className={`form-control form-control-sm 
                  ${validated && isHighPrice ? 'is-invalid' : validated && !isHighPrice ? 'is-valid' : ''}`}
                  placeholder="amount you want" thousandSeparator
                  prefix="$ "
                  required
                  defaultValue={defaultDiscountedPrice}
                  // suffix=" /-"
                  onValueChange={values => {
                    setDiscountedPrice(values.floatValue);
                  }}
                  onBlur={checkPrices}
                />
                <Form.Control.Feedback type="invalid"
                                       className={validated && isHighPrice ? 'dis-block' : ''}>
                  {minPriceFeedback}
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Col>

          {/*<Row>*/}
          <Col xs={12} className='mt-4'>
            <div className="checkbox d-inline">
              <Form.Control type="checkbox" name="checkbox-inline-1" id="checkbox-lowest-price"
                            defaultChecked={!isStartDisabled}
                            onChange={handleLowestPriceAcknowledgement}
              />
              <Form.Label htmlFor="checkbox-lowest-price" className="cr">
                I acknowledge that discounted price is the lowest price for this tour, available in the market & it
                is not available elsewhere lower than this.
              </Form.Label>
            </div>
          </Col>
          {/*</Row>*/}

          {!isPublished && getPublishStateWarning()}
          <Col xs={12} className='text-right'>
            <Button variant='info' className='processing-button'
                    onClick={savePrices}
                    disabled={isStartDisabled || processing}>
              {processing && getLoadingSpan()}
              {!processing && <i className='feather icon-check-circle'/>}
              Start
            </Button>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
};

export default SummaryEditor;