import {PassportData} from "../types/bookingStoreTypes";

export const WEBSITE_HOME = '/';

export const ANDROID_APP = 'https://www.softvessel.com/';
export const APPLE_APP = 'https://www.softvessel.com/';

export const BILLING_ADDRESS_PERSONAL = 'personal';
export const BILLING_ADDRESS_DIFFERENT = 'different';

export const UPLOAD_STATUS = {
  UPLOADING: 'uploading',
  UPLOAD_SUCCESS: 'success',
  UPLOAD_ERROR: 'error',
  REMOVE_PENDING: 'remove_pending',
  REMOVED: 'removed'
};

export enum PaxType  {
  ADULT = 'adult',
  CHILD = 'child',
}

export const sampleDataPassport: PassportData = {
  fullName: '',
  passportNumber: ''
};

//show discount percentage label if discount is greater then MIN_DISCOUNT_PERCENTAGE_IN_SEARCH_RESULTS
export const MIN_DISCOUNT_PERCENTAGE_IN_SEARCH_RESULTS = 50;