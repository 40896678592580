import React from "react";
import { Col, Form, Row } from "react-bootstrap";

class TableCustomSearch extends React.Component<any, any> {
    handleChange = (e: any) => {
        this.props.onSearch(e.target.value);
    };

    render() {
        return (
            <Row>
                <Col xs={{ span: 8, offset: 4 }} md={{ span: 3, offset: 9 }} className='table-search'>
                    <Form.Control
                        placeholder="Search"
                        onChange={(e: any) => this.handleChange(e)}
                    />
                </Col>
            </Row>
        );
    }
}

export default TableCustomSearch;
