import React, {useEffect, useState} from 'react';
import moment from "moment";

type UploadCountdownProps = {
  uploadDeadline: Date,
  message?: string
}

const UploadCountdown: React.FC<UploadCountdownProps> = (props) => {
  const [endingIn, setEndingIn] = useState("");

  const {message} = props;

  const offerEnd: number = props.uploadDeadline.getTime() / 1000;
  // console.log(offerEnd);
  // const offerEnd = 1594381526;

  const calcRemainingTime = () => {
    let endTime: any = moment.unix(offerEnd);

    let now: any = moment();
    let remaining = moment.duration(endTime - now);

    let days =
      remaining.days() > 0
        ? `${remaining
          .days()
          .toString() + 'd '}`
        : "";
    let hours =
      remaining.hours() > 0
        ? `${remaining
          .hours()
          .toString()
          .padStart(2, "0")}:`
        : "00:";
    let minutes =
      remaining.minutes() > 0
        ? `${remaining
          .minutes()
          .toString()
          .padStart(2, "0")}:`
        : "00:";
    let seconds =
      remaining.seconds() > 0
        ? `${remaining
          .seconds()
          .toString()
          .padStart(2, "0")}`
        : "00";

    setEndingIn(`${days + hours + minutes + seconds}`);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      calcRemainingTime();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [offerEnd]);


  return (
    <span className='upload-countdown'>
      <i className='feather ic-clock'/>
      <label className='end-in'>{message ? message + ' ' : ''}{endingIn}</label>
    </span>
  );
};

export default UploadCountdown;