import React from 'react';
import {Row, Col} from 'react-bootstrap';
import UcFirst from "../../../../App/components/UcFirst";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";

const ErrorSummary: React.FC = () => {
  const errorSummary = useSelector((state: AppState) => state.tour.errorSummary);
  console.log(errorSummary);

  return (
    <Row>
      <Col>
        {errorSummary.length > 0 &&
        <ul className='error-summary'>
          {
            errorSummary.map((error, idx) => {
              const err = error.split(':');
              return <li key={idx}><strong>{err[0]}:</strong><label><UcFirst text={err[1]}/></label></li>;

            })
          }
        </ul>
        }
      </Col>
    </Row>
  );
};

export default ErrorSummary;