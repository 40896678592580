export const customDropdownStyles = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    // color: '#00aba9',
    paddingTop: 2,
    paddingBottom: 2
  }),
  control: (provided: any) => {
    return {
      ...provided,
      borderRadius: 2,
      minHeight: 25,
      fontSize: '.8rem'
    };
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: '#00aba9',
      minHeight: 25,

    };
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: 1,
    };
  },
  option: (provided: any, state: any) => {
    // console.log(provided);
    return {
      ...provided,
      fontSize: '.8rem',
      paddingTop: 2,
      paddingBottom: 2,
    };
  },
};


export const customDropdownStylesNoColor = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    // color: '#ffffff',
    color: '#00aba9',
    paddingTop: 2,
    paddingBottom: 2
  }),
  control: (provided: any) => {
    return {
      ...provided,
      borderRadius: 2,
      minHeight: 25,
      fontSize: '.8rem'
    };
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      minHeight: 25,

    };
  },

  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: 1,
    };
  },
  option: (provided: any, state: any) => {
    // console.log(provided);
    return {
      ...provided,
      fontSize: '.8rem',
      paddingTop: 2,
      paddingBottom: 2
    };
  },
};


export const filterDropdownStylesNoColor = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    // color: '#ffffff',
    color: '#a29bfe',
    paddingTop: 2,
    paddingBottom: 2
  }),
  control: (provided: any) => {
    return {
      ...provided,
      borderRadius: 2,
      minHeight: 34,
      fontSize: '.8rem',
      color: '#a29bfe'
    };
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      minHeight: 25,
      cursor: 'pointer'

    };
  },

  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: 1,
    };
  },
  option: (provided: any, state: any) => {
    // console.log(provided);
    return {
      ...provided,
      fontSize: '.9rem',
      paddingTop: 3,
      paddingBottom: 3
    };
  },

};

export const airportDropdownStyles = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: '#ffffff',
    // color: '#00aba9',
    paddingTop: 2,
    paddingBottom: 2
  }),
  control: (provided: any) => {
    return {
      ...provided,
      borderRadius: 2,
      minHeight: 25,
      fontSize: '.8rem'
    };
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: '#00aba9',
      minHeight: 25,
    };
  },
  loadingIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      backgroundColor: '#ffffff',
      minHeight: 27,
      marginRight: 0,
      paddingTop: 10
    };
  },
  loadingMessage:  (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '.8rem',
      color: '#34495e',
    };
  },
  noOptionsMessage:  (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '.8rem',
      color: '#34495e',
    };
  },
  indicatorSeparator: () => ({
    display: 'none'
  }),
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      paddingTop: 0,
      paddingBottom: 0
    };
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: 1,
      width: 200
    };
  },
  option: (provided: any, state: any) => {
    return {
      ...provided,
      fontSize: '.7rem',
      paddingTop: 2,
      paddingBottom: 3,
      color: '#34495e',
    };
  },
};


export const dashboardDropdownStylesNoColor = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    // color: '#ffffff',
    color: '#00aba9',
    paddingTop: 2,
    paddingBottom: 2
  }),
  control: (provided: any) => {
    return {
      ...provided,
      borderRadius: 2,
      minHeight: 25,
      fontSize: '.8rem'
    };
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      minHeight: 25,

    };
  },
  clearIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      minHeight: 25,
      paddingTop: 2,
      paddingBottom: 2
    };
  },

  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      paddingTop: 2,
      paddingBottom: 2
    };
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: 1,
    };
  },
  option: (provided: any, state: any) => {
    // console.log(provided);
    return {
      ...provided,
      fontSize: '.8rem',
      paddingTop: 2,
      paddingBottom: 2
    };
  },
};

export const dashboardGroupedDropdownStylesNoColor = {
  dropdownIndicator: (provided: any) => ({
    ...provided,
    // color: '#ffffff',
    color: '#00aba9',
    paddingTop: 2,
    paddingBottom: 2
  }),
  control: (provided: any) => {
    return {
      ...provided,
      borderRadius: 2,
      minHeight: 25,
      fontSize: '.8rem'
    };
  },
  indicatorsContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      minHeight: 25,

    };
  },
  clearIndicator: (provided: any, state: any) => {
    return {
      ...provided,
      minHeight: 25,
      paddingTop: 2,
      paddingBottom: 2
    };
  },

  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      // backgroundColor: '#00aba9',
      paddingTop: 2,
      paddingBottom: 2
    };
  },
  menu: (provided: any, state: any) => {
    return {
      ...provided,
      marginTop: 1,
    };
  },
  option: (provided: any, state: any) => {
    // console.log(provided);
    return {
      ...provided,
      fontSize: '.8rem',
      color: '#2d3436',
      paddingTop: 2,
      paddingBottom: 2
    };
  },

  groupHeading: (provided: any, state: any) => {
    // console.log(provided);
    return {
      ...provided,
      fontSize: '.8rem',
      fontWeight: 600,
      paddingTop: 2,
      paddingBottom: 2
    };
  },
};
