import React, {useState, useEffect} from 'react';
import Activity from "./Activity";
import AirInfo from "./AirInfo";
import {IAirTravel} from "../../types/TourLineTypes";
import {retrieveFileURL} from "../../utils/TourLine";
import AirFareInfoView from "../../utils/AirFareInfoView";
import {ActiveDates} from "../../types/flightStoreTypes";
import {DAILY_DEPARTURE} from "../../constants/actions/FlightActions";
import {getDateTime} from "../../utils/CommonFunctions";

type AirTravelProps = {
  // country: string
  airTravel: IAirTravel
  subTimelineDefaultVisible?: boolean
}

const AirTravel: React.FC<AirTravelProps> = (props) => {
  const subTimelineDefaultVisible = props.subTimelineDefaultVisible;
  const [isSubTimelineVisible, setSubTimelineVisibility] = useState(subTimelineDefaultVisible);
  // const [isSubTimelineVisible, setSubTimelineVisibility] = useState(false);
  // console.log("default: " + isSubTimelineVisible);

  const {airTravel} = props;

  const [fileURL, setFileURL] = useState(null);

  let styles: any = {styles: {}};

  useEffect(() => {
    !subTimelineDefaultVisible && setSubTimelineVisibility(false);
    const mainPhoto = airTravel ? airTravel.photo : null;
    if(mainPhoto) {
      retrieveFileURL(mainPhoto).then(url => {
        setFileURL(url)
      });
      // console.log(fileURL);
    }
  }, [airTravel]);

  const airFare = airTravel.airFare;

  // if (!airFare) {
  //   return (
  //     <React.Fragment></React.Fragment>
  //   )
  // }

  styles = {style: {backgroundImage: `url(${fileURL})`}};

  if (!airFare) {
    return (
      <div className='item'>
        <div className={`expander ${isSubTimelineVisible ? 'expanded' : ''}`}
             onClick={() => setSubTimelineVisibility(!isSubTimelineVisible)}>
          <i className={`feather ${isSubTimelineVisible ? 'icon-minus' : 'icon-plus'}  color-white`}/>
        </div>

        <div className='item-content air-travel' {...styles}>
          <p className='topic-label'>
            Flight Details
          </p>
        </div>

        <div className={`sub-timeline air-info-line ${!isSubTimelineVisible ? 'hide' : ''} `}>
          <span className='main-connector'/>
          <AirInfo title={"change dates to see available flights."}/>
        </div>
      </div>
    )
  }

  let activeDates: ActiveDates | null = null;
  if (airFare.availableType === DAILY_DEPARTURE) {
    let startDate = getDateTime(airFare.availableStartDate);
    // startDate.setDate(startDate.getDate() + 2);

    if (airTravel.departure) {
      startDate = airTravel.departure; //show departure date for DAILY DEPARTURE
    }
    activeDates = {start: startDate, end: null}
  }

  return (
    <div className='item'>
      <div className={`expander ${isSubTimelineVisible ? 'expanded' : ''}`}
           onClick={() => setSubTimelineVisibility(!isSubTimelineVisible)}>
        <i className={`feather ${isSubTimelineVisible ? 'icon-minus' : 'icon-plus'}  color-white`}/>
      </div>

      <div className='item-content air-travel' {...styles}>
        <p className='topic-label'>
          Flight Details
        </p>
      </div>

      <div className={`sub-timeline air-info-line ${!isSubTimelineVisible ? 'hide' : ''} `}>
        <span className='main-connector'/>

        {airFare && <AirFareInfoView activeFare={airFare} activeDates={activeDates}/>}

        {/*<AirInfo title={"CMB: 2230h Oct 08, Sri Lankan Air Lines Boing 746"}/>*/}
        {/*<AirInfo title={"SIN: 0630h Oct 09 Arrival (Changi Airport)"}/>*/}
        {/*<AirInfo title={"Visa on arrival - up to 7 days."}/>*/}
        {/*<AirInfo title={"SIN: 1300h Oct 12, Sri Lankan Air Lines Airbus 330"}/>*/}
        {/*<AirInfo title={"CMB: 0945h Oct 12, Sri Lankan Katunayake"}/>*/}
        {/*<AirInfo title={"Departure SIN: Sri Lankan Air Lines 0630 Boing 330"}/>*/}
        {/*<AirInfo title={"Visa on arrival: up to 7 days."}/>*/}
        {/*<AirInfo title={"Departure SIN: Sri Lankan Air Lines 1300 Boing 330"}/>*/}
        {/*<AirInfo title={"Arrival CMB: Sri Lankan"}/>*/}

      </div>

    </div>
  )
};

export default AirTravel;