import React, {PropsWithChildren} from 'react';
import {FooterDestination} from "../../../../types/HomeTypes";
import {Col, Row} from "react-bootstrap";
import {IDestination} from "../../../../types/clientStoreTypes";
import DestinationTitle from "../../elements/FooterDestinations/DestinationTitle";
import DestinationItem from "../../elements/FooterDestinations/DestinationItem";

type DestinationGroupProps = {
  footerDestinations: FooterDestination[]
}

const DestinationGroups: React.FC<DestinationGroupProps> = (props: PropsWithChildren<DestinationGroupProps>) => {
  const {footerDestinations} = props;

  const renderDestinationGroupItems = (destinations: IDestination[]) => {
    return <ul className="item-list">
      {destinations.map((item: IDestination, index: number) => <DestinationItem destination={item}/>)}
    </ul>
  }

  const renderDestinationGroups = () => {

    return footerDestinations.map((footerDestination: FooterDestination, index: number) => {
      return (
        <Col sm={6} md={4} lg={3} key={index}>
          <DestinationTitle destination={footerDestination.title}/>

          {renderDestinationGroupItems(footerDestination.items)}
        </Col>
      );
    });
  }

  return (
    <Row>{renderDestinationGroups()}</Row>
  )
};

export default DestinationGroups;