import React from 'react';
import FacilityGroup from "./FacilityGroup";
import {Col, Row} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {ALL_FACILITIES} from "../../../../graphql/queries/FacilityQueries";
import UpdateFacility from "./FacilityUpdate/UpdateFacility";

export type FacilityData = {
  id: string
  icon: string
  name: string
  category: string
  ticket: string | null
  activity_types: string[]
}

export type CategorizedFacilities = {
  category: string,
  facilities: FacilityData[]
}

type AllFacilitiesProps = {
  query: string
}

const AllFacilities: React.FC<AllFacilitiesProps> = (props) => {
  const {loading, data, error} = useQuery(ALL_FACILITIES);
  let categorizedFacilities: CategorizedFacilities[] = [];

  const query = props.query;
  const matcher = new RegExp(query, 'i');

  if (loading) {
    return <span className="spinner-border spinner-border-sm mr-1 position-absolute" role="status"/>;
  }

  if (error) {
    return <p className="text-center py-5 text-danger">could not load facilities list. try reloading.</p>;
  }

  if (data) {
    const facilitiesData = data.facilities;
    // console.log(facilitiesData);

    if (facilitiesData) {
      facilitiesData.forEach((facility: FacilityData) => {
        const searchTxt = facility.name + facility.category + facility.icon;
        // if ( !facility.name.match(matcher) && !facility.category.match(matcher) && !facility.category.match(matcher)) {
        if (!searchTxt.match(matcher)) {
          return;
        }

        const categoryListIndex = categorizedFacilities.findIndex(
          categoryList => categoryList.category === facility.category);

        if (categoryListIndex > -1) {
          const categoryList = categorizedFacilities[categoryListIndex];
          const facilitiesList = categoryList.facilities.slice();
          facilitiesList.push(facility);
          categoryList.facilities = facilitiesList;
        } else {
          const categoryList: CategorizedFacilities[] = [{category: facility.category, facilities: [facility]}];
          categorizedFacilities = categorizedFacilities.concat(categoryList);
        }

      });

    }

    const renderFacilityGroups = () => {
      return (
        categorizedFacilities.map((group: CategorizedFacilities, index: number) => {
          return (
            <Col xs={12} md={6} key={index}>
              <FacilityGroup category={group.category} facilities={group.facilities}/>
            </Col>
          );
        })
      );
    };

    const allFacilities: FacilityData[] = facilitiesData.reduce(
      (allFacilities: FacilityData[], current: FacilityData) => allFacilities.concat(current), []);

    return (
      <React.Fragment>
        <UpdateFacility facilities={allFacilities}/>
        <Row className='all-facilities'>
          {renderFacilityGroups()}
        </Row>
      </React.Fragment>

    );

  }

  return <Row/>;
};

export default AllFacilities;