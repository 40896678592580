import React, {useEffect, useRef, useState} from 'react';
import {NavDropdown} from "react-bootstrap";
import {useOutsideClickNotifier} from "../../common/OutsideClickNotifier";

type MenuDropdownBodyProps = {
  onItemSelected?: () => void
}

export const withNavigationDropdownBody = (DropdownBody: React.FC<MenuDropdownBodyProps>, title: string) => {

  const NavigationDropdown: React.FC = (props: any) => {
    const myComponentWrapperRef = useRef(null);
    const [isOutsideClicked, setIsOutsideClicked] = useState(false);
    const isClickOutsideComponent = useOutsideClickNotifier(myComponentWrapperRef, isOutsideClicked);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      if (!isClickOutsideComponent) {
        return;
      }

      setIsVisible(false);
      setIsOutsideClicked(true); //to help reset outside click
    }, [isClickOutsideComponent]);

    useEffect(() => {
      if (!isVisible) {
        return;
      }
      setIsOutsideClicked(false); //resets outside click
    }, [isVisible]);

    const handleOnOptionSelect = () => {
      setIsVisible(false);
    }

    const handleOnToggle = () => {
      setIsVisible(!isVisible);
    }

    return (
      <div ref={myComponentWrapperRef}>
        <NavDropdown title={title} id={"th-main-menu-" + title} show={isVisible} onClick={handleOnToggle}>
          <DropdownBody onItemSelected={handleOnOptionSelect} {...props}/>
        </NavDropdown>
      </div>
    )
  };

  return NavigationDropdown;
}