import React, {PropsWithChildren} from 'react';
import {Col, Row} from "react-bootstrap";
import {UISizes} from "../../../../types/UISizes";
import {useDispatch, useSelector} from "react-redux";
import {setMonths} from "../../../../store/actions/clientActions";
import {AppState} from "../../../../../store/reducers";
import {OutlineLabel} from "../OutlineLabel";

type MenuDropdownLabelListProps = {
  onItemSelected?: () => void
}

const InstallmentDropdownLabelList: React.FC<MenuDropdownLabelListProps> =
  (props: PropsWithChildren<MenuDropdownLabelListProps>) => {

    const selectedMonths: number | null = useSelector((state: AppState) => state.client.search.months);

    const availableInstallments: number[] = [12, 24, 36, 48, 60];

    const dispatch = useDispatch();

    const handleMonthsChange = (instalments: number) => {
      dispatch(setMonths(instalments));
      props.onItemSelected && props.onItemSelected();
    };

    const renderInstallments = () => {
      return availableInstallments.map((period: number) =>
        <OutlineLabel onClick={() => handleMonthsChange(period)} size={UISizes.sm} fontBold={true}
                      className='w-8 clickable'
                      active={selectedMonths === period}>
          <OutlineLabel.Label>{period} Months</OutlineLabel.Label>
        </OutlineLabel>
      );
    }

    return (
      <div className='installment-select-container'>
        <Row className=' px-3'>
          <Col xs={6}>
            <OutlineLabel onClick={() => handleMonthsChange(0)} size={UISizes.sm} minWidth={8}
                          className='clickable' active={selectedMonths !== null && selectedMonths === 0}>
              <OutlineLabel.Label>Full Payment</OutlineLabel.Label>
            </OutlineLabel>
          </Col>

          <Col xs={6} className='pl-3'>
            <OutlineLabel onClick={() => handleMonthsChange(36)} size={UISizes.sm}
                          className='w-8'
                          disabled={true} active={selectedMonths !== null && selectedMonths !== 0}>
              <OutlineLabel.Label>Installments</OutlineLabel.Label>
            </OutlineLabel>

            {renderInstallments()}
          </Col>
        </Row>
      </div>
    )
  };

export default InstallmentDropdownLabelList;