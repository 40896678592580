import React from 'react';
import {Tab, Tabs} from "react-bootstrap";
import RecentPhotos from "./RecentPhotos";
import PhotoLibrary from "./PhotoLibrary";
import UploadPhoto from "./UploadPhoto";

const PhotoEditors: React.FC = () => {

  return <UploadPhoto/>;

  // return (
  //   <Tabs id={'photo-pills'} variant="pills" defaultActiveKey="upload" className="mb-3 photo-tabs">
  //     <Tab eventKey="recent" title={<span><i className='feather icon-clock'/> Recent</span>}>
  //       <RecentPhotos/>
  //     </Tab>
  //     <Tab eventKey="library" title={<span><i className='feather icon-search'/> Library</span>}>
  //       <PhotoLibrary/>
  //     </Tab>
  //     <Tab eventKey="upload" title={<span><i className='feather icon-upload-cloud'/> Upload</span>}>
  //       <UploadPhoto/>
  //     </Tab>
  //   </Tabs>
  //
  // );
};

export default PhotoEditors;