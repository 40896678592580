import React from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";
import {PHOTO_TYPE_ACTIVITY, PHOTO_TYPE_DAY} from "../../../../constants/actions";
import Options from "./Options/Options";
import DayOptions from "./Options/DayOptions";
import ActivityOptions from "./Options/ActivityOptions";

const EditorOptions: React.FC = () => {
  const photoEditor = useSelector((state: AppState) => state.tour.photoEditor);

  if (!photoEditor) {
    return <React.Fragment/>
  }

  return(
    <Options>
      {photoEditor.photoEditorType === PHOTO_TYPE_DAY && <DayOptions photoEditor={photoEditor}/>}
      {photoEditor.photoEditorType === PHOTO_TYPE_ACTIVITY && <ActivityOptions photoEditor={photoEditor} />}
    </Options>
  )
};

export default EditorOptions;