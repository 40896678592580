import React, {useState} from 'react';
import {Col, Modal, Row} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {closeEditor} from "../../../store/actions/tourlineActions";
import {AIR_TRAVEL_EDITOR, PHOTO_EDITOR, SUMMARY_EDITOR} from "../../../constants/actions";
import MainPhotoEditor from "./MainPhotoEditor/MainPhotoEditor";
import AirInfoEditor from "./AirInfoEditors/AirInfoEditor";
import SummaryEditor from "./SummaryEditor/SummaryEditor";

const PopupEditor: React.FC = (props) => {
  const activeEditor: string | null = useSelector((state: AppState) => state.tour.activeEditor);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeEditor())
  };

  return (
    <Modal show={activeEditor !== null}
           onHide={handleClose} size="lg"
           className='popup-editor'
           centered>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            {props.children}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default PopupEditor;