import { gql } from "apollo-boost";

export const AgencyListQuery = gql`
  {
    agencies {
      _id
      name
      phone
      email
      address
      country
      #fax: String
      description
      remarks
      createdAt
      updatedAt
    }
  }
`;

export const AgencyById = gql`
  query agencyById($id: String!) {
    agency(id: $id) {
      _id
      name
      phone
      email
      address
      country
      #fax: String
      description
      remarks
      createdAt
      updatedAt
    }
  }
`;
