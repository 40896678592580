import React from "react";
import { ApolloConsumer } from "react-apollo";

/*
 * This is an alternative replacement for withApollo HOC in react-apollo
 */
export function tourWithApolloClient(WrappedComponent: any) {
  return class TourWithApolloClient extends React.Component {
      constructor(props: any){
          super(props);
      }

    render() {
      return (
        <ApolloConsumer>
          {(client:any) =>{
             return <WrappedComponent {...this.props} client={client}/>;
          }}
        </ApolloConsumer>
      );
    }
  };
}
