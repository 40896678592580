import gql from "graphql-tag";
import {ApprovalFragment} from "../fragments/TourApprovalFragment";

export const CREATE_APPROVE_REJECT = gql`
  ${ApprovalFragment}
  
  mutation createAdminApproval($review: AdminApprovalInput!) {
    createAdminApproval(review: $review) {
      ...ApprovalFragment
    }
  }
`;

export const SET_APPROVE_VIEWED = gql`
  ${ApprovalFragment}
  
  mutation setAdminApprovalViewed($tourId: String!) {
    setAdminApprovalViewed(tourId: $tourId) {
      ...ApprovalFragment
    }
  }
`;