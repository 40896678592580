import moment from "moment";

export const generatePIN = () => {
  const length = 6; // length of pin
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDateTime = (date: Date | string) => {
  if (date instanceof Date) {
    return date;
  }
  return new Date(parseInt(date + ''));
};

export const getDateFormatted = (date: Date | null, format: string) => {
  // FIND formats @ https://momentjs.com/docs/#/displaying/
  if (date) {
    return moment(date).format(format);
  }
  return 'N/A';
};

export const getTimeZeroDate = (date: Date) => {
  const zeroDate = new Date(date);
  zeroDate.setHours(0);
  zeroDate.setMinutes(0);
  zeroDate.setSeconds(0);

  return zeroDate;
};

/**
 * if end time is higher than mid night of start return 1 or more if higher days.
 * @param start
 * @param end
 */
export const getOverNightDifferenceInDays = (start: Date, end: Date) => {
  start = getTimeZeroDate(start);
  end = getTimeZeroDate(end);

  const diffTime = Math.abs(end.getTime() - start.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};