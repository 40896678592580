import React from 'react';

type UcFirstType = {
    text: string
}

class UcFirst extends React.Component <UcFirstType>{
    render() {
        const string = this.props.text;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}

export default UcFirst;