import React, {FormEvent, useEffect, useState} from "react";
import { NavLink, Redirect } from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import axios from "axios";
import { API_ROUTES } from "../../../routes/routes";
import { Button, Form } from "react-bootstrap";
import USER_ROLES from "../../../constants/roles";
import RandomBackground from "../../../utils/RandomBackground";
import {getLoadingSpan} from "../../../utils/loading/loading";
import {STORAGE_USER} from "../../../constants/values";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setRedirect} from "../../../Client/store/actions/clientActions";

type SignIn2Props = {
  from?: any
}

const SignIn2: React.FC<SignIn2Props> = (props: SignIn2Props) => {
  const [loginState, setLoginState] = useState<SignInState>({
    username: null,
    password: null,
    isLoginError: false,
    message: null,
    redirect: false,
    redirectTo: "/",
    processing: false
  });

  const redirect: string | null = useSelector((state: AppState) => state.client.redirectTo);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, username: event.target.value });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, password: event.target.value });
  };

  const handleLogin = (data: any) => {
    // const user = { auth: true, token: "a", role: "admin" };
    const user = {
      auth: data.auth,
      token: data.token,
      role: data.role,
      name: data.name
      // company: data.company
    };

    localStorage.setItem(STORAGE_USER, JSON.stringify(user));
    // if (this.props.history.length > 1) {
    //   this.props.history.goBack();
    // }

    let redirectTo = "/";

    switch (data.role) {
      case USER_ROLES.SUPER_ADMIN:
      case USER_ROLES.AGENCY_ADMIN:
      case USER_ROLES.AGENCY_USER:
        redirectTo = "/admin";
        break;
    }

    if (redirect) {
      redirectTo = redirect;
    }

    setLoginState({ ...loginState, redirect: true, redirectTo });

    // this.props.history.push("/");
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setLoginState({
      ...loginState,
      message: "",
      isLoginError: false,
      processing: true
    });

    const auth = {
      username: loginState.username,
      password: loginState.password,
      login_type: "password"
    };

    axios
      .post(API_ROUTES.LOGIN, auth)
      .then((res: any) => {
        setLoginState({ ...loginState, processing: false });

        if (res.data.auth) {
          setLoginState({ ...loginState, isLoginError: false });
          handleLogin(res.data);
        }
      })
      .catch((err: any) => {
        setLoginState({ ...loginState, processing: false });

        const message = err.response ? err.response.data : "Network Error";
        localStorage.removeItem("user");

        if (!err.response) {
          setLoginState({
            ...loginState,
            message: message,
            isLoginError: true
          });
        }

        if (err.response && err.response.status === 403) {
          setLoginState({
            ...loginState,
            message: message,
            isLoginError: true
          });
        }

        if (err.response && err.response.status === 400) {
          setLoginState({
            ...loginState,
            message: "Problem occurred. Try again!",
            isLoginError: true
          });
        }
      });
  };

  const renderRedirect = () => {
    if (loginState.redirect) {
      return <Redirect to={loginState.redirectTo} />;
      //   props.history.push(loginState.redirectTo);
    }
  };

  return (
    <Aux>
      {renderRedirect()}

      <div className="auth-wrapper aut-bg-img-side container-fluid th-no-padding align-items-stretch">
        <div className="row align-items-center w-100 align-items-stretch bg-white">
          <RandomBackground className="d-none d-lg-flex col-lg-8 aut-bg-img align-items-center d-flex justify-content-center">
            <div className="col-md-8">
              <h1 className="text-white mb-5">Travel made Affordable.</h1>
              <p className="text-white">
                Find most exiting vacation plans & relaxing tours at lowest
                rate. Best tour deals from Tours Harbor.
              </p>
            </div>
          </RandomBackground>
          <div className="col-lg-4 align-items-stret h-100 align-items-lg-center d-flex justify-content-center">
            <div className=" auth-content text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon" />
              </div>
              <h3 className="mb-4">Login</h3>
              <Form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="username or email"
                    required
                    onChange={handleUsernameChange}
                  />
                </div>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="password"
                    required
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="form-group text-left">
                  <div className="checkbox checkbox-fill d-inline">
                    <input
                      type="checkbox"
                      name="checkbox-fill-1"
                      id="checkbox-fill-a1"
                    />
                    <label htmlFor="checkbox-fill-a1" className="cr">
                      {" "}
                      Save credentials
                    </label>
                  </div>
                </div>
                <div className="error-container">
                  {loginState.isLoginError && (
                    <p className="login-error">{loginState.message}</p>
                  )}
                </div>
                <Button
                  type="submit"
                  className="btn btn-primary shadow-2 mb-4"
                  // onClick={handleSubmit}
                  disabled={loginState.processing}
                >
                  {loginState.processing && getLoadingSpan()}
                  Login
                </Button>
              </Form>
              <p className="mb-2 text-muted">
                Forgot password?{" "}
                <NavLink to="/auth/forgot">Reset</NavLink>
              </p>
              <p className="mb-0 text-muted">
                Don’t have an account?{" "}
                <NavLink to="/signup">Signup</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

interface SignInState {
  username: string | null;
  password: string | null;
  isLoginError: boolean;
  message: string | null;
  redirect: boolean;
  redirectTo: string;
  processing: boolean;
}

export default SignIn2;
