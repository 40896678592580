import React from 'react';
import {Dropdown} from "react-bootstrap";
import DEMO from "../../../store/constant";
import {useDispatch, useSelector} from "react-redux";
import {clearTourInEditor, setActiveEditor} from "../../../store/actions/tourlineActions";
import swal from "sweetalert";
import {SwalOptions} from "sweetalert/typings/modules/options";
import {REVIEW_EDITOR, REVIEW_VIEWER, SUMMARY_EDITOR} from "../../../constants/actions";
import {AppState} from "../../../store/reducers";
import {ReviewStatus} from "../../../constants/values";
import {isHostAdminOrModerator, isSuperUser} from "../../../utils/authCheck/authCheck";

const TourLineOptions: React.FC = () => {
  const reviewStatus: ReviewStatus | null = useSelector((state: AppState) => state.tour.reviewStatus);

  const dispatch = useDispatch();

  const clearConfirmation = () => {
    const warningText = 'New tour will be created. Any unsaved changes may be lost!';
    const options: SwalOptions = {
      title: "Are you sure?",
      text: warningText,
      icon: "warning",
      dangerMode: true,
      buttons: ["Back", true],
      content: {element: 'cont'},
      className: 'clear-warning-swal',
      closeOnClickOutside: true,
      closeOnEsc: true,
      timer: 0
    };

    return swal(options);
  };

  const handlePublishNowClick = () => {
    dispatch(setActiveEditor(SUMMARY_EDITOR));
  };

  const handleReloadRequest = () => {
    window.location.reload();
  };

  const handleClearEditor = async () => {
    const confirmed = await clearConfirmation();
    if (confirmed) {
      dispatch(clearTourInEditor());
    }
  };

  const getAdminReviewOptions = () => {
    if(!isSuperUser()) {
      return;
    }

    if (reviewStatus && (reviewStatus === ReviewStatus.REQUESTED || reviewStatus === ReviewStatus.REJECTED)) {
      return (
        <React.Fragment>
          <Dropdown.Divider/>
          <Dropdown.Item as='li' className="dropdown-item " onClick={() => dispatch(setActiveEditor(REVIEW_EDITOR))}>
            <i className='feather icon-feather text-primary'/>
            <a href={DEMO.BLANK_LINK} className='text-primary'> Approve / Reject</a>
          </Dropdown.Item>
        </React.Fragment>
      );
    }
  };

  const getShowReviewOptions = () => {
    if(!isHostAdminOrModerator()) {
      return;
    }

    if (reviewStatus && (reviewStatus === ReviewStatus.APPROVED || reviewStatus === ReviewStatus.REJECTED)) {
      return (
        <React.Fragment>
          <Dropdown.Divider/>
          <Dropdown.Item as='li' className="dropdown-item " onClick={() => dispatch(setActiveEditor(REVIEW_VIEWER))}>
            <i className='feather icon-clipboard text-primary'/>
            <a href={DEMO.BLANK_LINK} className='text-primary'> Show admin review</a>
          </Dropdown.Item>
        </React.Fragment>
      );
    }
  };

  return (
    <div className="editor-options tourline-options">
      <Dropdown alignRight={true} className="btn-group card-option" title='options'>
        <Dropdown.Toggle id="dropdown-basic" className="btn-icon">
          <i className="feather icon-more-horizontal"/>
        </Dropdown.Toggle>
        <Dropdown.Menu as='ul' className="list-unstyled card-option">
          <Dropdown.Item as='li' className="dropdown-item" onClick={handlePublishNowClick}>
            <i className='feather icon-check-circle'/>
            <a href={DEMO.BLANK_LINK}> publish now</a>
          </Dropdown.Item>
          <Dropdown.Item as='li' className="dropdown-item" onClick={handleReloadRequest}>
            <i className='feather icon-refresh-cw'/>
            <a href={DEMO.BLANK_LINK}> reload</a>
          </Dropdown.Item>
          <Dropdown.Item as='li' className="dropdown-item" onClick={handleClearEditor}>
            <i className='feather icon-trash'/>
            <a href={DEMO.BLANK_LINK}>clear tour</a>
          </Dropdown.Item>
          {getAdminReviewOptions()}
          {getShowReviewOptions()}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default TourLineOptions;