import React, {useEffect, useState} from 'react';
import {IActivity, IDay, PhotoEditor} from "../../../../../types/storeTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";
import {ReactSelectOption} from "../../../../../types/ComponentTypes";
import {Button, Col, Row} from "react-bootstrap";
import {dashboardDropdownStylesNoColor} from "../../../../../constants/dropdownStyles";
import Select, {ValueType} from "react-select";
import {showAlert} from "../../../../../utils/showAlert";
import {moveActivityToDay} from "../../../../../store/actions/tourlineActions";

const MoveActivityToAnotherDay: React.FC = () => {
  const days: IDay[] = useSelector((state: AppState) => state.tour.days);
  const photoEditor: PhotoEditor | null = useSelector((state: AppState) => state.tour.photoEditor);
  const activity: IActivity | null = photoEditor ? photoEditor.editor as IActivity : null;
  const inSettingsMode = useSelector((state: AppState) => state.tour.inSettingsMode);

  const dispatch = useDispatch();

  let initDayOption: ReactSelectOption | null = null;
  if (activity) {
    initDayOption = {label: "Day " + activity.dayNumber, value: (activity.dayNumber - 1) + ""};
  }

  const [selectedDay, setSelectedDay] = useState<ReactSelectOption | null>(initDayOption);

  /**
   * reset to default date on activity change or come back to settings tab from another tab
   */
  const setDefaultSelectedDayOption = () => {
    if (!activity) {
      return;
    }

    const dayOption: ReactSelectOption = {label: "Day " + activity.dayNumber, value: (activity.dayNumber - 1) + ""};
    setSelectedDay(dayOption);
  }

  useEffect(() => {
    setDefaultSelectedDayOption();
  }, [activity]);

  /**
   * come back to settings tab from another tab
   */
  useEffect(() => {
    if (!inSettingsMode) {
      return;
    }

    setDefaultSelectedDayOption();
  }, [inSettingsMode]);

  const dayOptions: ReactSelectOption[] = days.map((day: IDay, index: number) => ({
    label: "Day " + (index + 1),
    value: index + ""
  }));

  const handleOnDayToMoveSelected = (selectedOption: ValueType<ReactSelectOption>) => {
    const selectedValueOption = (selectedOption as ReactSelectOption);
    setSelectedDay(selectedValueOption);
  }

  const handleOnMoveClick = () => {
    if (!activity || !selectedDay) {
      return;
    }

    if (activity.dayNumber === parseInt(selectedDay.value) + 1) {
      showAlert('Activity already in Day ' + activity.dayNumber);
      return;
    }

    dispatch(moveActivityToDay(parseInt(selectedDay.value)));
  }

  return (
    <Row>
      <Col xs={12} lg={6}>

        <Row>
          <Col xs={12} className='mb-3'>
            <h6>Move activity to:</h6>
          </Col>
          <Col xs={8} lg={7}>
            <Select
              styles={dashboardDropdownStylesNoColor}
              className="basic-single"
              isClearable={false}
              isMulti={false}
              value={selectedDay}
              name="color"
              onChange={(option) => option && handleOnDayToMoveSelected(option)}
              options={dayOptions}
            />
          </Col>
          <Col xs={4} lg={5} className='pl-0'>
            <Button variant='info' size="sm" className='d-inline' onClick={handleOnMoveClick}>
              move
            </Button>
          </Col>
        </Row>

      </Col>
    </Row>
  );
};

export default MoveActivityToAnotherDay;