import React, {useEffect, useState} from 'react';
import Activity from "./Activity";
import triangle from '../../assets/images/timeline/triangle.png';
import {Image} from "react-bootstrap";
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from "../../store/reducers";
import {IActivity, IDay, PhotoEditor} from "../../types/storeTypes";
import {addActivity, addDay, setActiveEditor, setPhotoEditor} from "../../store/actions/tourlineActions";
import {PHOTO_EDITOR, PHOTO_TYPE_DAY} from "../../constants/actions";
import EditorActiveIcon from "./EditorActiveIcon";

type DayProps = {
  day: IDay
}

const Day: React.FC<DayProps> = (props) => {
  const [isSubTimelineVisible, setSubTimelineVisibility] = useState(false);
  const totalDays = useSelector((state:AppState)=> state.tour.days.length);
  const photoEditor = useSelector((state:AppState)=> state.tour.photoEditor);
  // const day = useSelector((state:AppState)=> state.tour.days[props.number - 1]);

  const day: IDay = props.day;
  const activities: IActivity[] | null = day.activities;

  const dispatch = useDispatch();

  useEffect(() => {
    if(props.day.number === 1){
      // setSubTimelineVisibility(true);
    }
  });

  const showEditor = async () => {
    await dispatch(setPhotoEditor({editor: day, photoEditorType: PHOTO_TYPE_DAY}));
    dispatch(setActiveEditor(PHOTO_EDITOR));
  };

  const addNewDay = () => {
    dispatch(addDay());
  };

  const addNewActivity = () => {
    dispatch(addActivity(day))
  };

  const mainPhoto = day ? day.mainPhoto : null;
  let styles: any = {styles: {}};
  if(mainPhoto) {
    styles = {style: {backgroundImage: `url(${mainPhoto})`}}
  }

  return (
    <div className='item'>
      <div className={`expander ${isSubTimelineVisible ? 'expanded' : ''}`} onClick={() => setSubTimelineVisibility(!isSubTimelineVisible)}>
        <i className={`feather ${isSubTimelineVisible ? 'icon-minus' : 'icon-plus'}  color-white`}/>
      </div>

      <div className='item-content' {...styles} onClick={showEditor}>
        <p className='day-label'>
          DAY {day.number}
        </p>
        <Image src={triangle} className='triangle'/>
      </div>

      {photoEditor && photoEditor.editor as IDay === day && <EditorActiveIcon iconClass={'main-item-refresh'}/>}
      {day.number === totalDays && <i className='feather icon-plus add-day' title='Add new day'  onClick={addNewDay}/>}

      <div className={`sub-timeline ${!isSubTimelineVisible ? 'hide' : ''} `}>
        <span className='main-connector'/>

        {activities && activities.map((activity: IActivity, index: number) => {
          return(
            <Activity activity={activity} key={index}/>
          )
        })}

        <i className='feather icon-plus add-activity' title='Add new activity' onClick={addNewActivity}/>
      </div>

    </div>
  )
};

export default Day;

// const mapStateToProps = (state: AppState, ownProps: DayProps) => {
//   return {
//     day: state.tour.days[ownProps.number - 1]
//   }
// };
//
// const mapDispatchToProps = (dispath: Dispatch) => {
//   return {
//     showPhotoEditor: () => dispath(setActiveEditor(PHOTO_EDITOR)),
//     setPhotoEditorType: (editorTypeObj: IDay) => dispath(setPhotoEditor({editor: editorTypeObj, photoEditorType: PHOTO_TYPE_DAY}))
//   }
// };
//
// export default connect(mapStateToProps, mapDispatchToProps) (Day);
