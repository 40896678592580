import React, {useEffect, useState} from "react";
import { useQuery } from "@apollo/react-hooks";
import { AgencyById } from "../../graphql/queries/AgencyQueries";
import { Card } from "react-bootstrap";
import ToursSpinner from "../../components/ui/ToursSpinner";
import { AgencyType } from "../../types/AgencyType";
import {isSuperUser} from "../../utils/authCheck/authCheck";
import {Redirect} from "react-router-dom";

const AgencyView: React.FC = (props: any) => {
  const { loading, error, data } = useQuery(AgencyById, {
    variables: {
      id: props.match.params.id
    }
  });

  const [isAuthorized, setIsAuthorized] = useState(true);

  useEffect(() => {
    setIsAuthorized(isSuperUser());
  }, []);

  if (!isAuthorized) {
    return <Redirect to='/admin' />
  }

  const getContent = () => {
    if (loading) {
      return <ToursSpinner />;
    }

    // TODO: display proper error message
    if (error) {
      return <h6>Error! {JSON.stringify(error)}</h6>;
    }

    if (data.agency) {
      const agency: AgencyType = data.agency;
      // TODO: UI for agency view
      return (
        <div>
          <p>ID: {agency._id}</p>
          <p>Name: {agency.name}</p>
          <p>Phone: {agency.phone}</p>
          <p>Email: {agency.email}</p>
          <p>Address: {agency.address}</p>
          <p>Country: {agency.country}</p>
          <p>Description: {agency.description}</p>
          <p>Remarks: {agency.remarks}</p>
          <p>Created At: {agency.createdAt}</p>
          <p>Updated At: {agency.updatedAt}</p>
        </div>
      );
    }
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Agency View</Card.Title>
      </Card.Header>
      <Card.Body>{getContent()}</Card.Body>
    </Card>
  );
};

export default AgencyView;
