import React from 'react';
import DayPicker, {DateUtils, DayModifiers} from 'react-day-picker';
import {Modifiers} from 'react-day-picker/types/common';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {setDepartureDate} from "../../store/actions/clientActions";
import {AppState} from "../../../store/reducers";

const DateSearch: React.FC = () => {
  const selectedDate: Date | null = useSelector((state: AppState) => state.client.search.departure);

  // const month = selectedDate ? getDateTime(selectedDate) : new Date();
  const month = selectedDate ? new Date(selectedDate) : new Date();

  // const departure: Date | undefined = selectedDate ? getDateTime(selectedDate) : undefined;
  const departure: Date | undefined = selectedDate ? new Date(selectedDate) : undefined;
  const dispatch = useDispatch();

  let modifiers: Partial<Modifiers> = {};
  if (departure) {
    modifiers = {departureDate: departure}
  }

  const dispatchSelectedDate = (date: Date | null) => {
    dispatch(setDepartureDate(date));
  };

  const handleDayClick = async (day: Date, modifiers: DayModifiers) => {
    // const selected = modifiers.selected;
    day.setHours(0);
    day.setMinutes(0);
    day.setSeconds(0);

    const today = new Date(); // selecting past dates not allowed.
    // to allow select today.
    today.setHours(day.getHours());
    today.setMinutes(day.getMinutes());
    today.setSeconds(day.getSeconds());

    // console.log('departure day:');
    // console.log(day);

    // selection of past dates are not allowed.
    if (day.getTime() < today.getTime()) {
      dispatchSelectedDate(null);
      return;
    }

    if ((departure && DateUtils.isSameDay(departure, day) )) {
      dispatchSelectedDate(null);
    } else {
      // day.setHours(day.getHours() + 6);
      // day.setMinutes(day.getMinutes() + 330);
      const offset = day.getTimezoneOffset();
      day.setMinutes(day.getMinutes() - offset);
      dispatchSelectedDate(day);
    }
  };

  return (
    <div className='search-date'>
      <p className='pick-title'>select your departure date</p>

      <DayPicker
      modifiers={modifiers}
      selectedDays={departure}
      onDayClick={handleDayClick}
      month={month}
      disabledDays={{before: new Date()}}
      />

      <hr/>
      <Form.Group className="d-inline">
      <div className='checkbox d-inline checkbox-fill checkbox-success'>
        <Form.Control type='checkbox' name='checkbox-fill-1' id='checkbox-fill-1' defaultChecked={true} />
        <Form.Label htmlFor='checkbox-fill-1' className='cr date-pick-help'>
          help me find best deal flights, near my departure date.</Form.Label>
      </div>
      </Form.Group>
      {/*<p className='date-pick-help'>help me find flights on near my departure date.</p>*/}

    </div>
  );
};

export default DateSearch;