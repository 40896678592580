import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import UploadCountdown from "../../details/UploadCountdown";
import {getDateFormatted} from "../../../../utils/CommonFunctions";
import {IMyBooking} from "../../../types/bookingProps";
import {Link} from "react-router-dom";
import {getDateTime} from "../../../utils/util";
import {useDispatch} from "react-redux";
import {setMyRecentBookingsVisibility} from "../../../store/actions/clientActions";

type PassportStatusProps = {
  booking: IMyBooking
  isContainerMyBooking?: boolean // to style status in MyBooking
}

const PassportStatus: React.FC<PassportStatusProps> = (props) => {
  const {booking, isContainerMyBooking} = props;
  const dispatch = useDispatch();

  const closeMyRecentBookingsMenu = () => {
    dispatch(setMyRecentBookingsVisibility(false));
  };

  const getUploadCountdown = () => {
    const today = new Date();
    const endDate = getDateTime(booking.createdAt); //end date to upload passports
    endDate.setDate(endDate.getDate() + 2);

    if (endDate.getTime() < today.getTime()) {
      return (
        <label className='text-danger expired-upload'>
          expired on {getDateFormatted(endDate, 'DD/MM/YYYY')}
        </label>
      );
    }

    return <UploadCountdown uploadDeadline={endDate} message='Ending in'/>;
  };

  if (booking.isTravelDocComplete) {
    return (
      <Row className='passport-status'>
        <Col xs={12} className='pl-1 pr-1'>
          <Link to={`/travel-doc/${booking.orderID}`} onClick={closeMyRecentBookingsMenu}>
            <Button variant='outline-success' className='upload-btn uploaded mt-1'>Passports Uploaded</Button>
          </Link>
        </Col>
      </Row>
    );
  }

  return (
    <Row className='passport-status danger'>
      <Col xs={isContainerMyBooking ? 12 : 6} className=' pl-1 pr-1'>
        <Link to={`/travel-doc/${booking.orderID}`} onClick={closeMyRecentBookingsMenu}>
          <Button variant='danger' className='upload-btn mt-1'>Upload Passports</Button>
        </Link>
      </Col>

      <Col xs={isContainerMyBooking ? 12 : 6} className='pl-1 pr-1 pt-1 upload-countdown-container'>
        {getUploadCountdown()}
      </Col>
    </Row>
  );
};

export default PassportStatus;