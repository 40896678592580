import React from "react";
import {Col, Form, Row} from "react-bootstrap";
import InputDestination from "../search/InputDestination";
import InputDate from "../search/InputDate";
import InputDuration from "../search/InputDuration";
import InputPax from "../search/InputPax";
import SearchButton from "../search/SearchButton";

const MainSearch: React.FC = () => {
  return (
    <div className="th-main-search">
      <Row className="m-0">
        <Col lg={{span: 10, offset: 1}} md={{span: 12, offset: 0}} className='pt-4 pt-md-4 pt-lg-0'>
          <Row>
            <Col xs={12} lg={{span: 11, offset: 0}} md={{span: 12, offset: 0}}
                 className="mb-2 mb-sm-1 text-center welcome-text">
              <h1 className="th-main-search-title">Travel made Affordable.</h1>
              <h2 className="th-main-search-subtitle">
                Find the most exiting vacation plans & relaxing tours at lowest rate.
                <br/> Best tour deals from ToursHarbor.
              </h2>
            </Col>
            <Col xl={{span: 9, offset: 1}} lg={{span: 10, offset: 1}} md={{span: 10, offset: 1}} xs={12}
                 className='pl-4 pr-4'>
              <Form>
                <Row>
                  <Col className='mb-1 pl-0 pr-0'>
                    <InputDestination/>
                  </Col>
                </Row>

                <Row>
                  <Col className='mb-1 pr-0 mr-2 pl-0'>
                    <InputDate />
                  </Col>
                  <Col className='mb-1 pr-0'>
                    <InputDuration/>
                  </Col>
                </Row>

                <Row>
                  <Col xs={8} className='mb-1 pl-0 pr-0'>
                   <InputPax/>
                  </Col>
                  <Col xs={4} className='pr-0 pt-1'>
                    <Form.Group controlId="th-search-button">
                      <SearchButton/>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default MainSearch;
