import gql from "graphql-tag";

export const MAKE_TEST_PAYMENT = gql`
  mutation testAdminPayment ($orderID: Int!, $amount: Float){
    testAdminPayment(orderID: $orderID, amount: $amount) {
      _id
      total
      totalPaid
      payments {
        amount
        paidOn
        method
      }
    }
  }
`;