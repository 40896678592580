import React, {PropsWithChildren} from 'react';
import {Dropdown} from "react-bootstrap";
import DEMO from "../../../../../store/constant";
import {PHOTO_TYPE_DAY} from "../../../../../constants/actions";
import {addDay, cloneDay, moveDay, removeItem} from "../../../../../store/actions/tourlineActions";
import {IDay, MoveLocation, PhotoEditor} from "../../../../../types/storeTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";

type DayOptionsProps = {
  photoEditor: PhotoEditor
}

const DayOptions: React.FC<DayOptionsProps> = (props: PropsWithChildren<DayOptionsProps>) => {
  const {photoEditor} = props;
  const days = useSelector((state: AppState) => state.tour.days);
  const dispatch = useDispatch();

  const day: IDay = photoEditor.editor as IDay;
  const isMoveUpDisabled = day.number === 1;
  const isMoveDownDisabled = day.number === days.length;

  const removeDayFromTour = () => {
    if (photoEditor.photoEditorType === PHOTO_TYPE_DAY) { //type == day
      if (days.length > 1) {
        dispatch(removeItem(photoEditor));
      } else {
        alert('Tour must have at least 1 day.');
      }
    }
  };

  const addDayAboveOrBelow = (location: MoveLocation) => {
    const day: IDay = photoEditor.editor as IDay;
    dispatch(addDay(day.number, location));
  };

  const moveDayUpOrDown = (location: MoveLocation) => {
    const day: IDay = photoEditor.editor as IDay;
    dispatch(moveDay(day.number, location));
  };

  const handleCloneDay = () => {
    dispatch(cloneDay(day));
  };

  return (
    <React.Fragment>
      <Dropdown.Item as='li' className="dropdown-item" onClick={() => addDayAboveOrBelow(MoveLocation.ABOVE)}>
        <i className='feather icon-corner-up-left'/>
        <a href={DEMO.BLANK_LINK}> Add day above </a>
      </Dropdown.Item>
      <Dropdown.Item as='li' className="dropdown-item" onClick={() => addDayAboveOrBelow(MoveLocation.BELOW)}>
        <i className='feather icon-corner-down-left'/>
        <a href={DEMO.BLANK_LINK}> Add day below </a>
      </Dropdown.Item>
      <Dropdown.Divider/>

      <Dropdown.Item as='li' className="dropdown-item" disabled={isMoveUpDisabled}
                     onClick={() => moveDayUpOrDown(MoveLocation.ABOVE)}>
        <i className='feather icon-arrow-up'/>
        <a href={DEMO.BLANK_LINK}> Move day up </a>
      </Dropdown.Item>
      <Dropdown.Item as='li' className="dropdown-item" disabled={isMoveDownDisabled}
                     onClick={() => moveDayUpOrDown(MoveLocation.BELOW)}>
        <i className='feather icon-arrow-down'/>
        <a href={DEMO.BLANK_LINK}> Move day down </a>
      </Dropdown.Item>
      <Dropdown.Divider/>

      <Dropdown.Item as='li' className="dropdown-item" onClick={() => handleCloneDay()}
                     title="Makes a copy of the day with it's activities and add below this day.">
        <i className='feather icon-copy'/>
        <a href={DEMO.BLANK_LINK}> Clone day </a>
      </Dropdown.Item>
      <Dropdown.Divider/>

      <Dropdown.Item as='li' className="dropdown-item" onClick={removeDayFromTour}>
        <i className='feather icon-trash'/>
        <a href={DEMO.BLANK_LINK}> Remove </a>
      </Dropdown.Item>

    </React.Fragment>
  );
};

export default DayOptions;