import React from "react";
import {Image, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {IUser} from "../../../types/UserTypes";
import UcFirst from "../../../App/components/UcFirst";
import {useDispatch, useSelector} from "react-redux";
import {setMyRecentBookingsVisibility} from "../../store/actions/clientActions";
import USER_ROLES from "../../../constants/roles";
import MenuOverlay from "./MenuOverlay";
import MenuMyBookings from "../my-bookings/MenuMyBookings";
import {AppState} from "../../../store/reducers";
import ToursHarborLogo from '../../../assets/images/logos/logo-tilt.png';
import {withNavigationDropdownBody} from "../atomic/molecules/NavigationDropdown";
import InstallmentDropdownLabelList from "../atomic/molecules/NavBar/InstallmentDropdownLabelList";
import CurrencyDropdownLabelList from "../atomic/molecules/NavBar/CurrencyDropdownLabelList";

const MainMenu: React.FC = () => {
  const dispatch = useDispatch();

  const isMyRecentBookingsVisible: boolean = useSelector((state: AppState) =>
    state.client.isMyRecentBookingsVisible);

  const InstallmentDropdown = withNavigationDropdownBody(InstallmentDropdownLabelList, 'Inst');
  const CurrencyDropdown = withNavigationDropdownBody(CurrencyDropdownLabelList, 'LKR');

  const handleOnOverlayPopupOutsideClick = () => {
    dispatch(setMyRecentBookingsVisibility(false));
  };

  const handleOnMyBookingsClick = () => {
    dispatch(setMyRecentBookingsVisibility(!isMyRecentBookingsVisible));
  };

  const guestLinks = () => {
    return (
      <React.Fragment>
        <Nav.Link href="/signup" className='d-inline-block'>
            <span className="th-main-menu-button bordered">
              <i className="feather icon-edit"/> Register
            </span>
        </Nav.Link>
        <Nav.Link href="/login" className='d-inline-block'>
            <span className="th-main-menu-button">
              <i className="feather icon-log-in"/> Sign In
            </span>
        </Nav.Link>
      </React.Fragment>
    );
  };

  const loggedUserLinks = (username: string) => {
    const name = <span className='colorful-dropdown'><UcFirst text={username}/></span>;
    return (
      <NavDropdown title={name} id="th-main-menu-account">
        <NavDropdown.Item href="#account">My Account</NavDropdown.Item>
        <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
      </NavDropdown>
    );
  };

  const getBookingDropdown = () => {
    return (
      <Nav.Link href="#"
                className={`my-bookings-dropdown-link d-inline-block ${isMyRecentBookingsVisible ? 'visible' : ''}`}
                onMouseDown={handleOnMyBookingsClick}>
        <i className="feather icon-briefcase"/>
        <label>My bookings</label>
      </Nav.Link>
    );
  };

  const getAccountLinks = () => {
    const storageUser = localStorage.getItem('user');

    if (!storageUser) {
      return guestLinks();
    }

    const user: IUser = JSON.parse(storageUser);
    return (
      <React.Fragment>
        {user.role === USER_ROLES.TRAVELLER && getBookingDropdown()}
        {loggedUserLinks(user && user.name ? user.name.split('@')[0] : 'My Account')}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>

      <Navbar
        bg="white"
        expand="lg"
        className="th-main-menu shadow-1 py-0">
        <Navbar.Brand href="/" className="th-main-menu-brand">
          <Image src={ToursHarborLogo}/>
          {/*<span className="th-main-menu-brand-tours">TOURS</span>*/}
          {/*<br/>*/}
          {/*<span className="th-main-menu-brand-harbor">HARBOR</span>*/}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse>
          <Nav className="mr-auto mt-3 mt-sm-0" id="th-main-menu-left">
            <Nav.Link href="/become-a-host">
            <span className="th-main-menu-button bordered">
              <i className="feather icon-file-text"/> List a Service
            </span>
            </Nav.Link>
            <Nav.Link href="#">Things to do</Nav.Link>
            <Nav.Link href="#">Today's deals</Nav.Link>
          </Nav>
          <Nav id="th-main-menu-right">

            <CurrencyDropdown/>
            <InstallmentDropdown/>

            {getAccountLinks()}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {isMyRecentBookingsVisible &&
      <MenuOverlay onOutsideClick={handleOnOverlayPopupOutsideClick}>
          <MenuMyBookings/>
      </MenuOverlay>
      }

    </React.Fragment>
  );
};

export default MainMenu;
