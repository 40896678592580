import gql from "graphql-tag";

export const AirFareFragment = gql `
  fragment AirFareFields on AirFare {
      fareID:_id
      availableType: activeDateType
      availableStartDate
      availableEndDate
      seatClass
      flights {
        orderNumber
        departurePort
        departureDateTime
        arrivalPort
        arrivalDateTime
        carrier
      }
      price {
        adult
        kid:master
      }
      meals
      wifi
      baggage
  }
`;

export const AirTravelFragment = gql`
  ${AirFareFragment}
  
  fragment AirTravelFields on Tour{
    airTravel{
      airFare{
        ...AirFareFields
      }
    }
  }
`;

export const TourFragment = gql`
  fragment TourFields on Tour{
    id:_id
    title
    isPreferred
    agency {
      name
    }
    price{
      original
      price
    }
    months
    expiry
    searchDiscount {
      discount
      expiry
    }
    aot
    destinations {
      name
      facility
    }
    inclusions {
      facility
      icon
      info
    }
    days {
      number
      photo
      activities{
        number
        title
        photo
        facilities {
          facility
          icon
          name
          ticket
        }
      }
    }
  }
`;


export const TourPriceFragment = gql `
  fragment TourPriceFields on TourPrice {
    original
    price
  }
`;

export const TourDealFragment = gql `
  ${AirFareFragment}
  ${TourPriceFragment}

  fragment TourDealFields on Deal {
    airTicket{
      ...AirFareFields
    }
    price {
      ...TourPriceFields
    }   
    departure 
  }
`;

export const FullTourFragment = gql `
  ${AirFareFragment}

  fragment FullTourFields on Tour {
    id:_id
    title
    isPreferred
    price{
      original
      market
      price
    }
    months
    agency {
      name
    }
    destinations {
      name
      facility
    }
    searchDiscount {
      discount
      expiry
    }
    airTravel{
      photo
      airFare{
        ...AirFareFields
      }
    }
    days{
      number
      photo
      activities{
        number
        title
        photo
        facilities {
          facility
          icon
          name
          ticket
        }
      }
    }
  }
`;

export const TourDealsFragment = gql `
  ${FullTourFragment}
  ${TourDealFragment}
  
  fragment TourDealsFields on TourDeal {
    tour {
      ...FullTourFields
    }
    deals {
      ...TourDealFields
    }
  }
`;