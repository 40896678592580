import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import BottomFooter from "./BottomFooter";
import {TopDestinations} from "../../../constants/HomePage/values";
import DestinationGroups from "../../components/deck/cartons/FooterDestination/DestinationGroups";

const SectionFooter: React.FC = () => {
  const itemsCopy = [...TopDestinations, ...TopDestinations, ...TopDestinations, ...TopDestinations];

  return (
    <Container fluid>
      <Row className="footer">
        <Col xs={12} className="footer-section-1 pt-5 pb-4">
          <div className="pl-5 pr-5">
            <h2 className="footer-section-1-title">
              Top Destinations & Activities
            </h2>

            <DestinationGroups footerDestinations={itemsCopy}/>
          </div>
        </Col>

        <BottomFooter/>
      </Row>
    </Container>
  );
};

export default SectionFooter;
