import React, {useEffect, useState} from 'react';
import {Col, Image, Row} from "react-bootstrap";
import {IMyBooking} from "../../../types/bookingProps";
import {retrieveFileURL} from "../../../../utils/TourLine";
import TourSummary from "./TourSummary";
import PassportStatus from "./PassportStatus";

type RecentBookingProps = {
  booking: IMyBooking
}

const RecentBooking: React.FC<RecentBookingProps> = (props) => {
  const {booking} = props;
  const [mainPhoto, setMainPhoto] = useState('');

  const mainPhotoUrl = booking.tour.days[0].photo;

  useEffect(() => {
    if(!mainPhotoUrl) {
      return;
    }

    retrieveFileURL(mainPhotoUrl).then((signedUrl: string) => {
      setMainPhoto(signedUrl);
    })
  }, []);

  return (
      <Col xs={12} md={{span: 5, offset: 1}} lg={12} className='recent-booking ml-md-3 mb-3'>
        <Row>
          <Col xs={12} className='pl-0 pr-0 recent-booking-img'>
            <Image src={mainPhoto} />
          </Col>
          <Col xs={12}>
            <TourSummary booking={booking}/>
          </Col>
          <Col xs={12}>
            <PassportStatus booking={booking}/>
          </Col>
        </Row>
      </Col>
  );
};

export default RecentBooking;