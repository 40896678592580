import React, {useEffect, useState} from 'react';
import FlightListItem from "./FlightListItem";
import {ActiveDates, IAirFare} from "../../../../../types/flightStoreTypes";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";
import {useQuery} from "@apollo/react-hooks";
import {TICKETS_BY_DATE} from "../../../../../graphql/mutations/AirFareMutations";
import {getDateTime} from "../../../../../utils/CommonFunctions";


type FlightListProps = {
  tourID: string
}

const FlightList: React.FC<FlightListProps> = (props) => {
  const activeFare: IAirFare | null = useSelector((state: AppState) => state.flight.activeFare);
  const activeDates: ActiveDates | null = useSelector((state: AppState) => state.flight.activeDates);

  // const tourID = '5e09bf2612a6112a343ec5ec';
  // const tourID : string | null = useSelector((state: AppState) => state.tour.id);
  const {tourID} = props;

  let departureDate = activeDates ? getDateTime(activeDates.start) : null;
  const [ticketList, setTicketList] = useState<IAirFare[]>([]);

  // useEffect(() => {
    if (departureDate) {
      departureDate.setHours(0);
      departureDate.setMinutes(0);
      departureDate.setSeconds(0);
    }
  // }, [activeDates]);

  // if (!activeFare || activeFare.fareID) { //when Ticket(Editor) not visible or Ticket(Editor) view saved ticked.

  const {loading, error, data} = useQuery(TICKETS_BY_DATE, {
    variables: {tourID: tourID, departure: departureDate},
    skip: !tourID || !departureDate,
    // fetchPolicy: "network-only",
    onCompleted: async data => {
    //   console.log(data);
    //   if (data) {
    //     const flightList: IAirFare[] = [];
    //
    //     data.airTicketsByDate.forEach((ticket: IAirFare) => {
    //       flightList.push(ticket);
    //     });
    //     setTicketList(flightList);
    //   }
    },
    onError: error => {
      // alert('problem loading tickets.')
    },
  });

  if (!activeFare && departureDate) { //when Ticket(Editor) not visible
    if (loading) {
      return <span className="spinner-border spinner-border-sm mr-1 position-absolute" role="status"/>;
    }

    if (data) {
      return (
        <React.Fragment>
          {/*{ticketList.map((ticket, idx) => {*/}
          {/*  return <FlightListItem key={idx} departureDate={departureDate} ticket={ticket}/>;*/}
          {/*})}*/}
          {data.airTicketsByDate.map((ticket: IAirFare, idx: number) => {
            return <FlightListItem key={idx} ticket={ticket}/>;
            // return <FlightListItem key={idx} departureDate={departureDate} ticket={ticket}/>;
          })}
        </React.Fragment>
      );
    }

    return <React.Fragment/>;
  }

  return <React.Fragment/>;
};

export default FlightList;