import React, {useState, useEffect} from 'react';
import Activity from "./Activity";
import triangle from '../../assets/images/timeline/triangle.png';
import {Image} from "react-bootstrap";
import {IActivity, IDay} from "../../types/TourLineTypes";
import {retrieveFileURL} from "../../utils/TourLine";

type DayProps = {
  day: IDay
}

const Day: React.FC<DayProps> = (props) => {
  const {day} = props;
  const [isSubTimelineVisible, setSubTimelineVisibility] = useState(false);
  const [fileURL, setFileURL] = useState(null);

  let styles: any = {styles: {}};

  useEffect(() => {
    setSubTimelineVisibility(false);
    const mainPhoto = day ? day.photo : null;
    if(mainPhoto) {
      retrieveFileURL(mainPhoto).then(url => {
        setFileURL(url)
      });
      console.log(fileURL);
    }
  }, [day]);

  styles = {style: {backgroundImage: `url(${fileURL})`}};

  const renderActivities = () => {
    return (
      day.activities && day.activities.map((activity: IActivity, idx) => {
        return <Activity key={idx} activity={activity}/>
      })
    )
  };

  return (
    <div className='item'>
      <div className={`expander ${isSubTimelineVisible ? 'expanded' : ''}`}
           onClick={() => setSubTimelineVisibility(!isSubTimelineVisible)}>
        <i className={`feather ${isSubTimelineVisible ? 'icon-minus' : 'icon-plus'}  color-white`}/>
      </div>

      {fileURL &&
      <div className='item-content' {...styles} >
        <p className='day-label'>
          DAY {day.number}
        </p>
        <Image src={triangle} className='triangle'/>
      </div>
      }

      {!fileURL &&
      <div className='item-content loading' >
        <p className='day-label'>
          DAY {day.number}
        </p>
        <Image src={triangle} className='triangle'/>
      </div>
      }

      <div className={`sub-timeline ${!isSubTimelineVisible ? 'hide' : ''} `}>
        <span className='main-connector'/>

        {renderActivities()}
        {/*<Activity number={1} title={"Marina Bay Sands"}/>*/}
        {/*<Activity number={2} title={"Gardens by the Bay"}/>*/}
        {/*<Activity number={3} title={"Marina Bay Mall"}/>*/}

      </div>

    </div>
  )
};

export default Day;