import {useEffect, useState} from "react";
import {IBooking} from "../../types/dashboardStoreTypes";
import {useLazyQuery} from "@apollo/react-hooks";
import {RECENT_BOOKINGS} from "../../graphql/queries/BookingQueries";
import {GET_AIRLINES, GET_AIRPORTS} from "../../graphql/queries/AirFareQueries";
import {Airline, AirPort} from "../../types/FlightTypes";

export function useAirlines() {
  const [airlines, setAirlines] = useState<Airline[] | null>(null);
  const [loadAirlines, {data}] = useLazyQuery(GET_AIRLINES);

  useEffect(() => {
    if (data) {
      setAirlines(data.airlines);
      return;
    }

    loadAirlines();
  }, [data]);

  return airlines;
}

export function useAirports() {
  const [airports, setAirports] = useState<AirPort[] | null>(null);
  const [loadAirports, {data}] = useLazyQuery(GET_AIRPORTS);

  useEffect(() => {
    if (data) {
      setAirports(data.airports);
      return;
    }

    loadAirports();
  }, [data]);

  return airports;
}