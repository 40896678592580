import React, {useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import {
  airportDropdownStyles,
  customDropdownStyles,
  customDropdownStylesNoColor
} from "../../../../../constants/dropdownStyles";
import FlyDateTime from "./FlyDateTime";
import {IFlight} from "../../../../../types/flightStoreTypes";
import {Airline, AirPort} from "../../../../../types/FlightTypes";
import {useDispatch, useSelector} from "react-redux";
import {setFlight} from "../../../../../store/actions/airFareActions";
import {AppState} from "../../../../../store/reducers";
import {getAirlineOptions, getAirportOptions} from "../../../../../utils/common";

type FlyFragmentProps = {
  flight: IFlight
}

const FlyFragment: React.FC<FlyFragmentProps> = (props) => {
  const airlines: Airline[] | null = useSelector((state: AppState) => state.flight.airlines);
  const airports: AirPort[] | null = useSelector((state: AppState) => state.flight.airports);
  const airlineOptions = getAirlineOptions(airlines);
  const airportOptions = getAirportOptions(airports);

  const initDeparturePort = airportOptions.find(airPort => airPort.value === props.flight.departurePort);
  const initArrivalPort = airportOptions.find(airPort => airPort.value === props.flight.arrivalPort);
  const airLine = airlineOptions.find(airCarrier => airCarrier.value === props.flight.carrier);

  const dispatch = useDispatch();

  // const handleDeparturePortChange = (departurePort: AirPort | AirPort[] | null | undefined) => {
  // const handleDeparturePortChange = (option: ValueType<AirPort>) => {
  // const handleDeparturePortChange = (value: string, label: string, isFixed: boolean) => {

  // :TODO cleanup types
  const handleDeparturePortChange = (option: any) => {
    if (option) {
      dispatch(setFlight({...props.flight, departurePort: option.value}));
    }
  };

  const handleArrivalPortChange = (option: any) => {
    if (option) {
      dispatch(setFlight({...props.flight, arrivalPort: option.value}));
    }
  };

  const handleAriLineChange = (option: any) => {
    if (option){
      dispatch(setFlight({...props.flight, carrier: option.value}));
    }
  };

  const SingleValue = (props: any) => (
    <components.SingleValue {...props}>{props.data.value}</components.SingleValue>
  );

  const filterAirports = (inputValue: string) => {
    return airportOptions.filter(i =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const loadAirportOptions = (inputValue: string,
                              callback: (filteredOptions: {value: string, label: string}[]) => void) => {
    setTimeout(() => {
      callback(filterAirports(inputValue));
    }, 500);
  };

  return (
    <Row className='mb-4'>
      <Col md={4} className='pr-0 mb-2'>
        <AsyncSelect
          cacheOptions={true}
          loadOptions={loadAirportOptions}
          defaultValue={initDeparturePort}
          styles={airportDropdownStyles}
          components={{SingleValue}}
          noOptionsMessage={() => 'type to search'}
          onChange={(option) => option && handleDeparturePortChange(option)}
        />
        {/*<Select*/}
        {/*  styles={airportDropdownStyles}*/}
        {/*  className="basic-single"*/}
        {/*  classNamePrefix="select"*/}
        {/*  defaultValue={initDeparturePort}*/}
        {/*  name="color"*/}
        {/*  components={{SingleValue}}*/}
        {/*  onChange={(option) => option && handleDeparturePortChange(option)}*/}
        {/*  options={airportOptions}*/}
        {/*/>*/}
      </Col>
      <FlyDateTime flight={props.flight} dateType={'departure'}/>

      <Col md={4} className='pr-0'>
        <AsyncSelect
          cacheOptions={true}
          loadOptions={loadAirportOptions}
          defaultValue={initArrivalPort}
          styles={airportDropdownStyles}
          components={{SingleValue}}
          noOptionsMessage={() => 'type to search'}
          onChange={(option) => option && handleArrivalPortChange(option)}
        />
        {/*<Select*/}
        {/*  styles={airportDropdownStyles}*/}
        {/*  className="basic-single"*/}
        {/*  classNamePrefix="select"*/}
        {/*  defaultValue={initArrivalPort}*/}
        {/*  name="color"*/}
        {/*  components={{SingleValue}}*/}
        {/*  onChange={(option) => option && handleArrivalPortChange(option)}*/}
        {/*  options={airportOptions}*/}
        {/*/>*/}
      </Col>
      <FlyDateTime flight={props.flight} dateType={'arrival'}/>

      <Col md={6} className='pr-0'>
        <Select
          styles={customDropdownStylesNoColor}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={airLine}
          name="color"
          onChange={(option) => option && handleAriLineChange(option)}
          options={airlineOptions}/>
      </Col>
      <Col md={6} className='mt-2'>
        {/*<FormControl type='text' placeholder='30 Kg'/>*/}
      </Col>


    </Row>
  );
};

export default FlyFragment;