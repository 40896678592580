import React, {useEffect, useState} from 'react';
import AirInfoEditor from "./AirInfoEditors/AirInfoEditor";
import MainPhotoEditor from "./MainPhotoEditor/MainPhotoEditor";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {AIR_TRAVEL_EDITOR, PHOTO_EDITOR, SUMMARY_EDITOR} from "../../../constants/actions";
import SummaryEditor from "./SummaryEditor/SummaryEditor";
import {Col} from "react-bootstrap";

const Editors: React.FC = (props) => {
  const activeEditor = useSelector((state: AppState) => state.tour.activeEditor);
  let [scrollHeight, setScrollHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', updateEditorMargin);
  });

  const  updateEditorMargin = () => {
    const height = window.pageYOffset;
    // const scrollHeight = height > 80 ? height - 180 : 0;
    const scrollHeight = height > 80 ? height - 150 : 0;
    setScrollHeight(scrollHeight);
  };

  return (
    <React.Fragment>
      <Col xs={12} md={7} className='editor-padding' style={{paddingTop: scrollHeight}}>
        {props.children}
      </Col>
    </React.Fragment>
  );
};

export default Editors;