
export const DASHBOARD_ROUTES = {
  TOUR: {
    ALL: '/tours/all'
  },
  BOOKING: {
    ALL: '/bookings/all'
  },
  SITE_CONTENT: {
    CAROUSEL: '/carousel-manager',
    TEST_PAYMENT: '/test-admin-payment',
  }

};