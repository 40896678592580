import React from "react";
import { Card } from "react-bootstrap";

const BlogPostPreview: React.FC<IBlogPostPreviewTypes> = (
  props: IBlogPostPreviewTypes
) => {
  const postBody =
    props.postBody.length > 150
      ? props.postBody.substr(0, 150).concat("...")
      : props.postBody;
  return (
    <Card className="th-blog-post-preview-card shadow-1">
      <a href={props.postUrl}>
        <Card.Img
          variant="top"
          src={props.imgSrc}
          alt={props.imgAlt}
          className="th-blog-post-preview-card-img"
        />
      </a>
      <Card.Body className="th-blog-post-preview-card-body">
        <Card.Title as="h5" className="th-blog-post-preview-card-title text-center">
          <a href={props.postUrl}>{props.postTitle}</a>
        </Card.Title>
        <Card.Text className="th-blog-post-preview-card-text">{postBody}</Card.Text>
      </Card.Body>
      <Card.Footer className="th-blog-post-preview-card-footer">
        <a href={props.postUrl}>
          <p className="th-blog-post-preview-card-read-more">
            Read More
            <i className="feather icon-arrow-right icon" />
          </p>
        </a>
      </Card.Footer>
    </Card>
  );
};

export interface IBlogPostPreviewTypes {
  imgSrc: string;
  imgAlt: string;
  postTitle: string;
  postBody: string;
  postUrl: string;
}

export default BlogPostPreview;
