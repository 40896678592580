import * as ACTION_TYPES from "../../constants/actions";
import {
  AddActivity,
  AddDay,
  ClearTourInEditor,
  CloneActivity,
  CloneDay,
  CloseEditor,
  Destination,
  IActivity,
  IAdminReview,
  IDay,
  IFacility,
  MoveActivity,
  MoveActivityToDay,
  MoveDay,
  MoveLocation,
  PhotoEditor,
  Prices,
  RemoveItem,
  SetActiveEditor,
  SetAdminReview,
  SetDestinations,
  SetEditingComplete,
  SetErrorSummary,
  SetFacilityID,
  SetFacilityInEditor,
  SetFileUploaded,
  SetInFacilityMode,
  SetInSettingsMode,
  SetItemPhoto,
  SetPhotoEditor,
  SetPhotoSrc,
  SetPublishStatus,
  SetQuickSaved,
  SetReviewStatus,
  SetSaved,
  SetTitle,
  SetTourID,
  SetTourLineToEdit,
  SetTourPrices,
  TourLineState
} from "../../types/storeTypes";
import {ReviewStatus} from "../../constants/values";

/**
 * set editor type to be Photo Editor or Air Travel Editor.
 * @param editorType
 */
export function setActiveEditor(editorType: typeof ACTION_TYPES.AIR_TRAVEL_EDITOR | typeof ACTION_TYPES.PHOTO_EDITOR |
  typeof ACTION_TYPES.SUMMARY_EDITOR | typeof ACTION_TYPES.NO_EDITOR | typeof ACTION_TYPES.REVIEW_EDITOR |
  typeof ACTION_TYPES.REVIEW_VIEWER):
  SetActiveEditor {
  return{
    type: ACTION_TYPES.SET_EDITOR,
    editorType: editorType
  }
}

/**
 * sets photo editor type and its editing component
 * @param editor
 */
export function setPhotoEditor(editor: PhotoEditor | null): SetPhotoEditor {
  return{
    type: ACTION_TYPES.SET_PHOTO_EDITOR,
    editor: editor,
  }
}

/**
 * set item with the uploaded photo to store.
 * @param photoEditor can be IDay, IActivity or IAirTravel
 */
export function setItemPhoto(photoEditor: PhotoEditor): SetItemPhoto {
  return{
    type: ACTION_TYPES.SET_ITEM_PHOTO,
    payload: photoEditor
  }
}

/**
 * add a new day to tourline
 * @param dayNum - add in between option, add new Day above or below @param dayNum
 * @param location - add above or below
 */
export function addDay(dayNum?: number, location?: MoveLocation) : AddDay {
  return {
    type: ACTION_TYPES.ADD_DAY,
    dayNum: dayNum,
    location: location
  }
}

/**
 * add activity to given day
 * @param day   selected day, activity to be added.
 * @param activityNum - add in between option, add an Activity above or below @param activityNum
 * @param location - add above or below
 */
export function addActivity(day: IDay, activityNum?: number, location?: MoveLocation): AddActivity {
  return {
    type: ACTION_TYPES.ADD_ACTIVITY,
    day: day,
    activityNum: activityNum,
    location: location
  }
}

/**
 * close current active editor (photo or air travel)
 */
export function closeEditor(): CloseEditor {
  return {
    type: ACTION_TYPES.CLOSE_EDITOR
  }
}

/**
 * remove Day or Activity
 * @param photoEditor
 */
export function removeItem(photoEditor: PhotoEditor): RemoveItem {
  return {
    type: ACTION_TYPES.REMOVE_ITEM,
    payload: photoEditor
  }
}

/**
 * Set tour title
 * @param title
 */
export function setTitle(title: string | null): SetTitle {
  return {
    type: ACTION_TYPES.SET_TITLE,
    title: title
  }
}

export function setEditingComplete(isCompleted: boolean): SetEditingComplete {
  return {
    type: ACTION_TYPES.SET_COMPLETE,
    isComplete: isCompleted
  }
}

/**
 * @desc set file status to uploaded to cdn.
 * @param isUploaded
 */
export function setFileUpload(isUploaded: boolean): SetFileUploaded {
  return {
    type: ACTION_TYPES.SET_FILE_UPLOADED,
    isUploaded: isUploaded
  }
}

/**
 * set id of saved tour to store.
 * @param id
 */
export function setTourId(id: string): SetTourID {
  return {
    type: ACTION_TYPES.SET_TOUR_ID,
    id: id
  }
}


export function setSaved(isSaved: boolean): SetSaved {
  return {
    type: ACTION_TYPES.SET_SAVED,
    isSaved: isSaved
  }
}

/**
 * quick save on changes
 * @param isSaved
 */
export function setQuickSaved(isQuickSaved: boolean): SetQuickSaved {
  return {
    type: ACTION_TYPES.SET_QUICK_SAVED,
    isQuickSaved: isQuickSaved,
  }
}

/**
 * set photo source to cdn, on uploaded.
 * @param src
 */
export function setPhotoSrc(src: string): SetPhotoSrc {
  return {
    type: ACTION_TYPES.SET_PHOTO_SRC,
    src: src
  }
}

/**
 * set countries / destinations that are covered by the tour.2
 * @param destinations
 */
export function setDestinations(destinations: Destination[]): SetDestinations {
  return {
    type: ACTION_TYPES.SET_DESTINATIONS,
    destinations: destinations
  }
}

/**
 * set error list
 * @param errors
 */
export function setErrorSummary(errors: string[]): SetErrorSummary {
  return {
    type: ACTION_TYPES.SET_ERRORS,
    errors: errors
  }
}

/**
 * set original & discounted prices of tour
 * @param prices
 * @constructor
 */
export function setTourPrices(prices: Prices) : SetTourPrices {
  return {
    type: ACTION_TYPES.SET_PRICES,
    prices: prices
  }
}

/**
 * enable / disable facility view of an activity.
 * @param inFacilityMode
 */
export function setInFacilityMode(inFacilityMode: boolean): SetInFacilityMode {
  return {
    type: ACTION_TYPES.SET_IN_FACILITY_MODE,
    payload: inFacilityMode
  }
}

/**
 * enable or disable settings tab view of an activity
 * @param inSettingsMode
 */
export const setInSettingsMode = (inSettingsMode: boolean): SetInSettingsMode => {
  return {
    type: ACTION_TYPES.SET_IN_SETTINGS_MODE,
    payload: inSettingsMode
  }
}

/**
 * set facility ID of current facility editor
 * @param facilityID
 */
export function setFacilityID(facilityID: string | null): SetFacilityID {
  return {
    type: ACTION_TYPES.SET_FACILITY_ID,
    payload: facilityID
  }
}

/**
 * set facility from list, to editor
 * @param facility
 */
export function setFacilityInEditor(facility: IFacility): SetFacilityInEditor {
  return {
    type: ACTION_TYPES.SET_FACILITY_IN_EDITOR,
    payload: facility
  }
}

/**
 * clear tourline & reset state, to add new tour
 */
export function clearTourInEditor(): ClearTourInEditor {
  return {
    type: ACTION_TYPES.CLEAR_TOUR_IN_EDITOR
  }
}

/**
 * set tour from db to edit
 * @param tourState
 */
export function setTourLineToEdit(tourState: TourLineState) : SetTourLineToEdit {
  return {
    type: ACTION_TYPES.SET_TOUR_TO_EDIT,
    payload: tourState
  }
}

/**
 * set publish status received from db, if edited, status received from db is false
 * @param status
 */
export function setPublishStatus(status: boolean): SetPublishStatus {
  return {
    type: ACTION_TYPES.SET_PUBLISH_STATUS,
    payload: status
  }
}

/**
 * Move a day up or down
 * @param dayNum
 * @param location
 */
export function moveDay(dayNum: number, location: MoveLocation) : MoveDay {
  return {
    type: ACTION_TYPES.MOVE_DAY,
    dayNum: dayNum,
    location: location
  }
}

/**
 * move activity up or down inside day
 * @param activity
 * @param location
 */
export function moveActivity(activity: IActivity, location: MoveLocation) : MoveActivity {
  return {
    type: ACTION_TYPES.MOVE_ACTIVITY,
    activity: activity,
    location: location
  }
}

/**
 * clone activity in the tourline
 * @param activity
 */
export const cloneActivity = (activity: IActivity): CloneActivity => {
  return {
    type: ACTION_TYPES.CLONE_ACTIVITY,
    payload: activity
  }
}

/**
 * move activity from current activity.dayNumber to toDayIndex
 * @param toDayIndex
 */
export const moveActivityToDay = (toDayIndex: number): MoveActivityToDay => {
  return {
    type: ACTION_TYPES.MOVE_ACTIVITY_TO_DAY,
    payload: toDayIndex
  }
}

/**
 * dispatch day to clone in tourline
 * @param day
 */
export const cloneDay = (day: IDay): CloneDay => {
  return {
    type: ACTION_TYPES.CLONE_DAY,
    payload: day
  }
}

/**
 * set review data (comments, reject reason) to store
 * @param adminReview
 */
export function setAdminReview(adminReview: IAdminReview | null) : SetAdminReview {
  return {
    type: ACTION_TYPES.SET_ADMIN_REVIEW,
    payload: adminReview
  }
}

/**
 * set review status of tour to store
 * @param reviewStatus
 */
export function setReviewStatus(reviewStatus: ReviewStatus | null) : SetReviewStatus {
  return {
    type: ACTION_TYPES.SET_REVIEW_STATUS,
    payload: reviewStatus
  }
}