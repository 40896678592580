import React from 'react';
import {Col, FormControl, Row} from "react-bootstrap";
import {IPax} from "../../types/clientStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setPax} from "../../store/actions/clientActions";

const PaxPicker: React.FC = () => {
  const pax: IPax = useSelector((state: AppState) => state.client.search.pax);
  const dispatch = useDispatch();

  const dispatchPax = (pax: IPax) => {
    dispatch(setPax({...pax, isDefault: false}));
  };

  const handleAddAdults = () => {
    const newPax: IPax = {...pax, adults: pax.adults + 1};
    dispatchPax(newPax);
  };

  const handleSubAdults = () => {
    const numAdults = pax.adults > 1 ? pax.adults - 1 : pax.adults;
    const newPax: IPax = {...pax, adults: numAdults};
    dispatchPax(newPax);
  };

  const handleAddChildren = () => {
    const newPax: IPax = {...pax, children: pax.children + 1};
    dispatchPax(newPax);
  };
  const handleSubChildren = () => {
    const numChildren = pax.children > 1 ? pax.children - 1 : 0;
    const newPax: IPax = {...pax, children: numChildren};
    dispatchPax(newPax);
  };

  return (
    <div className='pax-pick'>
      <Row className='mt-1'>
        <Col md={{span: 4, offset: 6}} xs={{span: 4, offset: 6}} className='text-center'>
          <i className='feather icon-chevron-up duration-arrow' onClick={handleAddAdults}/>
        </Col>
      </Row>

      <Row className='mt-0 mb-0'>
        <Col md={{span: 4, offset: 2}} xs={{span: 4, offset: 2}} className='text-right'>
          <p className='pax-type'>Adults </p>
        </Col>
        <Col md={{span: 4}} xs={{span: 4, offset: 0}} className='pl-4 pr-4'>
          <FormControl
            size={'lg'}
            type='text'
            value={pax.adults + ''}
            readOnly
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span: 4, offset: 6}} xs={{span: 4, offset: 6}} className='text-center'>
          <i className='feather icon-chevron-down duration-arrow' onClick={handleSubAdults}/>
        </Col>
      </Row>


      <Row className='mt-2'>
        <Col md={{span: 4, offset: 6}} xs={{span: 4, offset: 6}} className='text-center'>
          <i className='feather icon-chevron-up duration-arrow' onClick={handleAddChildren}/>
        </Col>
      </Row>

      <Row className='mt-0 mb-0'>
        <Col md={{span: 4, offset: 2}}  xs={{span: 4, offset: 2}}className='text-right'>
          <p className='pax-type'>Children </p>
        </Col>
        <Col md={{span: 4}} xs={{span: 4, offset: 0}} className='pl-4 pr-4'>
          <FormControl
            size={'lg'}
            type='text'
            value={pax.children + ''}
            readOnly
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span: 4, offset: 6}} xs={{span: 4, offset: 6}} className='text-center'>
          <i className='feather icon-chevron-down duration-arrow' onClick={handleSubChildren}/>
        </Col>
      </Row>

    </div>
  );
};

export default PaxPicker;