import React, {useEffect, useState} from 'react';
// @ts-ignore
import ReactTags from 'react-tag-autocomplete';

import {destinationsObjs as DestinationList} from '../../../../constants/values/destinations';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";
import {Destination} from "../../../../types/storeTypes";
import {setDestinations} from "../../../../store/actions/tourlineActions";
import {useQuery} from "@apollo/react-hooks";
import {DESTINATION_FACILITIES} from "../../../../graphql/queries/FacilityQueries";

type Tag = {
  facility: string
  name: string
}

const Destinations: React.FC = () => {
  // const tags: Tag[] = [
  // ];

  // const suggestions: Tag[] = DestinationList;
  const {loading, error, data} = useQuery(DESTINATION_FACILITIES);

  // let suggestions: Tag[] = [];
  const [suggestions, setSuggestions] = useState();

  useEffect(() => {
    console.log('Destinations');
    if (data) {
      // console.log(data);
      // suggestions = data.destinations;
      setSuggestions(data.destinations)
    }
  }, [data]);

  // const [destinations, setDestinations] = useState(tags);
  const destinations: Destination[] = useSelector((state: AppState) => state.tour.destinations);
  const dispatch = useDispatch();

  const dispatchDestinations = (destinations: Destination[]) => {
    dispatch(setDestinations(destinations));
  };

  const handleDelete = (i: number) => {
    console.log('Delete ' + i);
    if(!destinations) {
      return;
    }
    const tags = destinations.slice(0);
    tags.splice(i, 1);
    dispatchDestinations(tags);
  };

  const handleAddition = (tag: Tag) => {
    // console.log('handle add');
    let tags: Destination[] = [];
    if(destinations){
      tags = destinations.slice();
    }
    // const tagsA = [].concat(tags, tag);
    // tags.push(tag);
    tags.push({facility: tag.facility, name: tag.name}); //to remove __typename
    dispatchDestinations(tags);
  };

  return (
    <div>
      {loading && <span className="spinner-border spinner-border-sm mr-1 position-absolute" role="status"/>}

      {data &&
      <ReactTags
        classNames={{
          root: 'react-tags bootstrap-tagsinput add-destinations',
          selectedTag: 'react-tags__selected-tag btn-info'
        }}
        tags={destinations}
        minQueryLength={1}
        suggestions={suggestions}
        placeholder={'Add destinations'}
        // handleDelete={this.handleSuggestionsDelete.bind(this)}
        // handleAddition={this.handleSuggestionsAddition.bind(this)} />
        handleDelete={handleDelete}
        handleAddition={handleAddition}/>
      }

    </div>
  );
};

export default Destinations;