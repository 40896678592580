import React from 'react';
import {Row, Col, InputGroup, FormControl} from 'react-bootstrap';
import NumberFormat from "react-number-format";
import {IAirFare, ITicketPrice} from "../../../../../types/flightStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";
import {setTicketPrice} from "../../../../../store/actions/airFareActions";

const TicketPrice: React.FC = () => {
  const activeFare: IAirFare | null = useSelector((state: AppState) => state.flight.activeFare);
  const ticketPrices: ITicketPrice | null = activeFare ? activeFare.price : null;

  const dispatch = useDispatch();

  let adultPrice: number | string = '';
  let kidPrice: number | string = '';

  if (ticketPrices) {
    adultPrice = ticketPrices.adult > 0 ? ticketPrices.adult : '';
    kidPrice = ticketPrices.kid > 0 ? ticketPrices.kid: '';
  }

  const handleAdultPriceChange = (value: any) => {
    if (ticketPrices) {
      dispatch(setTicketPrice({...ticketPrices, adult: value.floatValue}));
    }
  };

  const handleMasterPriceChange = (value: any) => {
    if (ticketPrices) {
      dispatch(setTicketPrice({...ticketPrices, kid: value.floatValue}));
    }
  };

  return (
    <Row className='ticket-prices mt-2'>
      <Col md={6}>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <i className='feather icon-user'/>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat className="form-control ticket-price" placeholder="Ticket Fare" thousandSeparator prefix="Rs. "
                        suffix=" /-"
                        onValueChange={handleAdultPriceChange}
                        defaultValue={adultPrice}
          />
        </InputGroup>
      </Col>
      <Col md={6}>
        <InputGroup className="mb-3">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <i className='fas fa-baby'/>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <NumberFormat className="form-control ticket-price" placeholder="Master's Fare" thousandSeparator
                        prefix="Rs. "
                        onValueChange={handleMasterPriceChange}
                        defaultValue={kidPrice}
                        suffix=" /-"/>
        </InputGroup>
      </Col>
    </Row>
  );
};

export default TicketPrice;