import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setItemPhoto, setTitle} from "../../../../store/actions/tourlineActions";
import {AppState} from "../../../../store/reducers";
import {IActivity} from "../../../../types/storeTypes";

const TourTitleEdit: React.FC = (props, ref) => {
  const placeHolderText = 'Click here to change Tour title.';
  let title = useSelector((state: AppState) => state.tour.tourTitle);
  title = title ? title : placeHolderText;

  const [tourTitle, setTourTitle] = useState(title);
  const [isTitleEditMode, setTitleEditMode] = useState(false);
  const [isTitleInputFocused, setTitleInputFocused] = useState(false);
  const dispatch = useDispatch();

  const showInputMode = () => {
    setTitleEditMode(true);
  };

  const handleChangeTourTitle = (newTitle: string) => {
    newTitle = newTitle.length > 0 ? newTitle : placeHolderText;
    setTourTitle(newTitle);
  };

  const handleTitleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      setTitleEditMode(false);
      const newTitle = tourTitle.length > 0 ? tourTitle : placeHolderText;
      // dispatch(setTitle(newTitle));
      dispatchNewTitle(newTitle);
    }
  };

  const dispatchNewTitle = (newTitle: string) => {
    dispatch(setTitle(newTitle !== placeHolderText ? newTitle : null));
  };

  const changeTourTitle = (newTitle: string) => {
    newTitle = newTitle.length > 0 ? newTitle : placeHolderText;
    setTourTitle(newTitle);
    setTitleEditMode(false);

    // dispatch(setTitle(newTitle));
    dispatchNewTitle(newTitle);
  };

  const hideNameEdit = () => { //click anywhere else in the container when input is visible, but not focused.
    if (!isTitleInputFocused && isTitleEditMode) {
      setTitleEditMode(false);
    }
  };

  return (
    <div onClick={hideNameEdit}>
      <div className={`activity-name-edit ${isTitleEditMode ? 'in-edit-mode' : ''}`}>
        {!isTitleEditMode &&
        <p className='activity-title' onClick={showInputMode}>
          {tourTitle}
        </p>}

        {isTitleEditMode &&
        <Form.Control type='text' size='sm'
                     defaultValue={tourTitle}
                     onBlur={(e: React.ChangeEvent<HTMLInputElement>) => changeTourTitle(e.target.value)}
                     onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                       e.target.select();
                       setTitleInputFocused(true);
                     }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTourTitle(e.target.value)}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleTitleInputKeyPress(e)}
                      autoFocus
                     placeholder={placeHolderText}/>}
      </div>
    </div>
  );
};

export default TourTitleEdit;