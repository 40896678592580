import React from 'react';
import {IDestination} from "../../../Client/types/clientStoreTypes";
import {Destination} from "../../../types/storeTypes";

export const getDestinationTags = (destinations: Destination[]) => {
  const MAX_DESTINATIONS_TO_SHOW = 3;

  const getTags = () => {
    const showTags = destinations.slice(0, MAX_DESTINATIONS_TO_SHOW).map(destination => destination.name);
    const numTagsNotShown = destinations.length - showTags.length;
    return(
      showTags.join(', ') + (numTagsNotShown > 0 ? ', +' + numTagsNotShown : '')
    )
  };

  return (
      getTags()
  );
};

export const getDestinationWithNTags = (destinations: Destination[], numberToShow: number) => {
  const MAX_DESTINATIONS_TO_SHOW = numberToShow;

  const getTags = () => {
    const showTags = destinations.slice(0, MAX_DESTINATIONS_TO_SHOW).map(destination => destination.name);
    const numTagsNotShown = destinations.length - showTags.length;
    return(
      showTags.join(', ') + (numTagsNotShown > 0 ? ', +' + numTagsNotShown : '')
    )
  };

  return (
      getTags()
  );
};

