import React from 'react';
import {useQuery} from "@apollo/react-hooks";
import {GET_TOUR} from "../../graphql/queries/TourQueries";
import TourLineView from "../../components/TourLineView/TourLineView";
import {ITourLine} from "../../types/TourLineTypes";

type ViewTourProps = {
  tourID: string | null
}
const ViewTour: React.FC<ViewTourProps> = (props) => {
  const tourID = props.tourID;

  const {loading, error, data} = useQuery(GET_TOUR, {
    variables: {id: tourID},
    skip: !tourID,
    fetchPolicy: "network-only"
  });

  if (!tourID) {
    return <div>View tour here..</div>;
  }

  const tourLine: ITourLine | null = data ? data.tour : null;

  return (
    <div>
      {tourLine && <TourLineView tourLine={tourLine}/>}
    </div>
  );
};

export default ViewTour;