import * as ACTION_TYPES from "../constants/actions/DashboardActions";
import {IFacility, ITourLine} from "./TourLineTypes";
import {Pax} from "../Client/types/clientStoreTypes";
import {BillingInfo} from "../Client/types/bookingProps";

export interface IBooking {
  _id: string
  orderID: number
  tour: ITourLine
  departure: Date
  pax: Pax
  billingInfo: BillingInfo
  total: number
  totalPaid: number
  createdAt: Date
}

export interface ICarouselImage {
  position: string
  photo?: string
  thumb?: string
}

export interface ICarouselPosition extends ICarouselImage{
  positionNum: number
  isComplete: boolean
}

export enum CarouselTabKeys {
  positionA = 'position-A',
  positionB = 'position-B',
  positionC = 'position-C',
}

export interface CarouselTileInfo {
  destinations: string[] | null
  category: string | null
  title: string | null
}

export interface ICarouselTile extends CarouselTileInfo {
  id?: string
  images: ICarouselImage[]
}

// consist of tiles with it's category name
export interface ICarousel{
  category: string
  tiles: ICarouselTile[]
}

export interface DashboardDataState {
  bookings: IBooking[] | null
  tours: ITourLine[] | null
  carouselPositions: ICarouselPosition[] | null
  carouselActiveKey: CarouselTabKeys
  carouselTileInfo: CarouselTileInfo | null
  activeCarouselTileID: string | null
}

export interface SetToursData {
  type: typeof ACTION_TYPES.SET_TOURS
  payload: ITourLine[] | null
}

export interface SetBookingsData {
  type: typeof ACTION_TYPES.SET_BOOKINGS
  payload: IBooking[] | null
}

export interface SetCarouselPositions {
  type: typeof ACTION_TYPES.SET_CAROUSEL_POSITIONS
  payload: ICarouselPosition[] | null
}

export interface SetCarouselActiveKey {
  type: typeof ACTION_TYPES.SET_CAROUSEL_ACTIVE_KEY
  payload: CarouselTabKeys
}

export interface SetCarouselInfo {
  type: typeof ACTION_TYPES.SET_CAROUSEL_INFO
  payload: CarouselTileInfo
}

export interface ResetCarouselTile {
  type: typeof ACTION_TYPES.RESET_CAROUSEL_TILE
}

export interface SetActiveCarouselID {
  type: typeof ACTION_TYPES.SET_ACTIVE_CAROUSEL_ID
  payload: string | null
}

export type DashboardDataActionTypes = SetToursData | SetBookingsData | SetCarouselPositions | SetCarouselActiveKey |
  SetCarouselInfo | ResetCarouselTile | SetActiveCarouselID