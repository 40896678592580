import React from 'react';
import {Dropdown} from "react-bootstrap";
import DEMO from "../../../../store/constant";

type PriceOptionsProps = {
  setTicketOption: ((option: string) => void)
}

export const FACILITY_TICKET = {
  INCLUDED: 'included',
  NOT_INCLUDED: 'not included',
  NOT_APPLICABLE: 'N/A',
};

const PriceOptions: React.FC<PriceOptionsProps> = (props) => {

  const handleOnItemSelect = (selection: string) => {
    props.setTicketOption(selection);
  };

  return (
    <div className="editor-options">
      <Dropdown alignRight={true} className="btn-group card-option" onSelect={((eventKey:string) => handleOnItemSelect(eventKey))}>
        <Dropdown.Toggle id="dropdown-flight" className="btn-icon">
          <i className='ic ic-ticket ticket-icon' title='ticket / extra pricing'/>
        </Dropdown.Toggle>
        <Dropdown.Menu as='ul' className="list-unstyled card-option">
          <Dropdown.Item as='li' className="dropdown-item" eventKey={FACILITY_TICKET.INCLUDED}>
            <i className='feather icon-check-circle'/>
            <a href={DEMO.BLANK_LINK}> included </a>
          </Dropdown.Item>
          <Dropdown.Item as='li' className="dropdown-item remove" eventKey={FACILITY_TICKET.NOT_INCLUDED}>
            <i className='feather icon-x-square'/>
            <a href={DEMO.BLANK_LINK}> NOT included </a>
          </Dropdown.Item>
          <Dropdown.Item as='li' className="dropdown-item not-applicable" eventKey={FACILITY_TICKET.NOT_APPLICABLE}>
            <i className='feather icon-check'/>
            <a href={DEMO.BLANK_LINK}> N/A </a>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default PriceOptions;