import React from 'react';
import {Row, Col} from 'react-bootstrap';
import {IAirFare, IFlight} from "../../../../../types/flightStoreTypes";
import NumberFormat from "react-number-format";
import moment from "moment";
import {setActiveFare} from "../../../../../store/actions/airFareActions";
import {useDispatch} from "react-redux";
import {getDateFormatted, getDateTime} from "../../../../../utils/CommonFunctions";
import FareOptions from "./FareOptions";
import {removeTypeName} from "../../../../../utils/common";

type FlightListItemProps = {
  ticket: IAirFare
  // departureDate: Date
}

const FlightListItem: React.FC<FlightListItemProps> = (props) => {
  const ticket = props.ticket;
  const price = ticket.price;
  const seatClass = ticket.seatClass ? ticket.seatClass.substring(0, 1).toUpperCase() : 'N/A';
  const dateFormat = 'DD/MM/YY';
  // console.log(ticket);

  const dispatch = useDispatch();

  // :TODO calc departure and return for daily departure
  let departureDate: Date | null = ticket.flights ? getDateTime(ticket.flights[0].departureDateTime) : null;
  // let departureDate: Date | null = ticket.flights ? ticket.flights[0].departureDateTime : null;
  const returnDate: Date | null = ticket.flights ?
    getDateTime(ticket.flights[ticket.flights.length - 1].arrivalDateTime) : null;

  const handleClickItem = () => {
    // console.log(ticket);
    const ticketDetails: IAirFare = {...ticket};
    if (ticketDetails.flights) {
      const flights = ticketDetails.flights;
      flights.forEach((flight: IFlight, idx) => {
      // ticketDetails.flights.map((flight: IFlight) => {
        flight.departureDateTime = getDateTime(flight.departureDateTime);
        flight.arrivalDateTime = getDateTime(flight.arrivalDateTime);

        flight = {orderNumber: flight.orderNumber,
          departurePort: flight.departurePort, departureDateTime: flight.departureDateTime,
          arrivalPort: flight.arrivalPort, arrivalDateTime: flight.arrivalDateTime,
        carrier: flight.carrier};

        flights[idx] = flight;
        // return flight;
      });
    }
    dispatch(setActiveFare(ticketDetails));
  };

  return (
    <Row className='flight-list-item' >
      <Col xs={1} className='text-left pl-2 pr-0' onClick={handleClickItem}>
        <i className='fa fa-plane'/>
      </Col>
      <Col xs={5} className='pr-0 pl-1' onClick={handleClickItem}>
        {/*<label className='flight-date'>17/12/19 - 24/12/19</label>*/}
        <label className='flight-date'>
          {getDateFormatted(departureDate, dateFormat)} - {getDateFormatted(returnDate, dateFormat)}
        </label>
        <label className='flight-class'>{seatClass}</label>
      </Col>
      <Col xs={5} className='text-center pr-0 pl-0' onClick={handleClickItem}>
        <label className='fare-summary'>Rs.
          <NumberFormat value={price.adult} displayType={'text'} thousandSeparator={true} decimalScale={2}/> /&nbsp;
          <NumberFormat value={price.kid} displayType={'text'} thousandSeparator={true} decimalScale={2}/>
          {/*58,700 / 53,200*/}
        </label>
      </Col>
      <Col xs={1} className='text-center pl-0 pr-1 fare-options'>
        {/*<i className='feather icon-more-vertical flight-item-options'/>*/}
        <FareOptions ticket={ticket}/>
      </Col>
    </Row>
  );
};

export default FlightListItem;