import {IDeal, ITourLine} from "../../types/TourLineTypes";
import {IPax} from "./clientStoreTypes";
import * as ACTIONS from '../constants/actions/booking';
import {BillingInfo} from "./bookingProps";
import {PaxType} from "../constants/values";

export const CREDIT_PAYMENT = 'credit';
export const FULL_PAYMENT = 'full';

export interface IBookingDeal {
  tour: ITourLine
  deal: IDeal
  aot: string
}

export interface IBookingRequest {
  tourDeal: IBookingDeal
  pax: IPax
}

export interface NewUser {
  email: string | null
  password: string | null
}

export interface InitBookingData {
  orderID: number | null
  total: number | null
}

/**
 * record data form
 */
export interface IPersonalDetails {
  fullName: string,
  address: string,
  city: string,
  postCode: string,
  country: string,
  contactNumber: string,
  email: string,
}

/**
 * record billing details
 */
export interface IBillingInfo {
  fullName: string,
  billingAddress: string,
  city: string,
  postCode: string,
  country: string,
  email: string,
}

/**
 * keep country of bank and name of bank for credit card installment type payments only.
 */
export interface ICardInfo {
  countryOfBank: string | null
  bank: string | null
}

export interface PassportData {
  fullName: string
  passportNumber: string
  paxType?: PaxType // adult or child
  age?: number
  issueDate?: Date //pp issued date
  expiry?: Date
  country?: string // issued country
  sex?: string
  surname?: string
  otherNames?: string
  dateOfBirth?: Date
}

export interface TravelDoc {
  imagePassports: string[]
  dataPassports: PassportData[]
}

/**
 * @var isValidated - all form input validated.
 */
export interface BookingState {
  tourDeal: IBookingDeal | null
  pax: IPax
  personalDetails: BillingInfo | null
  newUer: NewUser | null
  isSameBillingAddress: boolean
  billingInfo: BillingInfo | null
  paymentMode: typeof CREDIT_PAYMENT | typeof FULL_PAYMENT
  cardInfo: ICardInfo | null
  newsletter: boolean
  agreement: boolean
  isValidated: boolean
  discountCode: string | null
  discount: number
  orderID: number | null
  total: number | null

  travelDoc: TravelDoc | null
  isTravelPhotoUploading: boolean
  dataPassports: PassportData[] | null
}

export interface SetBookingDeal {
  type: typeof ACTIONS.SET_BOOKING_TOUR
  payload: IBookingRequest
}

export interface SetPersonalDetails {
  type: typeof ACTIONS.SET_PERSONAL_DETAILS
  payload: BillingInfo
}

export interface SetNewUser {
  type: typeof ACTIONS.SET_NEW_USER
  payload: NewUser | null
}

export interface SetIsSameBillingAddress {
  type: typeof ACTIONS.SET_BILLING_ADDRESS_TYPE
  payload: boolean
}

export interface SetBillingInfo {
  type: typeof ACTIONS.SET_BILLING_INFO
  payload: BillingInfo | null
}

export interface SetPaymentMode {
  type: typeof ACTIONS.SET_PAYMENT_MODE
  payload: typeof CREDIT_PAYMENT | typeof FULL_PAYMENT
}

export interface SetCardInfo {
  type: typeof ACTIONS.SET_CARD_INFO
  payload: ICardInfo | null
}

export interface SetIsNewsletterPreferred {
  type: typeof ACTIONS.SET_NEWSLETTER_PREFERENCE
  payload: boolean
}

export interface SetIsAgreed {
  type: typeof ACTIONS.SET_AGREEMENT
  payload: boolean
}

export interface SetIsValidated {
  type: typeof ACTIONS.SET_IS_VALIDATED
  payload: boolean
}

export interface SetOrderID {
  type: typeof ACTIONS.SET_ORDER_ID
  payload: number | null
}

export interface SetTotal {
  type: typeof ACTIONS.SET_TOTAL
  payload: number | null
}

export interface SetInitBookingData {
  type: typeof ACTIONS.SET_INIT_BOOKING_DATA
  payload: InitBookingData
}

/**
 * on reload page reset some data. refer to reducer to changes / resets.
 */
export interface ResetBooking {
  type: typeof ACTIONS.RESET_BOOKING
}

export interface SetTravelDoc{
  type: typeof ACTIONS.SET_TRAVEL_DOC
  payload: TravelDoc | null
}

export interface SetTravelPhotoUploadingStatus {
  type: typeof ACTIONS.IS_TRAVEL_PHOTO_UPLOADING
  payload: boolean
}

export interface SetDataPassports {
  type: typeof ACTIONS.SET_DATA_PASSPORTS
  payload: PassportData[] | null
}


export type BookingStoreTypes = SetBookingDeal | SetPersonalDetails | SetNewUser | SetIsSameBillingAddress |
  SetBillingInfo | SetPaymentMode | SetCardInfo | SetIsNewsletterPreferred | SetIsAgreed | SetIsValidated |
  SetOrderID | SetTotal | SetInitBookingData | ResetBooking | SetTravelDoc | SetTravelPhotoUploadingStatus |
  SetDataPassports