import React, {useEffect, useState} from 'react';
import SaveTourLabel from "./SaveTourLabel";
import SavingChanges from "./SavingChanges";
import {useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {PhotoEditor} from "../../../types/storeTypes";

const SaveFeedback: React.FC = () => {
  const isEditingComplete = useSelector((state: AppState) => state.tour.isEditingComplete);
  const photoEditor: PhotoEditor | null = useSelector((state: AppState) => state.tour.photoEditor);
  const isQuickSaveDone = useSelector((state: AppState) => state.tour.isQuickSaved);

  const [isComplete, setIsComplete] = useState(false);
  // let isComplete = false;
  // let isComplete = isEditingComplete && isQuickSaveDone && (photoEditor === null || photoEditor.src === 'cdn');


  useEffect(() => {
    //:TODO crude! check photo location type (cdn) for all and then pass.
    //if editor is open, src should be cdn.
    // isComplete = isEditingComplete && isQuickSaveDone && (photoEditor === null || photoEditor.src === 'cdn');
    const isCompleteToSave = isEditingComplete && isQuickSaveDone && (photoEditor === null || photoEditor.src === 'cdn');
    setIsComplete(isCompleteToSave);
    // console.log('Saving complete ' + isComplete);
  });

  return (
    <React.Fragment>
      {!isComplete && <SavingChanges/>}
      {isComplete && <SaveTourLabel/>}
    </React.Fragment>
  );
};

export default SaveFeedback;