import React from 'react';
import SearchResultItemTag from "./SearchResultItemTag";

type DurationProps = {
  numDays: number
}

const Duration: React.FC<DurationProps> = (props) => {
  const numDays = props.numDays;

  return (
    <SearchResultItemTag
      text={`${numDays}N / ${numDays + 1}D`}
    />
  );
};

export default Duration;