import {API_ROUTES} from "../routes/routes";
import axios from "axios";

export const CDN_URL_PATH = 'https://toursharbor.s3.amazonaws.com/';

export const retrieveFileURL = async (key: string) => {
  const filename = key.replace(CDN_URL_PATH, '');

  const generateGetUrl = API_ROUTES.S3_GET_URL;
  const options = {
    params: {
      Key: filename,
      ContentType: "image/jpeg"
    }
  };
  const res = await axios.get(generateGetUrl, options);

  const {data: getURL} = res;
  return getURL;
};
