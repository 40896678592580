import React from 'react';
import {Dropdown} from "react-bootstrap";
import DEMO from "../../../../../store/constant";
import {IAirFare, IFlight} from "../../../../../types/flightStoreTypes";
import {useDispatch} from "react-redux";
import {cloneActiveFare} from "../../../../../store/actions/airFareActions";
import {useLazyQuery, useMutation} from "@apollo/react-hooks";
import {ALL_AIR_TICKETS, DELETE_TICKET, TICKETS_BY_DATE} from "../../../../../graphql/mutations/AirFareMutations";
import {getDateTime} from "../../../../../utils/CommonFunctions";
import {AirFaresType, AllFaresType} from "../../../../../types/FlightTypes";
import {removeTypeName} from "../../../../../utils/common";

type FareOptionsProps = {
  ticket: IAirFare
}

const FareOptions: React.FC<FareOptionsProps> = (props) => {
  const ticket = props.ticket;
  const dispatch = useDispatch();

  const handleOnCloneClick = () => {
    // console.log(ticket.flights);
    dispatch(cloneActiveFare(ticket));
  };

  const [removeTicket] = useMutation(DELETE_TICKET, {
    variables: {fareID: ticket.fareID},
    onCompleted: async data => {
      // console.log('%c' + data.deleteAirFare._id, 'color: #ff0000');
      // console.log('%c' + data.deleteAirFare.deletedCount, 'color: #ff0000; font-size: 16px;');

      if (data.deleteAirFare.deletedCount > 0) {
        alert('ticket removed.');
      }
    },
    update: (cache, {data: {deleteAirFare}}) => {
      if (deleteAirFare.deletedCount > 0) {
        const tourID = deleteAirFare.deletedTicket.tour._id;
        const departureDate = getDateTime(deleteAirFare.deletedTicket.availableStartDate);
        departureDate.setHours(0);
        departureDate.setMinutes(0);
        departureDate.setSeconds(0);

        const airTicketsQueryType = cache.readQuery<AirFaresType>({
          query: TICKETS_BY_DATE,
          variables: {tourID: tourID, departure: departureDate}
        });
        if (airTicketsQueryType) {
          const {airTicketsByDate} = airTicketsQueryType;
          let updatedTickets = airTicketsByDate.slice();
          updatedTickets = updatedTickets.filter((ticket: IAirFare) => ticket.fareID !== deleteAirFare.deletedTicket._id);

          cache.writeQuery({
            query: TICKETS_BY_DATE,
            variables: {tourID: tourID, departure: departureDate},
            data: {airTicketsByDate: updatedTickets},
          });
        }

        const allTicketsQueryType = cache.readQuery<AllFaresType>({
          query: ALL_AIR_TICKETS,
          variables: {tourID: tourID}
        });

        if (allTicketsQueryType) {
          const {airTickets} = allTicketsQueryType;
          let updatedAllTickets = airTickets.slice();
          updatedAllTickets = updatedAllTickets.filter((ticket: any) => ticket._id !== deleteAirFare.deletedTicket._id);

          cache.writeQuery({
            query: ALL_AIR_TICKETS,
            variables: {tourID: tourID},
            data: {airTickets: updatedAllTickets},
          });
        }

      }
    }
  });

  return (
    <div className="editor-options">
      <Dropdown alignRight={true} className="btn-group card-option">
        <Dropdown.Toggle id="dropdown-flight" className="btn-icon">
          <i className="feather icon-more-vertical flight-item-options"/>
        </Dropdown.Toggle>
        <Dropdown.Menu as='ul' className="list-unstyled card-option">
          <Dropdown.Item as='li' className="dropdown-item" onClick={handleOnCloneClick}>
            <i className='feather icon-copy'/>
            <a href={DEMO.BLANK_LINK}> Clone </a>
          </Dropdown.Item>
          <Dropdown.Item as='li' className="dropdown-item remove" onClick={() => removeTicket()}>
            <i className='feather icon-trash'/>
            <a href={DEMO.BLANK_LINK}> Remove </a>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default FareOptions;