import gql from "graphql-tag";
import {ApprovalFragment} from "../fragments/TourApprovalFragment";

export const GET_LATEST_ADMIN_REVIEW = gql`
  ${ApprovalFragment}

  query getLatestApproval($tourId: String!) {
    getLatestApproval(tourId: $tourId){
      ...ApprovalFragment
    }
  }
`;