import gql from "graphql-tag";
import {AirTravelFragment, TourFragment} from "../fragments/TourFragment";

export const SEARCH_SUGGESTIONS = gql`
 query search($q: String!) {
  find(q: $q) {
    id:_id
    name
    type
    info
  }
}
`;


export const SEARCH_TOURS = gql`
  ${TourFragment}
  ${AirTravelFragment}

  
 query searchTours($query: SearchInput!) {
  search(query: $query) {
    ...TourFields
    ...AirTravelFields

  }
}
`;

export const GET_TOUR = gql`
  query Tour ($id: String!){
    tour(id: $id){
      _id
      title
      airTravel{
        photo
        airFare{
          fareID:_id
          availableType: activeDateType
          availableStartDate
          availableEndDate
          seatClass
          flights {
            orderNumber
            departurePort
            departureDateTime
            arrivalPort
            arrivalDateTime
            carrier
          }
          price {
            adult
            kid:master
          }
        }
      }
      days{
        number
        photo
        activities{
          number
          title
          photo
          facilities {
            facility
            icon
            name
            ticket
          }
        }
      }
    }
  }
`;
