import React, {useState} from 'react';
import {Redirect} from "react-router-dom";
import {STORAGE_USER} from "../../constants/values";

const Logout: React.FC = () => {
  const [redirect, setRedirect] = useState(false);

  const renderRedirect = () => {
    // return <Redirect to='/'/>
    return <Redirect
      to={{
        pathname: "/",
        search: "?redirect=logout",
        // state: { referrer: currentLocation }
      }}
    />

  };

  const handleLogout = () => {
    localStorage.removeItem(STORAGE_USER);
    setRedirect(true);
  };

  return (
    <React.Fragment>
      {redirect && renderRedirect()}
      {!redirect && handleLogout()}
    </React.Fragment>
  );
};

export default Logout;