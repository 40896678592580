import React, {PureComponent} from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {connect} from "react-redux";
import {PhotoEditor} from "../../../../types/storeTypes";
import {AppState} from "../../../../store/reducers";
import {setFileUpload, setItemPhoto, setPhotoSrc} from "../../../../store/actions/tourlineActions";
import {Dispatch} from "redux";
import {uploadImage} from "../../../../utils/UploadToBucket";
import {Button} from "react-bootstrap";
import {showAlert} from "../../../../utils/showAlert";
import {getLoadingSpan} from "../../../../utils/loading/loading";
import {ImageResizeOptions} from "../../../../types/ImageResize";
import {convertFile, resizeFile} from "../../../../utils/photoTools/image-resize";
import {INVALID_MEDIA_FILE} from "../../../../constants/errors/tourLineErrorMessages";

interface UploadPhotoProps {
  photoEditor: PhotoEditor | null
  onCropCompleted: (itemEdited: PhotoEditor) => void
  setUploadStatus: (isUploaded: boolean) => void
  setPhotoSrc: (src: string) => void
  isFileUploaded: boolean
}

const BELOW_SIZE_IMAGE_ALERT = 'Minimum photo width and height must be 620 x 240 pixels!';

interface UploadPhotoState {
  src: ArrayBuffer | string | null
  crop: {
    unit: string,
    width: number,
    height: number,
    aspect: number,
  }
  croppedImageUrl: string | null,
  croppedFile: File | null,
  isUploading: boolean
}

class UploadPhoto extends PureComponent <UploadPhotoProps, UploadPhotoState> {

  maxWidth: number = 620;
  maxHeight: number = 240;

  state: UploadPhotoState = {
    src: null,
    crop: {
      unit: 'px',
      width: this.maxWidth,
      height: this.maxHeight,
      aspect: 31 / 12,
    },
    croppedImageUrl: null,
    croppedFile: null,
    isUploading: false
  };

  imageRef: any;
  fileUrl: any;

  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      const file = e.target.files[0];

      reader.addEventListener('load', () =>
        this.setState({src: reader.result})
      );

      if (file.name.match(/\.(jpg|jpeg|png|webp|gif)$/i)) {
        reader.readAsDataURL(e.target.files[0]);
      } else {
        showAlert(INVALID_MEDIA_FILE)
      }

    }

    this.props.setUploadStatus(false);
  };

  onImageLoaded = (image: any) => {
    this.imageRef = image;
  };

  onCropComplete = (crop: any) => {
    if (crop.width < 620 || crop.height < 240) {  //restrict below size image upload
      showAlert(BELOW_SIZE_IMAGE_ALERT);
      return;
    }

    this.makeClientCrop(crop);
  };

  onCropChange = (crop: any, percentCrop: any) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    if (crop.width < 620 || crop.height < 240) { //restrict below size image upload
      // showAlert(BELOW_SIZE_IMAGE_ALERT);
      this.setState({src: null});
      return;
    }

    this.setState({crop});
    this.props.setUploadStatus(false);
  };

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl: string = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpg'
      );

      this.setState({croppedImageUrl});
      this.dispatchSelectedPhoto(croppedImageUrl);
    }
  }

  dispatchSelectedPhoto = (photoUrl: string) => {
    if (this.props.photoEditor) {
      this.props.photoEditor.editor.mainPhoto = photoUrl; //all editors share mainPhoto property
      this.props.onCropCompleted(this.props.photoEditor);
    }
  };

  getCroppedImg(image: any, crop: any, fileName: any): Promise<string> {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx && ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise<string>((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);

        this.setState({croppedFile: blob});

      }, 'image/jpg');
    });
  }

  //on confirm selected area, upload it to cdn
  onPhotoConfirmed = async () => {
    if (this.state.croppedFile) {
      await this.setState({isUploading: true});

      const resizeOptions: ImageResizeOptions = {
        file: this.state.croppedFile, maxWidth: this.maxWidth, maxHeight: this.maxHeight,
        compressFormat: 'JPEG', quality: 90, rotation: 0, fileName: 'tour-img.jpg'
      };
      const resizedFile: File = await convertFile(resizeOptions);

      const s3ImageURL = await uploadImage(resizedFile);
      // const s3ImageURL = await uploadImage(this.state.croppedFile);

      if (this.props.photoEditor) {
        await this.props.setPhotoSrc('cdn');
        this.props.photoEditor.src = 'cdn';
      }

      this.dispatchSelectedPhoto(s3ImageURL);
      this.props.setUploadStatus(true);

      this.setState({isUploading: false});
    }
  };

  render() {
    const {crop, croppedImageUrl, src, isUploading} = this.state;
    const {isFileUploaded} = this.props;

    const uploadButtonVariant = isFileUploaded ? 'success' : 'info';

    // @ts-ignore
    const croppedImageElement = croppedImageUrl && <img alt="Crop" style={{maxWidth: '100%'}} src={croppedImageUrl}/>;

    return (
      <div className="App">
        <div>
          <input type="file" onChange={this.onSelectFile}/>
          {src && <Button variant={uploadButtonVariant} className='photo-confirm' size='sm' disabled={isUploading}
                          onClick={this.onPhotoConfirmed}>
            {(!isUploading && isFileUploaded) && <i className='feather icon-check-circle'/>}
            {(!isUploading && !isFileUploaded) && <i className='feather icon-save'/>}
            {isUploading && getLoadingSpan()}

            save photo
          </Button>}
        </div>
        {src && (
          // @ts-ignore
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            locked={true}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}

        <div className='cropped-image'>
          {/*{croppedImageElement}*/}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    photoEditor: state.tour.photoEditor,
    isFileUploaded: state.tour.isFileUploaded
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onCropCompleted: (itemEdited: PhotoEditor) => dispatch(setItemPhoto(itemEdited)),
    setUploadStatus: (isUploaded: boolean) => dispatch(setFileUpload(isUploaded)),
    setPhotoSrc: (src: string) => dispatch(setPhotoSrc(src))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadPhoto);