import React, {useState} from 'react';
import Facilities from "./Facilities";
import background from '../../assets/images/photos/activity-sample.jpg'
import {IActivity} from "../../types/storeTypes";
import {useDispatch, useSelector} from "react-redux";
import {setActiveEditor, setPhotoEditor} from "../../store/actions/tourlineActions";
import {PHOTO_EDITOR, PHOTO_TYPE_ACTIVITY} from "../../constants/actions";
import {AppState} from "../../store/reducers";
import EditorActiveIcon from "./EditorActiveIcon";
import {activityPlaceHolderText} from "./Editors/MainPhotoEditor/ActivityEditor";

type ActivityProps = {
  activity: IActivity
}

const Activity: React.FC<ActivityProps> = (props) => {
  // const [inFacilityView, setInFacilityView] = useState(false);
  const activity: IActivity = props.activity;
  const photoEditor = useSelector((state:AppState)=> state.tour.photoEditor);

  const inFacilityMode: boolean = useSelector((state:AppState)=> state.tour.inFacilityMode);
  let  activityInFacilityMode = false;
  if (activity && photoEditor) {
    const activityInUse = photoEditor.editor as IActivity;
    activityInFacilityMode = inFacilityMode && (activityInUse === activity);
  }

  const dispatch = useDispatch();

  const showEditor = async () =>{
    await dispatch(setPhotoEditor({editor: activity, photoEditorType: PHOTO_TYPE_ACTIVITY}));
    dispatch(setActiveEditor(PHOTO_EDITOR));
  };

  const mainPhoto = activity ? activity.mainPhoto : null;
  let styles: any = {style: {backgroundImage:`url(${background})`}};
  if(mainPhoto) {
    styles = {style: {backgroundImage: `url(${mainPhoto})`}}
  }

  const activityTitle = activity.title !== '' ? activity.title : activityPlaceHolderText;

  return (
    <div className='sub-item' >
      <div className='connector'>

      </div>
      <div className={`sub-item-content ${activityInFacilityMode ? 'facility-mode' : ''}`} {...styles} onClick={showEditor}
           // onClick={() => {!inFacilityView && setInFacilityView(true)}}
      >
        {!activityInFacilityMode &&
        <p className='topic-label'>
          {activityTitle}
        </p>}

        {activityInFacilityMode && <Facilities facilities={activity.facilities}/>}
      </div>

      {/*show editor activated activity (refresh icon right side, next to activity)*/}
      {photoEditor && photoEditor.editor as IActivity === activity && <EditorActiveIcon iconClass={'sub-item-refresh'}/>}

    </div>
  )
};

export default Activity;






// class IActivity extends Component<ActivityProps> {
//
//   state = {
//     inFacilityView: false
//   };
//
//   setInFacilityView = () => {
//     if(!this.state.inFacilityView){
//       this.setState({inFacilityView: true});
//     }
//   };
//
//   setInActivityView = () => {
//     this.setState({inFacilityView: false});
//   };
//
//   render() {
//     const inFacilityView = this.state.inFacilityView;
//
//     return (
//       <div className='sub-item'>
//         <div className='connector'>
//
//         </div>
//         <div className={`sub-item-content ${inFacilityView ? 'facility-mode' : ''}`}
//              onClick={() => this.setInFacilityView()}>
//
//           {!inFacilityView && this.props.title}
//
//           {inFacilityView && <Facility onClose={() => this.setInActivityView()}/>}
//         </div>
//       </div>
//     )
//   }
// }