import gql from 'graphql-tag';
import {TravelDocFragment} from "../fragments/TravelDocFragment";
import {FULL_BOOKING_FRAGMENT, MY_BOOKING_FRAGMENT, RECENT_BOOKING_FRAGMENT} from "../fragments/BookingFragment";

export const GET_LAST_BILLING_INFO = gql`
  query getLastBillingInfo {
      lastBillingInfo {
        _id
        fullName
        address
        city
        postcode
        country
        tel
        type
      }
  }
`;

export const VALIDATE_BOOKING = gql`
  query validateBooking ($orderID: Int) {
    validateBooking(orderId: $orderID) {
      _id
      orderID
      total
      paid: totalPaid
      voucher
      remarks
      pax {
        adults
        children
      }
      createdAt
    }
  }
`;

export const GET_PASSPORT_DOC = gql`
  ${TravelDocFragment}

  query getTravelDoc($bookingID: Int!) {
    travelDoc(bookingID: $bookingID) {
      ...TravelDocFields
    }
  }
`;

export const GET_MY_RECENT_BOOKINGS = gql`
  ${RECENT_BOOKING_FRAGMENT}
  
  query getUserBookings{
    getUserBookings{
     ...RecentBookingFields
    }
  }
`;

export const GET_MY_BOOKINGS = gql`
  ${MY_BOOKING_FRAGMENT}

  query getMyBookings($filter: String!) {
    getMyBookings(filter: $filter) {
      ...MyBookingFields
    }
  }
`;

export const GET_MY_BOOKINGS_COUNT = gql`
  query getMyBookingsCount{
    getMyBookingsCount{
      upcoming
      completed
      cancelled
    }
  }
`;

export const GET_BOOKING = gql`
  ${FULL_BOOKING_FRAGMENT}
  
  query getBooking($orderID: Int!) {
    booking(orderID: $orderID) {
      ...FullBookingFields
    }
  }
`;