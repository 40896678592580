import { gql } from "apollo-boost";

export const AgencyAddMutation = gql`
  mutation AddAgency(
    $name: String!
    $phone: [String!]
    $email: String!
    $address: String!
    $country: String!
    $description: String!
    $remarks: String
    $pin: String!
  ) {
    addAgency(
      name: $name
      phone: $phone
      email: $email
      address: $address
      country: $country
      description: $description
      remarks: $remarks
      pin: $pin
    ) {
      _id
      name
      phone
      email
      address
      country
      description
      remarks
#      pin
    }
  }
`;

export const AgencyEditMutation = gql`
  mutation EditAgency(
    $id: String!
    $name: String!
    $phone: [String!]
    $email: String!
    $address: String!
    $country: String!
    $description: String!
    $remarks: String
    $pin: String!
  ) {
    editAgency(
      _id: $id
      name: $name
      phone: $phone
      email: $email
      address: $address
      country: $country
      description: $description
      remarks: $remarks
      pin: $pin
    ) {
      _id
      name
      phone
      email
      address
      country
      description
      remarks
      #      pin
    }
  }
`;
