import {RefObject, useEffect, useState} from "react";

export const useOutsideClickNotifier = (ref: RefObject<HTMLDivElement>, isOutside: boolean) => {
  const [isClickOutside, setIsClickOutside] = useState(isOutside);

  useEffect(() => {
    setIsClickOutside(isOutside);

    const handleClickOutside = (event: MouseEvent) => {
      if(ref.current && event.target && !ref.current.contains(event.target as Node)) {
        setIsClickOutside(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [ref,isOutside]);

  return isClickOutside;
};