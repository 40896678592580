import React from 'react';
import {Col, Image, Row} from "react-bootstrap";
import flower from '../../../../assets/images/facilities/flower.png';
import forest from '../../../../assets/images/facilities/forest.png';
import ticket from '../../../../assets/images/facilities/ticket.png';
import ticketCenter from '../../../../assets/images/facilities/ticket-center.png';
import meal from '../../../../assets/images/facilities/meal.png';
import {useDispatch} from "react-redux";
import {setFacilityID} from "../../../../store/actions/tourlineActions";
import {getFacilityIconClass} from "../../../../utils/common";

type remarksProps = {
  icon: string | null,
  remark: string
}

type FacilityProps = {
  id: string
  icon: string
  title: string
  remarks?: remarksProps | null
}


const Facility: React.FC<FacilityProps> = (props) => {
  const dispatch = useDispatch();
  const {id, icon, title} = props;

  const handleFacilityClick = () => {
    dispatch(setFacilityID(id));
  };

  return (
    <Row className='facility editor-facility' onClick={handleFacilityClick}>
      <Col xs={12}>
        <label>
          <i className={`${getFacilityIconClass(icon)} facility-icon`}/>
        </label>
        <p className='title'>{title}
          {props.remarks && getRemarkInfo(props.remarks)}
        </p>
      </Col>
    </Row>
  );
};

const getIcon = (icon: String | null) => {
  switch (icon) {
    case 'flower':
      return flower;
    case 'forest':
      return forest;
    case 'ticket':
      return ticket;
    case 'ticket-center':
      return ticketCenter;
    case 'meal':
      return meal;
    default:
      return '';
  }
};

const getRemarkInfo = (remarks: remarksProps) => {
  const icon = remarks.icon ? <Image src={getIcon(remarks.icon)}/> : '';
  const remarksString = <span className='remarks'> ({remarks.remark} {icon} ) </span>;
  return remarksString;
};

export default Facility;