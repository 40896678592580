import React from 'react';
import {Col, FormControl, Row} from "react-bootstrap";
import {IDuration} from "../../types/clientStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setDuration} from "../../store/actions/clientActions";

const DurationPicker: React.FC = () => {
  const duration: IDuration = useSelector((state: AppState) => state.client.search.duration);
  const nightsValue = duration.nights + 'N';
  const daysValue =  duration.days + 'D';

  const dispatch = useDispatch();

  const dispatchDuration = (duration: IDuration) => {
    dispatch(setDuration({...duration, isDefault: false}));
  };

  const handleAdd = () => {
    const newDuration: IDuration = {...duration, nights: duration.nights + 1, days: duration.days + 1};
    dispatchDuration(newDuration);
  };
  const handleSub = () => {
    const nights = duration.nights > 1 ? duration.nights - 1 : duration.nights;
    const days = nights + 1;
    const newDuration: IDuration = {...duration, nights: nights, days: days};
    dispatchDuration(newDuration);
  };

  return (
    <div className='duration-pick'>
      <Row>
        <Col>
          <p className='pick-title'>Tour duration (Nights / Days)</p>
        </Col>
      </Row>

      <Row className='mt-3'>
        <Col md={{span: 4, offset: 2}} xs={6} className='text-center'>
          <i className='feather icon-chevron-up duration-arrow' onClick={handleAdd}/>
        </Col>
        <Col md={{span: 4, offset: 0}} xs={6} className='text-center'>
          <i className='feather icon-chevron-up duration-arrow' onClick={handleAdd}/>
        </Col>
      </Row>

      <Row className='mt-2 mb-2'>
        <Col md={{span: 4, offset: 2}} xs={6} >
          <FormControl
            size={'lg'}
            type='text'
            defaultValue={nightsValue}
            readOnly
          />
        </Col>
        <Col md={{span: 4, offset: 0}} xs={6} >
          <FormControl
            size={'lg'}
            type='text'
            defaultValue={daysValue}
            readOnly
          />
        </Col>
      </Row>

      <Row>
        <Col md={{span: 4, offset: 2}} xs={6} className='text-center'>
          <i className='feather icon-chevron-down duration-arrow' onClick={handleSub}/>
        </Col>
        <Col md={{span: 4, offset: 0}} xs={6} className='text-center'>
          <i className='feather icon-chevron-down duration-arrow' onClick={handleSub}/>
        </Col>
      </Row>
    </div>

  );
};

export default DurationPicker;