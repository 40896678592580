import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import ApolloClient from "apollo-client";
import {createHttpLink} from "apollo-link-http";
import {InMemoryCache} from "apollo-cache-inmemory";
// import { ApolloProvider } from "react-apollo";
import {ApolloProvider} from "@apollo/react-hooks";
// import App from './App';
import App from "./App/index";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store";
import {GRAPHQL} from "./routes/routes";
import {setContext} from "apollo-link-context";
import {onError} from "apollo-link-error";
import ClientApp from "./Client/ClientApp";
import SignIn2 from "./view/Authentication/SignIn/SignIn2";
import SignUp2 from "./view/Authentication/SignUp/SignUp2";
import Logout from "./view/Authentication/Logout";
import HostSignUp from "./view/Authentication/SignUp/HostSignUp";
import {STORAGE_USER} from "./constants/values";
import Auth from "./view/Authentication/Auth";
import AuthCheck from "./AuthCheck";
// import {CookiesProvider} from "react-cookie";

const store = configureStore();

const httpLink = createHttpLink({
  uri: GRAPHQL,
  credentials: "include"
});

const authLink = setContext((_, {headers}: any) => {
  // get the authentication token from local storage if it exists
  const storeUser = localStorage.getItem("user");
  if (storeUser) {
    const user = JSON.parse(storeUser);
    let token = null;
    if (user) {
      token = user.token;
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  } else {
    return {
      headers: {
        ...headers
      }
    };
  }
});

const onGraphQLError = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors) {
    console.log("GQ Err -");
    console.log(graphQLErrors);
    console.log(graphQLErrors[0].message);

    const errorMsg = graphQLErrors[0].message;
    if (errorMsg.indexOf("unauthorized") > -1) {
      localStorage.removeItem(STORAGE_USER);
      // :TODO redirect to unauth
      // history.push('/auth/signin-1');
    }
    // sendToLoggingService(graphQLErrors);
  }
  if (networkError) {
    // alert("Network Error - Try again later.");
    console.log(networkError);
    // logoutUser();
  }
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  // link: authLink.concat(onGraphQLError).concat(httpLink)
  // link: httpLink,
  link: authLink.concat(onGraphQLError).concat(httpLink),
  connectToDevTools: true
});

//scroll page back to top on route change
const ScrollToTop: React.FC = () => {
  window.scrollTo(0, 0);
  return null;
};

const TourApp: React.FC = () => {

  return (
    <ApolloProvider client={apolloClient}>
      {/*<CookiesProvider>*/}
      <Provider store={store}>
        {/*<BrowserRouter basename={config.basename}>*/}
        <BrowserRouter>
          {/*<Router history={history}>*/}
          {/*basename="/datta-able"*/}
          <Route component={ScrollToTop}/>
          <Route component={AuthCheck}/>
          <Switch>
            <Route path="/login" exact={true}>
              <SignIn2/>
            </Route>
            <Route path="/signup">
              <SignUp2/>
            </Route>
            <Route path="/auth/">
              <Auth/>
            </Route>
            <Route path="/become-a-host">
              <HostSignUp/>
            </Route>
            <Route path="/logout">
              <Logout/>
            </Route>
            <Route path="/admin">
              <App/>
            </Route>
            <Route path="/">
              <ClientApp/>
            </Route>
          </Switch>
          {/*</Router>*/}
        </BrowserRouter>
      </Provider>
      {/*</CookiesProvider>*/}
    </ApolloProvider>
  );
};

ReactDOM.render(<TourApp/>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
