import React from 'react';
import {Col, Row} from "react-bootstrap";

export function withPopup(ResultComponent: React.FC<any>, data: any){
  const Popup: React.FC = (props: any) => {
    return (
      <div className='popup'>
        <Row>
          <Col>
            <ResultComponent {...data}/>
          </Col>
        </Row>
      </div>
    );
  };

  return Popup;
}

// export default withPopup();