import React, {useEffect} from 'react';
import {Button, Card, Col, Row} from "react-bootstrap";
import EditorClose from "../EditorClose";
import {closeEditor} from "../../../../store/actions/tourlineActions";
import {useDispatch, useSelector} from "react-redux";
import {useMutation, useQuery} from "@apollo/react-hooks";
import {GET_LATEST_ADMIN_REVIEW} from "../../../../graphql/queries/TourApproval";
import {AppState} from "../../../../store/reducers";
import {ReviewStatus} from "../../../../constants/values";
import {getDateFormatted, getDateTime} from "../../../../utils/CommonFunctions";
import {SET_APPROVE_VIEWED} from "../../../../graphql/mutations/TourApproval";
import {isSuperUser} from "../../../../utils/authCheck/authCheck";

const ViewAdminReview: React.FC = () => {
  const tourID: string | null = useSelector((state: AppState) => state.tour.id);
  const dispatch = useDispatch();

  const {data, loading, error} = useQuery(GET_LATEST_ADMIN_REVIEW, {
    variables: {
      tourId: tourID
    },
    skip: !tourID
  });

  const [setReviewViewed] = useMutation(SET_APPROVE_VIEWED);

  useEffect(() => {
    if (isSuperUser()) {
      return;
    }

    if (data && data.getLatestApproval) {
      setReviewViewed({
        variables: {
          tourId: tourID
        },
      })
    }
  }, [data]);

  if (loading) {
    return <span className="spinner-border spinner-border-sm mr-1 position-absolute" role="status"/>
  }

  if (error) {
    return <p className='text-warning'>problem loading admin review.</p>
  }



  return (
    <Card className='editors review-editor'>
      <Card.Body className='wide-card-body'>

        <EditorClose/>

        <Row>
          <Col xs={11} className='pl-0 pr-0'>
            <p className='activity-title'>
              Admin approval.
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <p className='mt-3 mb-1 review-time'>Review status:
              {data.getLatestApproval.status === ReviewStatus.APPROVED &&
              <span className='font-weight-bold text-success'> Approved.</span>}

              {data.getLatestApproval.status === ReviewStatus.REJECTED &&
              <span className='font-weight-bold text-danger'> Rejected.</span>}
            </p>
          </Col>
          <Col xs={12}>
            <p className='mt-1 mb-1 review-time'>Reviewed at:
              {/*<span className='font-italic'> September 07, 2020 1517h</span>*/}
              <span className='font-italic'> {getDateFormatted(getDateTime(data.getLatestApproval.createdAt),
                "MMMM DD, YYYY HHmm[h]" )}</span>
            </p>
          </Col>
        </Row>

        <Row>
          {data.getLatestApproval.status === ReviewStatus.REJECTED &&
          <Col xs={12}>
            <p className='mt-3 mb-1 reason-title'>Reasons for Reject: </p>
            <p className='mb-1 review-description'>
              {data.getLatestApproval.rejectReasons}
            </p>
          </Col>}

          <Col xs={12}>
            <p className='mt-3 mb-1 reason-title'>Comments </p>
            <p className='mb-1 review-description'>
              {data.getLatestApproval.comments ? data.getLatestApproval.comments: ''}
            </p>
          </Col>

          <Col xs={12} className='text-right mt-3'>
            <Button variant='primary' size='sm' onClick={() => dispatch(closeEditor())}>Close</Button>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
};

export default ViewAdminReview;