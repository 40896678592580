import gql from "graphql-tag";

export const RECENT_TOURS = gql`
  query recent {
    recentTours {
      _id
      title
      destinations{
        name
      }
      editor {
        updatedAt
        bookmark
      }
    }
  }

`;