import React, {useEffect, useState} from 'react';
import {Alert, Badge, Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {setActiveEditor, setReviewStatus} from "../../../../store/actions/tourlineActions";
import {REVIEW_VIEWER} from "../../../../constants/actions";
import {useLazyQuery, useMutation, useQuery} from "@apollo/react-hooks";
import {GET_TOUR_APPROVAL_STATUS} from "../../../../graphql/queries/TourQueries";
import {AppState} from "../../../../store/reducers";
import {ReviewStatus} from "../../../../constants/values";
import {GET_LATEST_ADMIN_REVIEW} from "../../../../graphql/queries/TourApproval";
import {SET_APPROVE_VIEWED} from "../../../../graphql/mutations/TourApproval";

const ReviewNotifications: React.FC = () => {
  const tourID: String | null = useSelector((state: AppState) => state.tour.id);
  const [isAlertDismissed, setIsAlertDismissed] = useState(false);
  const [alertStatus, setAlertStatus] = useState<ReviewStatus | null>(null);

  const dispatch = useDispatch();

  const [getApprovalStatus, {data}] = useLazyQuery(GET_TOUR_APPROVAL_STATUS);

  const [setApproveReviewViewed] = useMutation(SET_APPROVE_VIEWED);

  useEffect(() => {
    if (!tourID) {
      return;
    }

    getApprovalStatus({
      variables: {
        id: tourID
      }
    });
  }, [tourID]);

  useEffect(() => {
    if (data && data.getTourApprovalStatus) {
      setAlertStatus(data.getTourApprovalStatus.reviewStatus);
      dispatch(setReviewStatus(data.getTourApprovalStatus.reviewStatus));
      return;
    }

    dispatch(setReviewStatus(null));
  }, [data]);


  const handleShowCommentsClick = () => {
    dispatch(setActiveEditor(REVIEW_VIEWER));
  };

  const handleApproveAlertClose = () => {
    setIsAlertDismissed(true);
    setApproveReviewViewed({
      variables: {
        tourId: tourID
      }
    })
  };

  const getPendingApprovalAlert = () => {
    if (isAlertDismissed) {
      return;
    }

    return(
      <Alert variant='primary' dismissible={true} onClose={() => setIsAlertDismissed(true)}>
        Pending approval.
      </Alert>
    )
  };

  const getApprovedAlert = () => {
    if (isAlertDismissed) {
      return;
    }

    return(
      <Alert variant='success' dismissible={true} onClose={handleApproveAlertClose}>
        <label className='font-weight-bold'>Approved.</label>
        <Badge variant="info" className='ml-3' onClick={handleShowCommentsClick}>Show comments</Badge>
      </Alert>
    )
  };

  const getRejectedAlert = () => {
    if (isAlertDismissed) {
      return;
    }

    return(
      <Alert variant='danger' dismissible={true} onClose={() => setIsAlertDismissed(true)}>
        <label className='font-weight-bold'>Rejected.</label>
        <Badge variant="info" className='ml-3' onClick={handleShowCommentsClick}>Show reasons</Badge>
      </Alert>
    )
  };

  return (
    <Col xs={12} className='tour-review-notifications'>
      {alertStatus === ReviewStatus.REQUESTED && getPendingApprovalAlert()}
      {alertStatus === ReviewStatus.APPROVED && getApprovedAlert()}
      {alertStatus === ReviewStatus.REJECTED && getRejectedAlert()}
    </Col>
  );
};

export default ReviewNotifications;