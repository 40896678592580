import React, {FormEvent, useEffect, useState} from 'react';
import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import {useMutation} from "@apollo/react-hooks";
import {MAKE_TEST_PAYMENT} from "../../../graphql/mutations/BookingMutations";
import {getLoadingSpan} from "../../../utils/loading/loading";

const TestPayment: React.FC = () => {
  const [testAdminPayment, {data, loading, error}] = useMutation(MAKE_TEST_PAYMENT);

  const [orderID, setOrderID] = useState<number | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    setIsSuccess(true);
  }, [data]);

  const handleOnOrderIDChange = (id: string) => {
    setOrderID(parseInt(id));
  }

  const handleOnAmountChange = (paymentAmount: string) => {
    setAmount(parseFloat(paymentAmount.replace(/,/g, '')));
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsSuccess(false);

    if (!orderID || !amount) {
      return;
    }

    testAdminPayment({
      variables: {
        orderID: orderID,
        amount: amount
      }
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <h4>Test Payment by Admin</h4>
      </Col>
      <Col xs={12} md={{span: 4, offset: 3}}>
        <Form onSubmit={handleSubmit}>
          <Form.Label>Order #:</Form.Label>
          <Form.Control type="text" size='sm'
                        placeholder="Order #"
                        value={orderID ? orderID + '' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnOrderIDChange(e.target.value)}
          />

          <Form.Label className='mt-3'>Amount:</Form.Label>
          <Form.Control type="text" size='sm'
                        placeholder="payment"
                        value={amount ? amount + '' : ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnAmountChange(e.target.value)}
          />

          {error && <Alert variant='danger'>Test payment failed!</Alert>}
          {isSuccess && <Alert variant='success'><i className='feather icon-check'/>Test payment Added</Alert>}

          <Button variant="primary" size='sm' className='mt-3 float-right' type="submit" disabled={loading}>
            {loading && getLoadingSpan()}
            Submit
          </Button>
        </Form>
      </Col>
    </Row>
  )
};

export default TestPayment;