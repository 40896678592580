export const SET_QUERY = 'SET_QUERY';
export const SET_DESTINATION = 'SET_DESTINATION';
export const SET_DATE = 'SET_DATE';
export const SET_DURATION = 'SET_DURATION';
export const SET_PAX = 'SET_PAX';
export const SET_DURATION_VISIBILITY = 'SET_DURATION_VISIBILITY';
export const SET_PAX_VISIBILITY = 'SET_PAX_VISIBILITY';
export const SET_FLIGHT_DETAILS_VISIBILITY = 'SET_FLIGHT_DETAILS_VISIBILITY';
export const SET_TSM = 'SET_TSM';
export const SET_MONTHS = 'SET_MONTHS';
export const SET_REDIRECT = 'SET_REDIRECT';
export const SET_SEARCH_UPDATED = 'SET_SEARCH_UPDATED';
export const SET_MY_BOOKINGS_RECENT_VISIBILITY = 'SET_MY_BOOKINGS_RECENT_VISIBILITY';