import USER_ROLES from "../../constants/roles";

export const isSuperUser = () => {
  const userStore = localStorage.getItem("user");
  const user = JSON.parse(userStore || "{}");
  if (user && user.auth) {
    return  user.role === USER_ROLES.SUPER_ADMIN;
  }
  return false;
};

export const isHostAdmin = () => {
  const userStore = localStorage.getItem("user");
  const user = JSON.parse(userStore || "{}");
  if (user && user.auth) {
    return  user.role === USER_ROLES.AGENCY_ADMIN;
  }
  return false;
};

export const isHostAdminOrModerator = () => {
  const userStore = localStorage.getItem("user");
  const user = JSON.parse(userStore || "{}");
  if (user && user.auth) {
    return  user.role === USER_ROLES.AGENCY_ADMIN || user.role === USER_ROLES.AGENCY_USER;
  }
  return false;
};

