import React, {useEffect} from 'react';
import {Col, Row} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {GET_MY_RECENT_BOOKINGS} from "../../../graphql/queries/BookingQuery";
import RecentBooking from "./RecentBooking";
import {getLoadingSpinner} from "../../../utils/loading/loading";
import {IMyBooking} from "../../../types/bookingProps";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setMyRecentBookingsVisibility} from "../../../store/actions/clientActions";

const MyRecentBookings: React.FC = () => {
  const {data, loading, error} = useQuery(GET_MY_RECENT_BOOKINGS);
  const dispatch = useDispatch();

  const closeMyRecentBookingsMenu = () => {
    dispatch(setMyRecentBookingsVisibility(false));
  };

  useEffect(() => {
    if (!data) {
      return;
    }
  }, [data]);

  if (loading) {
    return getLoadingSpinner();
  }

  if (error) {
    return <p className='mt-2 mb-2 pr-lg-2 my-recent-bookings-label'>
      Problem loading Recent Bookings.
    </p>

  }

  const renderRecentBookingItems = () => {
    if (!data) {
      return;
    }

    return data.getUserBookings.map((recentBooking: IMyBooking, index: number) =>
      <RecentBooking booking={recentBooking} key={index}/>)
  };

  return (
    <React.Fragment>
      <p className='text-right mt-2 mb-2 pr-lg-2 pr-md-5 my-recent-bookings-label'>My Recent Bookings</p>
      <Row className='pr-lg-4 justify-content-md-center'>
        {renderRecentBookingItems()}
      </Row>
      <Row>
        <Col xs={12} className='pr-lg-4 pr-md-5 pr-2 text-right view-all-my-bookings mb-3'>
          <Link to='/my-bookings' onClick={closeMyRecentBookingsMenu}>View all</Link>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default MyRecentBookings;