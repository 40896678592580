import React from 'react';
import refresh from '../../assets/images/timeline/refresh.jpg';
import refreshBlue from '../../assets/images/timeline/refresh_blue.png';
import refreshDarkBlue from '../../assets/images/timeline/refresh-dark-blue.png';
import {Image} from "react-bootstrap";

type EditorActiveIconProps = {
  iconClass: string
}
const EditorActiveIcon: React.FC<EditorActiveIconProps> = (props) => {
  return (
      <Image src={refreshBlue} className={`editor-active-item ${props.iconClass}`}/>
  )
};

export default EditorActiveIcon;