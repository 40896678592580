import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {IDestination} from "../../types/clientStoreTypes";
import {setDestination} from "../../store/actions/clientActions";
import {useQuery} from "@apollo/react-hooks";
import {SEARCH_SUGGESTIONS} from "../../graphql/queries/SearchQuery";


const DESTINATION_TYPES = {
  DESTINATION: 'country',
  CITY: 'cz',
};

type DestinationResult = {
  id: string,
  name: string,
  info: string,
  type: string
}

export type DestinationResultProps = {
  query: string
}

const DestinationResult: React.FC<DestinationResultProps> =
  (props) => {
  const {query} = props;
  const dispatch = useDispatch();
  const [hoverItemIndex, setHoverItemIndex] = useState<number>(0);

  const [destinations, setDestinations] = useState<IDestination[]>([]);

  const {loading, data, error} = useQuery(SEARCH_SUGGESTIONS, {
    variables: {q: query}
  });

  useEffect(() => {
    if (data) {
      setDestinations(data.find);
    }
  }, [data]);

  // const destinations: IDestination[] = [
  //   {"id": "11", "name": "Austrlia", info: 'Oceania, east.', type: 'dest'},
  //   {"id": "77", "name": "Sigiriya", info: 'north central, Sri Lanka, south-asia.', type: 'city'},
  //   {"id": "12", "name": "Azerbaijan", info: 'Europe.', type: 'dest'},
  //   {"id": "13", "name": "Bahamas", info: 'Bahamas islands, north-america', type: 'dest'}
  // ];

  const handleOnClickResult = (index: number) => {
    const destination: IDestination = destinations[index];
    // const {__typename, ...destinationData} = destination
    dispatch(setDestination(destination));
    // dispatch(setDestination({...destination}));
  };

  const handleOnMouseOver = (index: number) => {
    setHoverItemIndex(index);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
      event.preventDefault();
      setHoverItemIndex(hoverItemIndex !== 0 ? hoverItemIndex - 1 : destinations.length - 1);
    } else if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
      event.preventDefault();
      setHoverItemIndex(hoverItemIndex !== destinations.length - 1 ? hoverItemIndex + 1 : 0);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleOnClickResult(hoverItemIndex);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keypress', handleKeyPress);
    };
  });

  const renderResult = () => {
    return (
      destinations.map((destination, idx) => {
        const icon = destination.type === DESTINATION_TYPES.DESTINATION ? 'map-pin' : 'flag';

        return (
          <li key={idx}
              className={`result-item ${hoverItemIndex === idx ? 'active' : ''}`}
              onClick={() => handleOnClickResult(idx)}
              onMouseOver={() => handleOnMouseOver(idx)}>

            <i className={`feather result-icon icon-${icon}`}/>
            <label className='result-label'>{destination.name}</label>
            <label className='small-label'>{destination.info}</label>
          </li>
        );
      })
    );
  };

  const renderLoading = () => {
    if (loading) {
      return (
        [...Array(4)].map((e, index) => {
          return (
            <li className='result-item' key={index}>
              <label className='result-label'>
                <div className='loading'/>
              </label>
            </li>
          );
        })
      );

    }
  };

  return (
    <React.Fragment>
      <ul className='search-destinations result-list'>
        {loading && renderLoading()}
        {data && renderResult()}
      </ul>
    </React.Fragment>
  );
};

export default DestinationResult;