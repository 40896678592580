import React, { useState, useEffect } from "react";

const cdnPath = 'https://s3.amazonaws.com/cdn2.toursharbor/';

const bgImages = [
  'adventure.webp',
  'italy.webp',
  'desert.webp',
  'person.webp',
  'mountain.webp',
  'srilanka.webp',
  'thailand.webp',
  'landscape.webp',
  'beach.jpg',
  'kremlin.jpg',
];

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const RandomBackground: React.FC<any> = (props: any) => {
  const [bgImg, setBgImg] = useState(
    cdnPath + bgImages[getRandomInt(0, bgImages.length - 1)]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setBgImg(cdnPath + bgImages[getRandomInt(0, bgImages.length - 1)]);
    }, 1000 * 20);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      {...props}
      style={{
        backgroundColor: "#000000",
        backgroundImage: `url("${bgImg}")`,
        backgroundSize: "cover",
        transition: "background 5s ease"
      }}
    >
      <div
        style={{
          backgroundColor: "#000000",
          opacity: "0.3",
          position: "absolute",
          width: "100%",
          height: "100%"
        }}
     />
      {props.children}
    </div>
  );
};

export default RandomBackground;
