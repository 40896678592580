import React, {FormEvent, useEffect, useState} from "react";
import {NavLink, Redirect} from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import axios from "axios";
import {API_ROUTES} from "../../../routes/routes";
import {Button, Form} from "react-bootstrap";
import USER_ROLES from "../../../constants/roles";
import RandomBackground from "../../../utils/RandomBackground";
import {getLoadingSpan} from "../../../utils/loading/loading";
import {STORAGE_USER} from "../../../constants/values";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setRedirect} from "../../../Client/store/actions/clientActions";
import SecurePassword from "../res/SecurePassword";
import {useParams} from 'react-router-dom';
import {getLoadingSpinner} from "../../../Client/utils/loading/loading";

interface ModeratorSignupState {
  email: string | null;
  password: string | null;
  confirmPassword: string | null;
  isResetError: boolean;
  message: string | null;
  resetSuccess: boolean;
  processing: boolean;
  redirect: boolean,
  loggingIn: boolean,
  redirectTo: string,
}

const PRR_VALIDATING = 'validating';
const PRR_VALIDATED = 'validated';
const PRR_INVALID = 'invalid';

const ModeratorSignup: React.FC = () => {
  const {mir} = useParams(); //Password Reset Request

  const [PRRState, setPRRState] = useState(PRR_VALIDATING);
  const [loginState, setLoginState] = useState<ModeratorSignupState>({
    email: null,
    password: null,
    confirmPassword: null,
    isResetError: false,
    message: null,
    resetSuccess: false,
    processing: false,
    redirect: false,
    loggingIn: false,
    redirectTo: "/"
  });

  const redirect: string | null = useSelector((state: AppState) => state.client.redirectTo);

  const checkPRR = () => {
    axios
      .post(API_ROUTES.VALIDATE_MIR, {mir: mir})
      .then((res: any) => {
        setLoginState({...loginState, processing: false});

        if (res.data.email) {
          setLoginState({...loginState, email: res.data.email, isResetError: false});
          setPRRState(PRR_VALIDATED);
        } else {
          setPRRState(PRR_INVALID);
        }
      })
      .catch((err: any) => {
        setPRRState(PRR_INVALID);
      });
  };

  useEffect(() => {
    checkPRR();
  }, [mir]);


  const handlePasswordChange = (password: string | null) => {
    setLoginState({...loginState, password: password});
  };

  const handleLogin = (data: any) => {
    const user = {
      auth: data.auth,
      token: data.token,
      role: data.role,
      name: data.name
      // company: data.company
    };

    localStorage.setItem(STORAGE_USER, JSON.stringify(user));

    let redirectTo = "/";

    switch (data.role) {
      case USER_ROLES.SUPER_ADMIN:
      case USER_ROLES.AGENCY_ADMIN:
      case USER_ROLES.AGENCY_USER:
        redirectTo = "/admin";
        break;
    }

    if (redirect) {
      redirectTo = redirect;
    }

    setLoginState({...loginState, redirect: true, redirectTo});

    // this.props.history.push("/");
  };


  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setLoginState({
      ...loginState,
      message: "",
      isResetError: false,
      processing: true
    });

    const auth = {
      mir: mir,
      email: loginState.email,
      password: loginState.password,
      login_type: "join_moderator"
    };

    axios
      .post(API_ROUTES.JOIN_AS_MODERATOR, auth)
      .then((res: any) => {
        setLoginState({...loginState, processing: false});

        if (res.data.auth) {
          setLoginState({...loginState, resetSuccess: true, isResetError: false});

          setTimeout(() => {
            setLoginState({...loginState, resetSuccess: true, loggingIn: true});
          }, 1500);

          setTimeout(() => {
            handleLogin(res.data);
          }, 3500);
        }
      })
      .catch((err: any) => {
        setLoginState({...loginState, processing: false});

        const message = err.response ? err.response.data : "Network Error";
        localStorage.removeItem("user");

        if (!err.response) {
          setLoginState({
            ...loginState,
            message: message,
            isResetError: true
          });
        }

        if (err.response && err.response.status === 403) {
          setLoginState({
            ...loginState,
            message: message,
            isResetError: true
          });
        }

        if (err.response && err.response.status === 400) {
          setLoginState({
            ...loginState,
            message: "Problem occurred. Try again!",
            isResetError: true
          });
        }
      });
  };

  const renderPasswordChangedMessage = () => {
    return (
      <React.Fragment>
        <p className="mt-2 mb-3 text-success font-weight-bold">
          Joined successfully.
          <i className='feather icon-check'/>
        </p>
        {loginState.loggingIn &&
        <p className="mt-2 mb-3 text-success font-weight-light">
          {getLoadingSpan()} logging in
        </p>}
        <p className="mb-0 text-muted">
          Log in to your account{" "}
          <NavLink to="/login">Login</NavLink>
        </p>
      </React.Fragment>
    );
  };

  const   getAuthBody = () => {
    switch (PRRState) {
      case PRR_VALIDATING:
        return (
          <p className="mt-2 mb-3 text-info font-weight-bold">
            {getLoadingSpan()} checking request.
          </p>
        );
      case PRR_INVALID:
        return (
          <React.Fragment>
            <div className="error-container">
              <p className="login-error">
                Link expired.
              </p>
            </div>
            <p className="mb-0 text-muted">
              Contact your admin to get a new invitation.
            </p>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <h3 className="mb-4">Create a Password to Join</h3>
            <Form onSubmit={handleSubmit}>
              <div className="input-group mb-4">
                <input
                  type="email"
                  className="form-control"
                  placeholder="email"
                  required
                  defaultValue={loginState.email ? loginState.email : ''}
                  readOnly
                />
              </div>
              <SecurePassword onSecurePasswordCreated={handlePasswordChange}/>

              <div className="error-container">
                {loginState.isResetError && (
                  <p className="login-error">{loginState.message}</p>
                )}
              </div>
              <Button
                type="submit"
                className="btn btn-primary shadow-2 mb-4"
                disabled={!loginState.password}
              >
                {loginState.processing && getLoadingSpan()}
                Sign up
              </Button>
            </Form>
          </React.Fragment>
        );
    }
  };

  const renderRedirect = () => {
    if (loginState.redirect) {
      return <Redirect to={loginState.redirectTo} />;
    }
  };

  return (
    <Aux>
      {renderRedirect()}

      <div className="auth-wrapper aut-bg-img-side container-fluid th-no-padding align-items-stretch">
        <div className="row align-items-center w-100 align-items-stretch bg-white">
          <RandomBackground className="d-none d-lg-flex col-lg-8 aut-bg-img align-items-center d-flex
          justify-content-center">
            <div className="col-md-8">
              <h1 className="text-white mb-5">Travel made Affordable.</h1>
              <p className="text-white">
                Find most exiting vacation plans & relaxing tours at lowest
                rate. Best tour deals from Tours Harbor.
              </p>
            </div>
          </RandomBackground>
          <div className="col-lg-4 align-items-stret h-100 align-items-lg-center d-flex justify-content-center">
            <div className=" auth-content text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon text-warning"/>
              </div>

              {!loginState.resetSuccess && getAuthBody()}

              {loginState.resetSuccess && renderPasswordChangedMessage()}

            </div>
          </div>
        </div>
      </div>
    </Aux>
  );

};


export default ModeratorSignup;
