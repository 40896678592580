import {STORAGE_USER} from "../../constants/values";
import {Redirect} from "react-router-dom";
import React from "react";

const FEATHER_PREFIX = "icon";

export const getFacilityIconClass = (icon: string) => {
  if (icon.substring(0,4) !== FEATHER_PREFIX) { //tour icon
    return `ic ic-${icon}`
  }
  return `feather ${icon}`
};

export const isLoggedIn = async () => {
  const userStore = localStorage.getItem(STORAGE_USER);
  const user = JSON.parse(userStore || "{}");
  return user !== null && user.auth;
};

export const getPhotoKey = (url: string | null) => {
  if (url) {
    return url.split('?')[0];
  }
  return null;
};

/**
 * NOT a safe method to get a key, if for Eg. string values are equal, first key match is returned.
 * @param object
 * @param value
 */
export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find(key => object[key] === value);
};

/**
 *
 * @param obj
 */
export const copyObject = (obj: any) => {
  return {...obj}
};

/**
 * show alert message to user
 * @param message
 */
export const showAlert = (message: string) => {
  alert(message);
};

/**
 * remove graphql __typename and return obj without __typename
 * @param obj
 */
export const removeTypeName = (obj: any) => {
    const {__typename, ...objData} = obj;
    return objData;
};
