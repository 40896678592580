import React, {PropsWithChildren} from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";
import FacilityItemEditor from "../FacilityItemEditor";
import {FacilityData} from "../AllFacilities";
import {Col, Row} from "react-bootstrap";
import {IFacility} from "../../../../../types/storeTypes";

type UpdateFacilityProps = {
  facilities: FacilityData[]
}


const UpdateFacility: React.FC<UpdateFacilityProps> = (props: PropsWithChildren<UpdateFacilityProps>) => {
  const {facilities} = props;

  /**
   * This feature can be enabled for crete as well by getting facilityEditorID from store altering first if condition
   * below in #25
   */

  //only if facility item on tourline clicked.
  const facilityInUpdate: IFacility | null = useSelector((state: AppState) => state.tour.facilityInEditor);

  if (!facilityInUpdate) {
    return null;
  }

  const facility: FacilityData | undefined = facilities.find((facility: FacilityData) =>
    facility.id === facilityInUpdate.facilityID);

  if (!facility) {
    return null;
  }

  return (
    <Row>
      <Col xs={12} md={6} className='facility-group'>
        <h6>Quick Editor</h6>
        <FacilityItemEditor facility={facility}/>
      </Col>
      <Col xs={12}>
        <hr/>
      </Col>
    </Row>
  )

};

export default UpdateFacility;