import React from 'react';

type WarningMessageProps = {
  message: string
}

const WarningMessage: React.FC<WarningMessageProps> = (props) => {
  const {message} = props;

  return (
    <p className='common-warning'>{message}</p>
  );
};

export default WarningMessage;