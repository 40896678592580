import React from 'react';
import { Route, Switch} from "react-router-dom";
import Forgot from "./Reset/Forgot";
import Reset from "./Reset/Reset";
import ModeratorSignup from "./SignUp/ModeratorSignup";

const Auth: React.FC = () => {
  return (
    <Switch>
      <Route path='/auth/forgot'>
        <Forgot/>
      </Route>
      <Route path='/auth/reset/:prr'>
        <Reset/>
      </Route>
      <Route path='/auth/moderator-signup/:mir'>
        <ModeratorSignup/>
      </Route>
    </Switch>
  );
};

export default Auth;