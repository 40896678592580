import React, { Component, Props, Suspense } from "react";
import { Switch, Route, RouteProps, Redirect } from "react-router-dom";
import Loadable from "react-loadable";

import "../../node_modules/font-awesome/scss/font-awesome.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../routes/route";
import USER_ROLES from "../constants/roles";
import {STORAGE_USER} from "../constants/values";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader
});

// import AdminLayout from './layout/AdminLayout'

class App extends Component<RouteProps> {
  render() {
    const menu = routes.map((route: any, index) => {
      return route.component ? (
        // @ts-ignore
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          name={route.name}
          render={props => <route.component {...props} />}
        />
      ) : null;
    });

    const authCheck = () => {
      const userStore = localStorage.getItem(STORAGE_USER);
      const user = JSON.parse(userStore || "{}");
      if (user && user.auth) {
        // console.log('user found');
        switch (user.role) {
          case USER_ROLES.SUPER_ADMIN:
          case USER_ROLES.AGENCY_ADMIN:
          case USER_ROLES.AGENCY_USER:
            return null;
        }
      }
      return <Redirect to="/login" />;
    };

    return (
      <Aux>
        {authCheck()}
        {/*:TODO fix test failure: Invariant failed: You should not use <withRouter(ScrollToTop) /> outside a <Router>*/}
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {menu}
              <Route path="/admin" component={AdminLayout} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Aux>
    );
  }
}

export default App;
