import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row, Form} from "react-bootstrap";
import {useQuery, useMutation} from "@apollo/react-hooks";

// @ts-ignore
import {ValidationForm} from "react-bootstrap4-form-validation";
// @ts-ignore
import {TextInput} from "react-bootstrap4-form-validation";
// @ts-ignore
import {SelectGroup} from "react-bootstrap4-form-validation";

// @ts-ignore
import validator from "validator";

import {AgencyType} from "../../types/AgencyType";
import {countries} from "../../constants/countries";
import {tourWithApolloClient} from "../../graphql/TourWithApolloClient";
import {
  AgencyAddMutation,
  AgencyEditMutation
} from "../../graphql/mutations/AgencyMutations";
import {generatePIN} from "../../utils/CommonFunctions";
import {AgencyById} from "../../graphql/queries/AgencyQueries";
import ToursSpinner from "../../components/ui/ToursSpinner";
import {isSuperUser} from "../../utils/authCheck/authCheck";
import {Redirect} from "react-router-dom";

interface StateType extends AgencyType {
  pin?: string; // TODO: removing "?" will give a typescript error
}

interface PropType {
  // client: any;
  [anyProp: string]: any;
}

const initialState: StateType = {
  name: "",
  phone: "",
  email: "",
  address: "",
  country: "",
  // fax: '',
  description: "",
  remarks: "",
  pin: ""
};

const AgencyEdit: React.FC = (props: PropType) => {
  // constructor(props: PropType) {
  //   super(props);
  //   this.state = initialState;
  // }

  // handleChange = (e: any) => {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  // };

  // handleSubmit = (e: any, formData: any, inputs: any) => {
  //   console.log("form data valid");
  //   // console.log("typeof formData", typeof formData); // todo: add type
  //   // console.log("typeof inputs", typeof inputs); // todo: add type
  //
  //   e.preventDefault();
  //   //alert(JSON.stringify(formData, null, 2));
  //   // this.setState({ showModal: true });
  //
  //   console.log(this.props.client);
  //
  //   this.props.client
  //     .mutate({
  //       mutation: AgencyAddMutation,
  //       variables: {
  //         name: this.state.name,
  //         phone: [this.state.phone],
  //         email: this.state.email,
  //         address: this.state.address,
  //         country: this.state.country,
  //         description: this.state.description,
  //         remarks: this.state.remarks,
  //         pin: this.state.pin
  //       }
  //     })
  //     .then((results: any) => {
  //       console.log(results);
  //       if (results.data.addAgency) {
  //         // this.clearForm();
  //       } else {
  //         // TODO: show error
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log("Error: ", error);
  //     });
  // };
  //
  // handleErrorSubmit = (e: any, formData: any, errorInputs: any) => {
  //   //console.log(errorInputs);
  //   console.log("form data invalid");
  //
  //   // console.log("typeof e", typeof e); // todo: add type
  //   // console.log("typeof formData", typeof formData); // todo: add type
  //   // console.log("typeof inputs", typeof errorInputs); // todo: add type
  // };

  const [agency, setAgency] = useState(initialState);
  const [isAuthorized, setIsAuthorized] = useState(true);

  const {loading, error, data} = useQuery(AgencyById, {
    variables: {
      id: props.match.params.id
    }
  });

  useEffect(() => {
    if (data) {
      setAgency(data.agency);
    }
  }, [data]);

  useEffect(() => {
    setIsAuthorized(isSuperUser());
  }, []);

  const [editAgency] = useMutation(AgencyEditMutation);

  const handleChange = (e: any) => {
    setAgency({...agency, [e.target.name]: e.target.value});
  };

  const handleSubmit = (e: any, formData: any, inputs: any) => {
    e.preventDefault();

    editAgency({
      variables: {
        id: props.match.params.id,
        name: agency.name,
        phone: agency.phone,
        email: agency.email,
        address: agency.address,
        country: agency.country,
        description: agency.description,
        remarks: agency.remarks,
        pin: agency.pin
      }
    });
  };

  const handleErrorSubmit = (e: any, formData: any, errorInputs: any) => {
    console.log(errorInputs);
    console.log("form data invalid");
  };

  const getContent = () => {
    if (!isAuthorized) {
      return <Redirect to='/admin' />
    }

    if (loading) {
      return <ToursSpinner/>;
    }

    if (error) {
      // return <h6>Error! {JSON.stringify(error)}</h6>;
      return <h6>Error!</h6>;
    }



    return (
      <ValidationForm onSubmit={handleSubmit} onErrorSubmit={handleErrorSubmit}>
        <Row>
          <Col md={6}>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="name">Agency Name</Form.Label>
                <TextInput
                  name="name"
                  id="name"
                  placeholder="Agency Name"
                  required
                  value={agency.name}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="phone">Phone</Form.Label>
                <TextInput
                  name="phone"
                  id="phone"
                  placeholder="Phone"
                  required
                  value={agency.phone}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="email">Email Address</Form.Label>
                <TextInput
                  name="email"
                  id="email"
                  placeholder="Email"
                  validator={validator.isEmail}
                  errorMessage={{validator: "Please enter a valid email"}}
                  required
                  value={agency.email}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="address">Address</Form.Label>
                <TextInput
                  name="address"
                  id="address"
                  placeholder="Address"
                  required
                  multiline
                  rows="3"
                  value={agency.address}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label htmlFor="country">Country</Form.Label>
                <SelectGroup
                  name="country"
                  id="country"
                  value={agency.country}
                  required
                  errorMessage="Please select a country."
                  onChange={handleChange}
                >
                  <option value="">Please select a country...</option>
                  {countries.map((country: any, i: number) => {
                    return (
                      <option value={country.key} key={i}>
                        {country.name}
                      </option>
                    );
                  })}
                </SelectGroup>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="description">Description</Form.Label>
                <TextInput
                  name="description"
                  id="description"
                  placeholder="Description"
                  required
                  multiline
                  rows="3"
                  value={agency.description}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="remarks">Remarks</Form.Label>
                <TextInput
                  name="remarks"
                  id="remarks"
                  placeholder="Remarks"
                  multiline
                  rows="3"
                  value={agency.remarks}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
            </Form.Row>
          </Col>
          <Col md={1}></Col>
          <Col md={1}>
            <div className="vl"></div>
          </Col>
          <Col md={4}>
            <Form.Row>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="username">Username</Form.Label>
                <TextInput
                  name="username"
                  id="username"
                  type="email"
                  placeholder="Username"
                  readOnly
                  required
                  value={agency.email}
                  autoComplete="off"
                />
              </Form.Group>
              <Form.Group as={Col} md="12">
                <Form.Label htmlFor="pin">PIN</Form.Label>
                <TextInput
                  name="pin"
                  id="pin"
                  type="text"
                  placeholder="PIN"
                  required
                  minLength="6"
                  defaultValue={agency.pin}
                  onChange={handleChange}
                  autoComplete="off"
                />
              </Form.Group>
            </Form.Row>
          </Col>
          <Col md={12}>
            <Form.Row>
              <Form.Group as={Col} sm={12} className="mt-3">
                <Button type="submit">Submit</Button>
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
      </ValidationForm>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title as="h5">Agency Edit</Card.Title>
      </Card.Header>
      <Card.Body>{getContent()}</Card.Body>
    </Card>
  );
};

export default AgencyEdit;
