import React, {Component} from 'react';
import {Col, Row, Carousel} from "react-bootstrap";
import Facility from "./Facility";
import {IFacility} from "../../types/storeTypes";

interface FacilitiesProps {
  // onClose:  (() => void)
  facilities: IFacility[] | null
}

const Facilities: React.FC<FacilitiesProps> = (props) => {
    const facilities = props.facilities;

    const getFacility = (facility: IFacility | null) => {
      if (facility) {
        return <Facility facility={facility}/>;
      }

      return;
    };

    const renderFacilities = () => {
      if (facilities) {
        const carouselItems = [];

        for (let i = 0; i < facilities.length; ) {
          const facilityOne = facilities[i];
          const facilityTwo = (i + 1) < facilities.length ? facilities[i + 1] : null;
          const facilityThree = (i + 2) < facilities.length ? facilities[i + 2] : null;
          // i = (i + 3) < facilities.length ? i + 3 : i + 3;
          i += 3;
          carouselItems.push(
            <Carousel.Item key={i}>
              {getFacility(facilityOne)}
              {getFacility(facilityTwo)}
              {getFacility(facilityThree)}
              {/*<Facility icon={} title={"Flower Dome"}/>*/}
              {/*<Facility icon={'forest'} title={"Cloud Forest"}*/}
              {/*          remarks={{icon: 'ticket-center', remark: "ticket included"}}/>*/}
              {/*<Facility icon={'meal'} title={"Lunch at cafe"} remarks={null}/>*/}
            </Carousel.Item>
          );
        }

        return carouselItems;
      }

      return;
    };

    return (
      <div>
        <Row>
          <Col xs={12} className='facilities-container'>
            <Carousel controls={false} interval={null}>
              {renderFacilities()}
            </Carousel>
          </Col>
        </Row>
      </div>
    );
  }
;

export default Facilities;