import React, {useEffect, useState} from 'react';
import {ProgressBar} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {IActivity, IAirTravel, IDay, TourLineState} from "../../../types/storeTypes";
import {setEditingComplete, setErrorSummary} from "../../../store/actions/tourlineActions";
import {CDN_PATH} from "../../../constants/values";
import {activityPlaceHolderText} from "../Editors/MainPhotoEditor/ActivityEditor";

const CheckProgress: React.FC = () => {
  const [progress, setProgress] = useState(0);
  const tourState: TourLineState = useSelector((state: AppState) => state.tour);
  const dispatch = useDispatch();
  // const initErrorList: string[] = [];
  // const [errorList, setErrorList] = useState(initErrorList);
  const [isErrorListSent, setIsErrorListSent] = useState(false);
  let errorList: string[] = [];

  useEffect(() => {
    // console.log('CheckProgress updated');

    const isComplete = checkCompleted();
    // console.log('Ready to save - ' + isComplete);

    /**
     * no need to update if already in complete mode, & update to not complete on new day/activity added
     * (anything that makes) tour incomplete.
     */
    if ((!tourState.isEditingComplete && isComplete) || (tourState.isEditingComplete && !isComplete)) {
      // console.log('dispatch progress');
      dispatch(setEditingComplete(isComplete));
      dispatchErrorList(); // :TODO problem when isEditingComplete and changes to existing items happen, not working correct!
    }

    if (isComplete && !tourState.isQuickSaved) {
      setTimeout(() => { //really?
        dispatchErrorList();
      }, 500);
    }

    // if(isComplete && !isErrorListSent) {
    //   console.log('Dispatch Error List');
    // }

    // const timeout = setTimeout(() => {
    //   const isComplete = checkCompleted();
    //   console.log('Ready to save - ' + isComplete);
    //
    // }, 5000);
    //
    // return () => {
    //   clearTimeout(timeout);
    // }
  });

  const checkCompleted = () => {
    // const prevErrorList = errorList.slice();
    errorList = []; //reset error list to initial state.
    // setErrorList(initErrorList); //reset error list to initial state.

    checkFlightDetailsPhoto(tourState.airTravel);
    if (!tourState.airTravel.mainPhoto) {
      // console.log('air');
      // return false;
    }

    const days = tourState.days;
    for (const day of days) {
      checkPhotoErrorDay(day);
      if (!day.mainPhoto) {
        // console.log('day main');
        return false;
      }

      const activities: IActivity[] | null = day.activities;
      if (!activities) {
        // console.log('no activity');
        // return false; // uncomment this to strict publish now
      } else {
        for (const activity of activities) {
          checkErrorsActivity(activity);
          if (!activity.mainPhoto || activity.title === '') {
            // console.log('activity');
            // return false; // uncomment this to strict publish now
          }
        }
      }
    }

    // const hasErrorListChanged = !prevErrorList.every(e => errorList.includes(e));
    // console.log('Has error list changed: ' + hasErrorListChanged);
    // if(hasErrorListChanged){
    //   console.log('Set Error List Sent False');
    // }

    return true;
  };



  const checkFlightDetailsPhoto = (airTravel: IAirTravel) => {
    if (airTravel.mainPhoto === null || airTravel.mainPhoto.substring(0, 22) !== CDN_PATH) {
      const error = 'Flight Details: missing or unsaved photo.';
      // console.log(error);
      pushErrorToList(error);
    }
  };

  const checkPhotoErrorDay = (day: IDay) => {
    if (day.mainPhoto === null || day.mainPhoto.substring(0, 22) !== CDN_PATH) {
      const error = 'Day ' + day.number + ':missing or unsaved photo.';
      // console.log(error);
      pushErrorToList(error);
    }
  };

  const checkErrorsActivity = (activity: IActivity) => {
    if (!activity.mainPhoto || activity.mainPhoto.substring(0, 22) !== CDN_PATH) {
      const error = 'Day ' + activity.dayNumber + ' / Activity ' + activity.number + ':missing or unsaved photo.';
      pushErrorToList(error);
    }

    if (activity.title === '' || activity.title === activityPlaceHolderText) {
      const error = 'Day ' + activity.dayNumber + ' / Activity ' + activity.number + ':title needed.';
      pushErrorToList(error);
    }
  };

  const pushErrorToList = (error: string) => {
    const errors = errorList.slice();
    errors.push(error);
    errorList = errors;
  };

  const dispatchErrorList = () => {
    // setIsErrorListSent(true);
    dispatch(setErrorSummary(errorList));
  };

  // function mainItemIsTypeActivity(mainItem: IDay | IActivity): mainItem is IActivity {
  //   return 'title' in mainItem;
  // }


  const animateProgress = () => {
    //   const interval = setInterval( () => {
    //     if (progress < 100) {
    //       setProgress(progress + 20);
    //     } else {
    //       clearInterval(interval);
    //       setProgress(0);
    //     }
    //
    //   }, 500);
  };


  return (
    <ProgressBar variant="info" now={progress} className="tour-progress"/>
  );
};

export default CheckProgress;