import React from 'react';

const SignUp1 = React.lazy(() => import('../view/Authentication/SignUp/SignUp2'));
const Signin1 = React.lazy(() => import('../view/Authentication/SignIn/SignIn2'));
const Logout = React.lazy(() => import('../view/Authentication/Logout'));
const Tours = React.lazy(() => import('../Client/views/SearchResults'));
const Tour = React.lazy(() => import('../Client/components/tour/Tour'));
const Payment = React.lazy(() => import('../Client/components/payment/Payment'));
const ThankYou = React.lazy(() => import('../Client/components/payment/feedback/ThankYou')); //payment success
const TravelDoc = React.lazy(() => import('../Client/components/details/EditTravelDoc')); //edit later
const HostGuide = React.lazy(() => import('../Client/components/guide/HostGuide'));
const MyBookings = React.lazy(() => import('../Client/components/my-bookings/MyBookings'));
const ViewBooking = React.lazy(() => import('../Client/components/my-bookings/view-booking/ViewBooking'));

// :TODO update all below paths like this
export const ROUTE_PATHS = {
    tours: '/tours'
}

const route = [
    // { path: '/register', exact: true, name: 'Signup 1', component: SignUp1 },
    // { path: '/login', exact: true, name: 'Signin 1', component: Signin1 },
    { path: '/logout', exact: true, name: 'Logout', component: Logout },
    { path: ROUTE_PATHS.tours, exact: true, name: 'Tours', component: Tours },
    { path: '/paynow', exact: true, name: 'PayNow', component: Payment },
    { path: '/payment-feedback', exact: true, name: 'Success', component: ThankYou },
    { path: '/travel-doc/:bookingID', exact: true, name: 'Traveler Details', component: TravelDoc },
    { path: '/tour/:title/:id?', exact: true, name: 'Tour', component: Tour },
    { path: '/host-guide', exact: true, name: 'HostGuide', component: HostGuide },

    { path: '/my-bookings', exact: true, name: 'MyBookings', component: MyBookings },
    { path: '/booking/:orderID', exact: true, name: 'View Booking', component: ViewBooking },
];

export default route;
