import React, {useEffect, useRef, useState} from 'react';
import {withPopup} from "../common/Popup";
import DurationPicker from "./DurationPicker";
import {Col, Form, InputGroup} from "react-bootstrap";
import {IDuration, ISearch} from "../../types/clientStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setDurationPickerVisibility} from "../../store/actions/clientActions";
import {useOutsideClickNotifier} from "../common/OutsideClickNotifier";

const InputDuration: React.FC = () => {
  const duration: IDuration = useSelector((state: AppState) => state.client.search.duration);
  const durationValue = !duration.isDefault ? duration.nights + 'N/' + duration.days + 'D' : '';
  const dispatch = useDispatch();

  // const [isDurationPopupOn, setIsDurationPopupOn] = useState(false);
  const isDurationPopupOn = useSelector((state: AppState) => state.client.isDurationPickerOn);
  const DurationPopup = withPopup(DurationPicker, null);

  const wrapperRef = useRef(null);
  const [resetOutsideClick, setResetOutsideClick] = useState(false);
  const isClickOutside = useOutsideClickNotifier(wrapperRef, resetOutsideClick);

  useEffect(() => {
    if (!isDurationPopupOn) {
      return;
    }
    setResetOutsideClick(false);
  }, [isDurationPopupOn]);

  useEffect(() => {
    if (!isClickOutside) {
      return;
    }
    dispatch(setDurationPickerVisibility(false));
    setResetOutsideClick(true);
  }, [isClickOutside]);

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      // setIsDurationPopupOn(false);
      dispatch(setDurationPickerVisibility(false));
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  });

  const handleDurationInputClick = () => {
    // setIsDurationPopupOn(!isDurationPopupOn);
    dispatch(setDurationPickerVisibility(!isDurationPopupOn));
  };

  return (
    <Form.Group className='search-picker-input'
                controlId="th-search-duration"
                ref={wrapperRef}>
      <InputGroup className="th-search-input-group" onClick={handleDurationInputClick}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <i className="feather icon-clock"/>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          placeholder="3N/4D"
          value={durationValue}
          className="input-icon"
          readOnly={true}
        />
      </InputGroup>

      {isDurationPopupOn && <DurationPopup/>}

    </Form.Group>
  );
};

export default InputDuration;