import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {setActiveEditor} from "../../store/actions/tourlineActions";
import {SUMMARY_EDITOR} from "../../constants/actions";

const TourTitle: React.FC = () => {
  const title = useSelector((state: AppState) => state.tour.tourTitle);
  const dispatch = useDispatch();

  return (
    <p className='tour-title' onClick={() => {dispatch(setActiveEditor(SUMMARY_EDITOR))}}>{title}</p>
  );
};

export default TourTitle;