import React, {useEffect, useRef, useState} from 'react';
import {withPopup} from "../common/Popup";
import PaxPicker from "./PaxPicker";
import {Form, InputGroup} from "react-bootstrap";
import {IPax} from "../../types/clientStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setDurationPickerVisibility} from "../../store/actions/clientActions";
import {useOutsideClickNotifier} from "../common/OutsideClickNotifier";

const InputPax: React.FC = () => {
  const pax: IPax = useSelector((state: AppState) => state.client.search.pax);
  const dispatch = useDispatch();
  let initPaxValue = !pax.isDefault ? pax.adults + ' Adults, ' + pax.children + ' Children' : '';
  const [paxValue, setPaxValue] = useState(initPaxValue);

  const [isPaxPopupOn, setIsPaxPopupOn] = useState(false);
  const PaxPopup = withPopup(PaxPicker, null);

  const wrapperRef = useRef(null);
  const [resetOutsideClick, setResetOutsideClick] = useState(false);
  const isClickOutside = useOutsideClickNotifier(wrapperRef, resetOutsideClick);

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setIsPaxPopupOn(false);
    }
  };

  const handlePaxInputClick = () => {
    setIsPaxPopupOn(!isPaxPopupOn);
    dispatch(setDurationPickerVisibility(false));
  };

  useEffect(() => {
    if (!isPaxPopupOn) {
      return;
    }
    setResetOutsideClick(false);
  }, [isPaxPopupOn]);

  useEffect(() => {
    if (!isClickOutside) {
      return;
    }
    setIsPaxPopupOn(false);
    setResetOutsideClick(true);
  }, [isClickOutside]);

  // const pax
  useEffect(() => {
    if (!pax.isDefault) {
      setPaxValue(pax.adults + ' Adults, ' + pax.children + ' Children');
    }

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [pax]);

  const popupOnRef = useRef<boolean>(false); // to set input value on first click.

  useEffect(() => {
    if (isPaxPopupOn !== popupOnRef.current) {
      setPaxValue(pax.adults + ' Adults, ' + pax.children + ' Children');
    }

    popupOnRef.current = isPaxPopupOn;
  }, [isPaxPopupOn]);

  return (
    <Form.Group className='search-picker-input' controlId="th-search-people" ref={wrapperRef}>
      <InputGroup className="th-search-input-group"
                  onClick={handlePaxInputClick}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <i className="feather icon-users"/>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          type="text"
          placeholder="2 Adults, 2 Children"
          className="input-icon"
          value={paxValue}
          readOnly
        />
      </InputGroup>

      {isPaxPopupOn && <PaxPopup/>}
    </Form.Group>
  );
};

export default InputPax;