import React, {useEffect, useState} from 'react';
import {Col, Form, InputGroup} from "react-bootstrap";
import PasswordStrength from "./PasswordStrength";

type SecurePasswordProps = {
  onSecurePasswordCreated: (password: string | null) => void
}

const SecurePassword: React.FC<SecurePasswordProps> = (props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isValidatedRetype, setIsValidatedRetype] = useState(false);

  const [password, setPassword] = useState<string | null>(null);
  const [retypePassword, setRetypePassword] = useState<string | null>(null);
  const [passwordStrength, setPasswordStrength] = useState(0);

  const handleOnShowPassword = () => {
    setIsPasswordVisible(isPasswordVisible => !isPasswordVisible);
  };

  useEffect(() => {
    if (!isPasswordVisible) {
      return;
    }

    props.onSecurePasswordCreated(passwordStrength > 2 && password ? password : null);
  }, [isPasswordVisible]);

  const handlePasswordChange = (password: string) => {
    setPassword(password);
  };

  const handleRetypePasswordChange = (retypePassword: string) => {
    setRetypePassword(retypePassword);
    if (!password) {
      return;
    }

    const isPasswordsMatch = password === retypePassword;
    props.onSecurePasswordCreated(passwordStrength > 2 && isPasswordsMatch && password ? password : null);
  };

  const handleOnPasswordStrengthChange = (strength: number) => {
    setPasswordStrength(strength);
    const isPasswordsMatch = isPasswordVisible || isBothPasswordsSame();
    props.onSecurePasswordCreated(strength > 2 && isPasswordsMatch && password ? password : null);
  };

  const isBothPasswordsSame = () => {
    if (!password || !retypePassword) {
      return false;
    }

    return password === retypePassword;
  };

  const getConfirmPasswordInput = () => {
    return (
      <Form.Group as={Col} md="12" className='password-group pl-0 pr-0' controlId="validationConfirmPassword">
        <InputGroup className='password-append'>
          <Form.Control
            required
            type='password'
            placeholder="retype password"
            isInvalid={isValidatedRetype && !isBothPasswordsSame()}
            className='append-control'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRetypePasswordChange(e.target.value)}
            onBlur={() => setIsValidatedRetype(true)}
          />
          <Form.Control.Feedback
            className={isValidatedRetype && !isBothPasswordsSame() ? 'dis-block text-left' : ''}
            type="invalid">
            Please type the same password
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    )
  };

  return (
    <React.Fragment>
      <Form.Group as={Col} md="12" className='password-group pl-0 pr-0 mb-2' controlId="validationCustomPassword">
        {/*<Form.Label>Choose your password*</Form.Label>*/}
        <InputGroup className='password-append'>
          <Form.Control
            required
            type={isPasswordVisible ? 'text' : 'password'}
            placeholder="new password"
            isInvalid={isValidated && passwordStrength < 3}
            className='append-control'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handlePasswordChange(e.target.value)}
            onBlur={() => setIsValidated(true)}
          />
          <InputGroup.Append>
            <InputGroup.Text onClick={handleOnShowPassword}>
              <i className={`feather ${isPasswordVisible ? 'icon-eye-off' : 'icon-eye'}`}/>
            </InputGroup.Text>
          </InputGroup.Append>

          <PasswordStrength password={password} onStrengthChange={handleOnPasswordStrengthChange}/>
          <Form.Control.Feedback
            className='text-left'
            type="invalid">
            Please type a strong password<br/> (min length 8, a-z, A-Z, 1-9, @ # $ * )
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>

      {!isPasswordVisible && getConfirmPasswordInput()}

    </React.Fragment>
  );
};

export default SecurePassword;