import React, {Component, useEffect} from 'react';
import {Col, Row, FormControl, Card, Form} from "react-bootstrap";
import FlightEditor from "./FlightEditor";
import VisaInfo from "./VisaInfo";
import AirOtherInfo from "./AirOtherInfo";
import EditorClose from "../EditorClose";
import FlightDataEditor from "./FlightDataEditor";
import {useDispatch} from "react-redux";
import {resetFlightEditor} from "../../../../store/actions/airFareActions";

interface AirInfoEditorInterface {
  info: string
}

interface AirInfoEditorState {
  infoType: string
}

const infoTypes = {
  FLIGHT: 'flight',
  VISA: 'visa',
  INFO: 'info'
};

// class AirInfoEditor extends Component<AirInfoEditorInterface, AirInfoEditorState> {
const AirInfoEditor: React.FC<AirInfoEditorInterface> = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFlightEditor());
  }, [])

  return (
    <Card className='editors'>
      <Card.Header>
        <Card.Title as="h5">Air travel information.</Card.Title>
        <EditorClose/>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12}>
            <FlightDataEditor/>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default AirInfoEditor;