import React from 'react';
import {Col, Row} from "react-bootstrap";
import AirTravel from "./AirTravel";
import Day from "./Day";
import {ITourLine} from "../../types/TourLineTypes";

type TourLineViewProps = {
  tourLine: ITourLine
}

const TourLineView: React.FC<TourLineViewProps> = (props) => {
  const tourLine = props.tourLine;

  const renderDays = () => {
    // console.log(tourLine);
    return (
      tourLine.days.map((day, idx) => {
        return <Day key={idx} day={day}/>;
      })
    )
  };

  return (
    <Row>
      <Col xs={12} className='tours'>
        <div className='timeline'>
          {tourLine.airTravel.photo && <AirTravel airTravel={tourLine.airTravel}/>}
          {renderDays()}
          {/*<Day number={4}/>*/}
          {/*<AirTravel country={''}/>*/}
        </div>

      </Col>
    </Row>
  );
};

export default TourLineView;