import gql from "graphql-tag";
import {AirFareFragment} from "../fragments/AirFareFragment";

export const ADD_AIR_FARE = gql`
  ${AirFareFragment}

  mutation addTicket(
    $tour: String!,
    $activeDateType: String!,
    $availableStartDate: String!,
    $availableEndDate: String,
    $flights: [FlightInput!],
    $price: AirFarePriceInput!,
    $seatClass: String!,
    $meals: Boolean,
    $wifi: Boolean,
    $baggage: Float) {
        addAirFare(
        tour: $tour, 
        activeDateType: $activeDateType, 
        availableStartDate: $availableStartDate,
        availableEndDate: $availableEndDate,
        flights: $flights,
        price: $price,
        seatClass: $seatClass,
        meals: $meals,
        wifi: $wifi,
        baggage: $baggage
        ) {
          fareID:_id
          ...AirFareFields
        }
    }
`;

export const UPDATE_AIR_FARE = gql`
  ${AirFareFragment}

  mutation updateTicket(
    $id: String!,
    $tour: String!,
    $activeDateType: String!,
    $availableStartDate: String!,
    $availableEndDate: String,
    $flights: [FlightInput!],
    $price: AirFarePriceInput!,
    $seatClass: String!,
    $meals: Boolean,
    $wifi: Boolean,
    $baggage: Float) {
        updateFare(
        _id: $id,
        tour: $tour, 
        activeDateType: $activeDateType, 
        availableStartDate: $availableStartDate,
        availableEndDate: $availableEndDate,
        flights: $flights,
        price: $price,
        seatClass: $seatClass,
        meals: $meals,
        wifi: $wifi,
        baggage: $baggage) {
          fareID:_id
          ...AirFareFields
        }
    }
`;

export const ALL_AIR_TICKETS = gql`
  query allFlights($tourID: String!) {
    airTickets(tourID: $tourID) {
      _id
      tour{
        _id
      }
      availableType: activeDateType
      availableStartDate
      availableEndDate
      seatClass
    }
  }
`;

export const TICKETS_BY_DATE = gql`
  ${AirFareFragment}

  query flightsByDate($tourID: String!, $departure: String!) {
    airTicketsByDate(tourID: $tourID, departure: $departure) {
      fareID:_id
      ...AirFareFields
    }
  }
`;

export const DELETE_TICKET = gql`
  mutation removeTicket ($fareID: String!) {
    deleteAirFare(id: $fareID){
      deletedCount
      deletedTicket{
        _id
        tour{
          _id
        }
        availableStartDate
      }
    }
  }
`;

export const AIR_TICKET = gql`
  query allFlights($tourID: String!) {
    airTickets(tourID: $tourID) {
      _id
      tour{
        _id
      }
      activeDateType
      availableStartDate
      availableEndDate
      seatClass
      price{
        adult
        master
      }
      flights{
        orderNumber
        departurePort
        departureDateTime
        arrivalPort
        arrivalDateTime
        carrier
      }
    }
  }
`;

