import React from 'react';
import {Carousel, Col, Row} from "react-bootstrap";
import Facility from "./Facility";
import {IFacility} from "../../types/TourLineTypes";

interface FacilitiesProps {
  onClose:  (() => void)
  facilities: IFacility[] | null
}

const Facilities: React.FC<FacilitiesProps> = (props) => {
    const facilities = props.facilities;

    const getFacility = (facility: IFacility | null) => {
      if (facility) {
        return <Facility facility={facility}/>;
      }

      return;
    };

    const renderFacilities = () => {
      if (facilities) {
        const carouselItems = [];

        for (let i = 0; i < facilities.length;) {
          const facilityOne = facilities[i];
          const facilityTwo = (i + 1) < facilities.length ? facilities[i + 1] : null;
          const facilityThree = (i + 2) < facilities.length ? facilities[i + 2] : null;
          // i = (i + 3) < facilities.length ? i + 3 : i + 3;
          i += 3;
          carouselItems.push(
            <Carousel.Item key={i}>
              {getFacility(facilityOne)}
              {getFacility(facilityTwo)}
              {getFacility(facilityThree)}
              {/*<Facility icon={} title={"Flower Dome"}/>*/}
              {/*<Facility icon={'forest'} title={"Cloud Forest"}*/}
              {/*          remarks={{icon: 'ticket-center', remark: "ticket included"}}/>*/}
              {/*<Facility icon={'meal'} title={"Lunch at cafe"} remarks={null}/>*/}
            </Carousel.Item>
          );
        }

        if (carouselItems.length > 0) {
          return carouselItems;
        } else {
          return <p className='ml-2'>no facility listed.</p>;
        }
      }

      return;
    };

    return (
      <React.Fragment>
        <Row>
          <Col xs={12} className='facilities-container'>
            <Carousel controls={false} interval={10000}>
              {renderFacilities()}
            </Carousel>
            <i className='feather icon-x facility-mode-close' onClick={() => props.onClose()}/>
          </Col>
        </Row>


        <Row>
          <Col xs={12}>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
;

export default Facilities;