import React, {useState} from "react";
import {Redirect} from "react-router-dom";
import {ROUTE_PATHS} from "../../../routes/route";

export const useRedirectToListing = (isRedirect: boolean) => {
  const [redirect, setRedirect] = useState(isRedirect);
  let output: any = <Redirect to={ROUTE_PATHS.tours}/>;

  const setRedirectToListing = (isRedirect: boolean) => {
    setRedirect(isRedirect);
  }

  if (!redirect) {
    output = null;
  }

  return [output, setRedirectToListing];
}