import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from "react-bootstrap";
import AirTravelEdit from "./AirTravelEdit";
import Editors from "./Editors/Editors";
import * as actionTypes from "../../store/actions";
import {useDispatch, useSelector} from "react-redux";
import {IAirTravel, IDay, TourLineState} from "../../types/storeTypes";
import Day from "./Day";
import {AppState} from "../../store/reducers";
import TourTitle from "./TourTitle";
import SaveFeedback from "./Persistance/SaveFeedback";
import CheckProgress from "./Persistance/CheckProgress";
import TourLineOptions from "./Options/TourLineOptions";
import PopupEditor from "./Editors/PopupEditor";
import {AIR_TRAVEL_EDITOR, PHOTO_EDITOR, REVIEW_EDITOR, REVIEW_VIEWER, SUMMARY_EDITOR} from "../../constants/actions";
import MainPhotoEditor from "./Editors/MainPhotoEditor/MainPhotoEditor";
import AirInfoEditor from "./Editors/AirInfoEditors/AirInfoEditor";
import SummaryEditor from "./Editors/SummaryEditor/SummaryEditor";
import ReviewNotifications from "./Editors/ReviewEditors/ReviewNotifications";
import CreateAdminReview from "./Editors/ReviewEditors/CreateAdminReview";
import ViewAdminReview from "./Editors/ReviewEditors/ViewAdminReview";

/*interface TourLineEditProps {
  airTravel: IAirTravel,
  days: IDay[],
  toggleNav: () => void
}*/

const TourLineEdit: React.FC = () => {
  // throttleTimeout: ReturnType<typeof setTimeout> | null  = null;
  const tour: TourLineState = useSelector((state: AppState) => state.tour);
  const inFacilityMode = useSelector((state: AppState) => state.tour.inFacilityMode);
  const activeEditor: string | null = useSelector((state: AppState) => state.tour.activeEditor);

  const airTravel: IAirTravel = tour.airTravel;
  const days: IDay[] = tour.days;
  const dispatch = useDispatch();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    dispatch({type: actionTypes.CLOSE_MENU});
  });

  useEffect(() => {
    // console.log(window.innerWidth < 1300);
    setIsSmallScreen(window.innerWidth < 1300);
  }, []);

  // :TODO - reload other browser tabs, on new tour added.
  // const prevTourID = useRef<string | null>(null);
  // useEffect(() => {
  //   if (tour.id === null && prevTourID.current !== null) {
  //     alert('Editing tour changed.');
  //     window.location.reload();
  //   }
  //   prevTourID.current = tour.id
  // }, [tour.id]);

  const getEditors = () => {
    return (
      <React.Fragment>
        {activeEditor && activeEditor === PHOTO_EDITOR && <MainPhotoEditor/>}
        {activeEditor && activeEditor === AIR_TRAVEL_EDITOR && <AirInfoEditor info={'New Air Line info'}/>}
        {activeEditor && activeEditor === SUMMARY_EDITOR && <SummaryEditor/>}
        {activeEditor && activeEditor === REVIEW_EDITOR && <CreateAdminReview/>}
        {activeEditor && activeEditor === REVIEW_VIEWER && <ViewAdminReview/>}
      </React.Fragment>
    );
  };

  return (
    <Row>
      <ReviewNotifications/>

      <Col xs={12} md={5} className='tours edit-tours'>
        <CheckProgress/>

        <Card className=''>
          <Card.Body>
            <Row>
              <Col>
                <TourLineOptions/>
                <div className='feedback-row'>
                  <SaveFeedback/>
                </div>
                <TourTitle/>
              </Col>
            </Row>
            <div className='timeline'>
              {airTravel && <AirTravelEdit country={'Singapore'}/>}

              <div className='days-conntainer'>
                {days.map((day: IDay, index) => {
                  return (<Day day={day} key={index}/>);
                })}
              </div>
              {/*<AirTravelEdit country={''}/>*/}
            </div>
          </Card.Body>
        </Card>
      </Col>

      {(!isSmallScreen || inFacilityMode) && <Editors>{getEditors()}</Editors>}
      {(isSmallScreen && !inFacilityMode) && <PopupEditor>{getEditors()}</PopupEditor>}
    </Row>
  );
};

export default TourLineEdit;

// const mapStateToProps = (state: AppState) => {
//   return {
//     airTravel: state.tour.airTravel,
//     days: state.tour.days
//   };
// };
//
// const mapDispatchToProps = (dispatch: any) => {
//   return {
//     toggleNav: () => dispatch({type: actionTypes.CLOSE_MENU}),
//   };
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(TourLineEdit);