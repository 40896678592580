import React from 'react';
import {useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {PHOTO_TYPE_ACTIVITY, PHOTO_TYPE_DAY} from "../../../constants/actions";
import {IActivity, IDay} from "../../../types/storeTypes";

const PhotoEditorTitle: React.FC = () => {
  const photoEditor = useSelector((state: AppState) => state.tour.photoEditor);
  let day: IDay | null = null;
  let activity: IActivity | null = null;

  if (photoEditor && photoEditor.photoEditorType === PHOTO_TYPE_DAY) {
    day = photoEditor.editor as IDay;
  } else if (photoEditor && photoEditor.photoEditorType === PHOTO_TYPE_ACTIVITY) {
    activity = photoEditor.editor as IActivity;
  }

  if (activity) {
    return (
      <div>
        <i className='feather icon-calendar'/>
        <span className='editor-active-day'>
          DAY {activity.dayNumber}
        </span>
        <span className='editor-title-sep'>/</span>
        <span className='editor-active-activity'>Activity {activity.number}</span>
      </div>
    );
  }

  if (day) {
    return (
      <div>
        <i className='feather icon-calendar'/>
        <span className='editor-active-day'>
        DAY {day.number}
        </span>
      </div>
    );
  }

  return (<div className='mb-3 editor-active-day'>Flight Photo</div>);
};

export default PhotoEditorTitle;