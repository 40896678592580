import React, {useState} from 'react';
import FacilitySearch from "./FacilitySearch";
import AllFacilities from "./AllFacilities";

const FacilityEditor: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState('');

  const handleQueryChange = (query: string) => {
    setSearchQuery(query)
  };

  return (
    <React.Fragment>
      <FacilitySearch setQuery={handleQueryChange} />
      <AllFacilities query={searchQuery}/>
    </React.Fragment>
  );
};

export default FacilityEditor;