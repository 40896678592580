// import {TOURLINE_ACTIONS} from '../actions';
// import tourConfig from '../tourConfig'

import {IActivity, IDay, MoveLocation, TourLineActionTypes, TourLineState} from "../../types/storeTypes";
import * as ACTION_TYPES from "../../constants/actions";
import {
  addNewActivity,
  addNewDay,
  cloneActivityInDay,
  cloneDayInTourline,
  moveActivityToAnotherDay,
  moveSelectedActivity,
  moveSelectedDay
} from "../util/tourlineUtil";

export const sampleActivity: IActivity = {
  dayNumber: 1,
  number: 1,
  title: '',
  mainPhoto: null,
  facilities: null
};

export const tourLineInitialState: TourLineState = {
  id: null,
  tourTitle: null,
  isEditingComplete: false,
  airTravel: {
    mainPhoto: null,
    airInfo: [
      {number: 1, info: "CMB: 2230h Oct 08, Sri Lankan Air Lines Boing 746"},
      {number: 2, info: "Visa on arrival - up to 7 days."},
      {number: 3, info: "CMB: 2230h Oct 08, Sri Lankan Air Lines Boing 746"}
    ]
  },
  days: [
    {number: 1, mainPhoto: null, activities: [{dayNumber: 1, ...sampleActivity}]},
    // {number: 1, mainPhoto: null, activities: null},
    // {number: 2, mainPhoto: null, activities: null},
    // {number: 3, mainPhoto: null, activities: null},
  ],
  generalInfo: {
    mainPhoto: null,
    info: ['calm', 'cold']
  },
  activeEditor: null, // ACTION_TYPES.AIR_TRAVEL_EDITOR, //null
  photoEditor: null,
  inFacilityMode: false,
  inSettingsMode: false,
  destinations: [],
  isFileUploaded: false,
  isSaved: false,
  isQuickSaved: true,
  errorSummary: [],
  price: {originalPrice: 0, market: 0, discountedPrice: 0},
  isNewTour: false,
  facilityEditorID: null,
  facilityInEditor: null,
  published: false,
  adminReview: null,
  reviewStatus: null
};

// const tourReducer: TourLineState = (state: TourLineState = initialState, action: TourLineActionTypes) => {
export function tourReducer(state = tourLineInitialState, action: TourLineActionTypes): TourLineState {
  if (!state.isNewTour && (action.type === ACTION_TYPES.SET_ITEM_PHOTO)) {
    state.isNewTour = true;
  }

  switch (action.type) {
    case ACTION_TYPES.SET_EDITOR:
      return {
        ...state,
        activeEditor: action.editorType,
      };
    case ACTION_TYPES.SET_PHOTO_EDITOR:
      return {
        ...state,
        isFileUploaded: false,
        photoEditor: action.editor,
      };
    case ACTION_TYPES.SET_ITEM_PHOTO: {
      switch (action.payload.photoEditorType) {
        case ACTION_TYPES.PHOTO_TYPE_AIR_INFO:
          return {
            ...state,
            airTravel: {
              ...state.airTravel,
              mainPhoto: action.payload.editor.mainPhoto
            },
          };
        case ACTION_TYPES.PHOTO_TYPE_DAY: {
          let days = state.days.slice();
          const dayEditor = action.payload.editor as IDay;
          days[dayEditor.number - 1] = dayEditor;

          // console.log('cdn check  : ' + dayEditor.src);
          // console.log('cdn check2 : ' + dayEditor.src === 'cdn');

          return {
            ...state,
            days: days,
            // isQuickSaved: (dayEditor.src !== null && dayEditor.src === 'cdn')
          };
        }
        case ACTION_TYPES.PHOTO_TYPE_ACTIVITY: {
          const activityEditor = action.payload.editor as IActivity;
          const allDays = state.days.slice();
          const day = allDays[activityEditor.dayNumber - 1];
          const activities = day.activities ? day.activities.slice() : null;
          if (activities) {
            activities[activityEditor.number - 1] = activityEditor;
            day.activities = activities;
            allDays[activityEditor.dayNumber - 1] = day;
          }
          return {
            ...state,
            days: allDays,
            isQuickSaved: false, //to update activity title changes.
          };
        }
        default:
          return state;
      }
    }
    case ACTION_TYPES.ADD_DAY: {
      const dayNum = action.dayNum;
      const location = action.location;
      const days = addNewDay(state.days.slice(), action.dayNum, action.location);
      const dayNumber = dayNum && location ? (location === MoveLocation.ABOVE ? dayNum : dayNum + 1) : days.length;

      const newDay = days[dayNumber - 1];

      return {
        ...state,
        days: days,
        activeEditor: ACTION_TYPES.PHOTO_EDITOR,
        photoEditor: {photoEditorType: ACTION_TYPES.PHOTO_TYPE_DAY, editor: newDay},
        inFacilityMode: false,
        inSettingsMode: false,
        facilityEditorID: null,
        facilityInEditor: null
      };
    }
    case ACTION_TYPES.ADD_ACTIVITY: {
      let newActivityDay = action.day as IDay;

      const activityNum = action.activityNum;
      const location = action.location;
      const days: IDay[] = addNewActivity(state.days.slice(), newActivityDay, activityNum, location);

      const updatedNewActivityDay: IDay | undefined = days.find((day: IDay) =>
        day.number === newActivityDay.number);

      if (!updatedNewActivityDay || !updatedNewActivityDay.activities) {
        return {...state, activeEditor: null, photoEditor: null}
      }

      const newActivityNumber = activityNum && location ?
        (location === MoveLocation.ABOVE ? activityNum : activityNum + 1)
        : (newActivityDay.activities ? newActivityDay.activities.length : 0);

      const newActivity = updatedNewActivityDay.activities[newActivityNumber - 1];

      return {
        ...state,
        days: days,
        activeEditor: ACTION_TYPES.PHOTO_EDITOR,
        photoEditor: {photoEditorType: ACTION_TYPES.PHOTO_TYPE_ACTIVITY, editor: newActivity},
        inFacilityMode: false,
        inSettingsMode: false,
        facilityEditorID: null,
        facilityInEditor: null
      };
    }
    case ACTION_TYPES.CLOSE_EDITOR:
      return {
        ...state,
        activeEditor: null,
        photoEditor: null
      };
    case ACTION_TYPES.REMOVE_ITEM: {
      switch (action.payload.photoEditorType) {
        case ACTION_TYPES.PHOTO_TYPE_DAY: {
          let days = state.days.slice();
          if (days.length > 1) {
            const dayEditor = action.payload.editor as IDay;
            days.splice(dayEditor.number - 1, 1);

            days.forEach((day, index) => {
              day.number = index + 1;
              // if (index >= dayEditor.number - 1) {
              const activities = day.activities;
              activities && activities.forEach((activity) => {
                activity.dayNumber = day.number;
              });
              // }
            });
          }

          return {
            ...state,
            days: days,
            activeEditor: null,
            photoEditor: null,
            isQuickSaved: false
          };
        }
        case ACTION_TYPES.PHOTO_TYPE_ACTIVITY: {
          const allDays = state.days.slice();
          const activityEditor = action.payload.editor as IActivity;

          const dayOfActivity = allDays[activityEditor.dayNumber - 1];
          let activities = dayOfActivity.activities ? dayOfActivity.activities.slice() : null;


          if (activities && activities.length > 1) {
            activities.splice(activityEditor.number - 1, 1);

            activities.forEach((activity, index) => {
              activity.number = index + 1;
            });

            dayOfActivity.activities = activities;
            allDays[activityEditor.dayNumber - 1] = dayOfActivity;
          }

          return {
            ...state,
            days: allDays,
            activeEditor: null,
            isQuickSaved: false, //to update changes on server.
            // photoEditor: null,
          };
        }
        default:
          return state;
      }
    }
    case ACTION_TYPES.SET_TITLE: //Tour Title
      return {
        ...state,
        isQuickSaved: false,
        tourTitle: action.title
      };
    case ACTION_TYPES.SET_COMPLETE:
      return {
        ...state,
        isEditingComplete: action.isComplete
      };
    case ACTION_TYPES.SET_FILE_UPLOADED:
      return {
        ...state,
        isQuickSaved: !action.isUploaded, //if uploaded to cdn, need to quick save again.
        isFileUploaded: action.isUploaded
      };
    case ACTION_TYPES.SET_TOUR_ID:
      return {
        ...state,
        id: action.id
      };
    case ACTION_TYPES.SET_SAVED:
      return {
        ...state,
        isSaved: action.isSaved
      };
    case ACTION_TYPES.SET_QUICK_SAVED:
      return {
        ...state,
        isQuickSaved: action.isQuickSaved,
        published: false
      };
    case ACTION_TYPES.SET_PHOTO_SRC:
      const photoEditor = state.photoEditor;
      if (photoEditor) {
        photoEditor.src = action.src;
      }
      return {
        ...state,
        photoEditor: photoEditor
      };
    case ACTION_TYPES.SET_DESTINATIONS:
      return {
        ...state,
        destinations: action.destinations,
        isQuickSaved: false
      };
    case ACTION_TYPES.SET_ERRORS:
      return {
        ...state,
        errorSummary: action.errors
      };
    case ACTION_TYPES.SET_PRICES:
      return {
        ...state,
        price: action.prices,
        // isQuickSaved: false
      };
    case ACTION_TYPES.SET_IN_FACILITY_MODE:
      return {
        ...state,
        inSettingsMode: false,
        inFacilityMode: action.payload
      };
    case ACTION_TYPES.SET_IN_SETTINGS_MODE:
      return {
        ...state,
        inSettingsMode: action.payload,
        inFacilityMode: false
      };
    case ACTION_TYPES.SET_FACILITY_ID:
      return {
        ...state,
        facilityEditorID: action.payload,

        // on Facility editor close (payload is null) set faciInEditor null. or new facility type clicked after existing edit.
        facilityInEditor: (!action.payload || state.facilityInEditor) ? null : state.facilityInEditor
      };
    case ACTION_TYPES.SET_FACILITY_IN_EDITOR:
      return {
        ...state,
        facilityInEditor: action.payload,
        facilityEditorID: action.payload.facilityID
      };
    case ACTION_TYPES.CLEAR_TOUR_IN_EDITOR:
      return tourLineInitialState;
    case ACTION_TYPES.SET_TOUR_TO_EDIT:
      return action.payload;
    case ACTION_TYPES.SET_PUBLISH_STATUS:
      return {
        ...state,
        published: action.payload
      };
    case ACTION_TYPES.MOVE_DAY: {
      const days = moveSelectedDay(state.days, action.dayNum, action.location);
      return {
        ...state,
        days: days,
        isQuickSaved: false
      }
    }
    case ACTION_TYPES.MOVE_ACTIVITY: {
      return moveSelectedActivity({...state}, action.activity, action.location)
    }
    case ACTION_TYPES.CLONE_ACTIVITY: {
      const activityToClone: IActivity = action.payload;
      return cloneActivityInDay({...state}, activityToClone);
    }
    case ACTION_TYPES.CLONE_DAY: {
      const dayToClone: IDay = action.payload;
      return cloneDayInTourline({...state}, dayToClone);
    }
    case ACTION_TYPES.MOVE_ACTIVITY_TO_DAY: {
      const toDayIndex: number = action.payload;
      return moveActivityToAnotherDay({...state}, toDayIndex);
    }
    case ACTION_TYPES.SET_ADMIN_REVIEW: {
      return {
        ...state,
        adminReview: action.payload
      }
    }
    case ACTION_TYPES.SET_REVIEW_STATUS:
      return {
        ...state,
        reviewStatus: action.payload
      };
    default:
      return state;
  }
}

// export default tourReducer;