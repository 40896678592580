import React from "react";
import { Col, Row } from "react-bootstrap";
import MainSearch from "./MainSearch";
import HomePromoBanner from "./HomePromoBanner";
import SimilarToursHome from "./SimilarToursHome";

const SectionSearch: React.FC = () => {
  return (
    <Row>
      <Col md={8} className="th-no-padding">
        <MainSearch />
      </Col>
      <Col md={4} className="th-no-padding">
        {/*<SimilarToursHome/>*/}
        <HomePromoBanner />
      </Col>
    </Row>
  );
};

export default SectionSearch;
