import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {GET_SAMPLE_TOUR} from "../graphql/queries/TourQueries";
import ProblemDataLoading from "../components/common/DataLoading/ProblemDataLoading";
import DataLoading from "../components/common/DataLoading/DataLoading";
import TourLineView from "../components/TourLineView/TourLineView";

const SampleTourline: React.FC = () => {
  const {data, loading, error} = useQuery(GET_SAMPLE_TOUR);

  if (error) {
    return <ProblemDataLoading/>;
  }

  if (loading) {
    return <DataLoading/>;
  }

  return (
    <Row>
      <Col xs={12} md={6} className='sample-tour'>
        {data.sampleTour && <TourLineView tourLine={data.sampleTour}/>}
      </Col>
    </Row>
  )
};

export default SampleTourline;