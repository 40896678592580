import React from 'react';
import {ActiveDates, IAirFare, IFlight} from "../types/flightStoreTypes";
import {getDateFormatted, getDateTime} from "./CommonFunctions";
import AirInfoItem from "../components/TourlineEdit/AirInfoItem";
import {DAILY_DEPARTURE} from "../constants/actions/FlightActions";
import {Airline} from "../types/FlightTypes";
import {useSelector} from "react-redux";
import {AppState} from "../store/reducers";
import {getAirlineNameByCode} from "./common";

type AirFareInfoProps = {
  activeFare: IAirFare | null
  activeDates: ActiveDates | null
}

const AirFareInfo: React.FC<AirFareInfoProps> = (props) => {
  const {activeFare, activeDates} = props;
  const airlines: Airline[] | null = useSelector((state: AppState) => state.flight.airlines);

  const getDepartureDateDifference = (activeStartDate: Date, firstFlightDepartureDate: Date) => {
    const startDate = getDateTime(activeStartDate); //new Date(activeStartDate.getTime());
    const firstFlightDate = new Date(firstFlightDepartureDate.getTime());
    startDate.setHours(0);
    startDate.setMinutes(0);
    firstFlightDate.setHours(0);
    firstFlightDate.setMinutes(0);

    const diffInDays = ( (startDate.getTime() - firstFlightDate.getTime()) / (1000 * 60 * 60 * 24));
    return diffInDays;
  };

  const getActiveAirFareInfo = (activeFare: IAirFare | null) => {
    if (activeFare && activeFare.flights) {

      let departureDifference = 0;

      if (activeFare.availableType === DAILY_DEPARTURE && activeDates) {
        departureDifference = getDepartureDateDifference(activeDates.start, getDateTime(activeFare.flights[0].departureDateTime));
        // console.log(departureDifference);
      }

      const baggage = activeFare.baggage > 0 ? ' - ' + activeFare.baggage + ' Kg' : '';

      return (
        activeFare.flights.map((flight: IFlight, idx) => {
          flight.departureDateTime = getDateTime(flight.departureDateTime);
          flight.arrivalDateTime = getDateTime(flight.arrivalDateTime);
          // "CMB: 2230h Oct 08, Sri Lankan Air Lines Boing 746"
          flight.departureDateTime.setDate(flight.departureDateTime.getDate() + departureDifference);
          flight.arrivalDateTime.setDate(flight.arrivalDateTime.getDate() + departureDifference);

          const departureDetails = flight.departurePort + ' ' +
            getDateFormatted(flight.departureDateTime, 'HHmm') + 'h ' +
            getDateFormatted(flight.departureDateTime, 'MMM DD') + ', ' +
            getAirlineNameByCode(airlines, flight.carrier) + baggage;

          const arrivalDetails = flight.arrivalPort + ' ' +
            getDateFormatted(flight.arrivalDateTime, 'HHmm') + 'h ' +
            getDateFormatted(flight.arrivalDateTime, 'MMM DD') + ', ' +
            getAirlineNameByCode(airlines, flight.carrier) + baggage;

          return <React.Fragment key={idx}>
            <AirInfoItem title={departureDetails} />
            <AirInfoItem title={arrivalDetails}/>
          </React.Fragment>;
        })
      );
    }
  };

  // if (!activeDates || !activeDates.start) {
  //   return <React.Fragment/>
  // }

  return (
    <React.Fragment>
      {getActiveAirFareInfo(activeFare)}
    </React.Fragment>
  );
};

export default AirFareInfo;