import React, {useEffect, useRef, useState} from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {IDestination, ISearch} from "../../types/clientStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {withPopup} from "../common/Popup";
import DestinationResult from "./DestinationResult";
import {setQuery} from "../../store/actions/clientActions";
import {useOutsideClickNotifier} from "../common/OutsideClickNotifier";

const InputDestination: React.FC = () => {
  const search: ISearch = useSelector((state: AppState) => state.client.search);
  const dispatch = useDispatch();
  const [isSearchPopupOn, setIsSearchPopupOn] = useState(false);

  const [destinationQuery, setDestinationQuery] = useState(search.query);
  const DestinationSearchPopup = withPopup(DestinationResult, {query: destinationQuery});

  const wrapperRef = useRef(null);
  const [resetOutsideClick, setResetOutsideClick] = useState(false);
  const isClickOutside = useOutsideClickNotifier(wrapperRef, resetOutsideClick);

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setIsSearchPopupOn(false);
    }
  };

  useEffect(() => {
    if (!isSearchPopupOn) {
      return;
    }
    setResetOutsideClick(false);
  }, [isSearchPopupOn]);

  useEffect(() => {
    if (!isClickOutside) {
      return;
    }
    setIsSearchPopupOn(false);
    setResetOutsideClick(true);
  }, [isClickOutside]);

  useEffect(() => {
    let destination: IDestination | IDestination[] | null = search.destination;
    if (destination && !Array.isArray(destination)) {
      destination = destination as IDestination;
      // console.log(destination);
      // initQuery = destination.name
      setDestinationQuery(destination.name);
      setIsSearchPopupOn(false);
    }

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    }
  }, [search.destination]);

  const handleDestinationInputClick = () => {
    if (destinationQuery.length > 0) {
      setIsSearchPopupOn(true);
    }
  };

  const handleDestinationChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setDestinationQuery(query);
    if (!isSearchPopupOn && query.trim().length > 0 ) {
      setIsSearchPopupOn(true);
    } else if (query.trim().length < 1) {
      setIsSearchPopupOn(false);
    }
    dispatch(setQuery(query));
  };


  return (
    <Form.Group controlId="th-search-location" ref={wrapperRef}>
      <InputGroup className=" th-search-input-group">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <i className="feather icon-search"/>
          </InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control
          // size="lg"
          type="text"
          placeholder="Country or Destination"
          className="input-icon destination-input"
          value={destinationQuery}
          onChange={handleDestinationChange}
          onClick={handleDestinationInputClick}
        />
      </InputGroup>

      {isSearchPopupOn && <DestinationSearchPopup />}
    </Form.Group>
  );
};

export default InputDestination;