import React, {useState} from 'react';
import {Button} from "react-bootstrap";
import {Redirect, useHistory} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setSearchUpdated} from "../../store/actions/clientActions";

const SearchButton: React.FC = () => {
  const dispatch = useDispatch();

  const [redirect, setRedirect] = useState(false);
  // let history = useHistory();

  const handleSearchClick = async () => {
    await dispatch(setSearchUpdated(true));
    setRedirect(true);
    // history.push('/tours');
  };

  const redirectToListing = () => {
    if (redirect) {
      return <Redirect to='/tours'/>;
    }
  };

  return (
    <React.Fragment>
      {redirectToListing()}
      <Button className="th-search-button" block onClick={handleSearchClick}>
        Start
      </Button>
    </React.Fragment>
  );

  // return (
  //   <React.Fragment>
  //     {!loading && <Button className="th-search-button" block onClick={() => loadTours()}>
  //       Start
  //     </Button>
  //     }
  //
  //     {called && loading && <Button className="th-search-button btn-secondary" block>
  //       <span className="spinner-border spinner-border-sm mr-1 mt-1 suggestion-loading"
  //                                   role="status"/>
  //
  //       Loading
  //     </Button>}
  //   </React.Fragment>
  //
  // );
};

export default SearchButton;