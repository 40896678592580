import React, {PropsWithChildren} from 'react';
import {getFacilityIconClass} from "../../../utils/common";

/**
 * for feather send icon-[name] only eg: icon-camera, for tour icons just icon name eg: vessel-cruise
 */
type LabelTagIconProps = {
  iconClass: string
}

const Icon: React.FC<LabelTagIconProps> = (props: PropsWithChildren<LabelTagIconProps>) => {
  const {iconClass} = props;

  return (
    <i className={getFacilityIconClass(iconClass)}/>
  )
};

export default Icon;