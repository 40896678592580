import * as ACTION_TYPES from "../constants/actions";
import {ReviewStatus} from "../constants/values";

export interface IAirInfo {
  number: number
  info: string
}

export interface IAirTravel {
  airInfo: IAirInfo[]
  mainPhoto: string | null
  src?: string | null //photo source
}

export interface GeneralInfo{
  mainPhoto: string | null
  info: string[]
}

export interface IFacility {
  number: number
  facilityID: string
  icon: string
  name: string
  ticket: string | null//included, NOT included or N/A (not applicable)
}

export interface IActivity {
  dayNumber: number
  number: number
  mainPhoto: string | null
  src?: string | null //photo source
  title: string
  facilities: IFacility[] | null
}

export interface IDay {
  number: number
  mainPhoto: string | null
  src?: string | null //photo source
  activities: IActivity[] | null
}

export interface IAdminReview {
  approved: boolean,
  rejectReasons: string,
  comments: string
}

export enum MoveLocation {
  ABOVE = 'above',
  BELOW ='below',
}

export interface PhotoEditor {
  photoEditorType: typeof ACTION_TYPES.PHOTO_TYPE_AIR_INFO | typeof ACTION_TYPES.PHOTO_TYPE_DAY |
    typeof ACTION_TYPES.PHOTO_TYPE_ACTIVITY  // Day, Activity or Air Main photo
  editor: IAirTravel | IDay | IActivity
  src?: string | null
}

export interface Destination {
  facility: string // facility id
  name: string
}

export interface Prices {
  originalPrice: number
  market: number
  discountedPrice: number
}

export interface TourLineState {
  id: string | null
  airTravel: IAirTravel
  days: IDay[]
  generalInfo: GeneralInfo
  activeEditor: string | null //Photo or Airline
  photoEditor: PhotoEditor | null
  inFacilityMode: boolean,
  inSettingsMode: boolean,
  tourTitle: string | null
  destinations: Destination[]
  isEditingComplete: boolean
  isFileUploaded: boolean  // whether the selected area of the photo is uploaded to bucket.
  isSaved: boolean // whether the tour is added to db & received _id (reason SavingChanges gets killed on Complete Tour status)
  isQuickSaved: boolean // quick save while features are added.
  errorSummary: string[] //missing photos, activity titles. to show on SummaryEditor -> ErrorSummary
  price: Prices
  isNewTour: boolean
  facilityEditorID: string | null //ID of current editing facility. to open facility editor for matching facility type
  facilityInEditor: IFacility | null// active facility in FacilityItemEditor. to update / delete existing facility
  published: boolean
  adminReview: IAdminReview | null
  reviewStatus: ReviewStatus | null
}

export interface SetActiveEditor {
  type: typeof ACTION_TYPES.SET_EDITOR
  editorType: typeof ACTION_TYPES.AIR_TRAVEL_EDITOR | typeof ACTION_TYPES.PHOTO_EDITOR |
    typeof ACTION_TYPES.SUMMARY_EDITOR | typeof ACTION_TYPES.NO_EDITOR | typeof ACTION_TYPES.REVIEW_EDITOR |
    typeof ACTION_TYPES.REVIEW_VIEWER
}

export interface SetPhotoEditor {
  type: typeof ACTION_TYPES.SET_PHOTO_EDITOR
  editor: PhotoEditor | null
}

export interface SetItemPhoto {
  type: typeof ACTION_TYPES.SET_ITEM_PHOTO
  payload: PhotoEditor
}

export interface AddDay {
  type: typeof ACTION_TYPES.ADD_DAY
  dayNum?: number
  location?: MoveLocation
}

export interface AddActivity {
  type: typeof ACTION_TYPES.ADD_ACTIVITY
  day: IDay,
  activityNum?: number,
  location?: MoveLocation
}

export interface CloseEditor {
  type: typeof ACTION_TYPES.CLOSE_EDITOR
}

export interface RemoveItem {
  type: typeof ACTION_TYPES.REMOVE_ITEM
  payload: PhotoEditor
}

export interface SetTitle {
  type: typeof ACTION_TYPES.SET_TITLE
  title: string | null
}

export interface SetEditingComplete {
  type: typeof ACTION_TYPES.SET_COMPLETE
  isComplete: boolean
}

export interface SetFileUploaded {
  type: typeof ACTION_TYPES.SET_FILE_UPLOADED
  isUploaded: boolean
}

export interface SetTourID {
  type: typeof ACTION_TYPES.SET_TOUR_ID
  id: string
}

export interface SetSaved {
  type: typeof ACTION_TYPES.SET_SAVED
  isSaved: boolean
}

export interface SetQuickSaved {
  type: typeof ACTION_TYPES.SET_QUICK_SAVED
  isQuickSaved: boolean
}

export interface SetPhotoSrc {
  type: typeof ACTION_TYPES.SET_PHOTO_SRC
  src: string
}

export interface SetDestinations {
  type: typeof ACTION_TYPES.SET_DESTINATIONS
  destinations: Destination[]
}

export interface SetErrorSummary {
  type: typeof ACTION_TYPES.SET_ERRORS
  errors: string[]
}

export interface SetTourPrices {
  type: typeof ACTION_TYPES.SET_PRICES
  prices: Prices
}

export interface SetInFacilityMode {
  type: typeof ACTION_TYPES.SET_IN_FACILITY_MODE
  payload: boolean
}

export interface SetInSettingsMode {
  type: typeof ACTION_TYPES.SET_IN_SETTINGS_MODE
  payload: boolean
}

export interface SetFacilityID {
  type: typeof ACTION_TYPES.SET_FACILITY_ID
  payload: string | null
}

export interface SetFacilityInEditor {
  type: typeof ACTION_TYPES.SET_FACILITY_IN_EDITOR
  payload: IFacility
}

export interface ClearTourInEditor {
  type: typeof ACTION_TYPES.CLEAR_TOUR_IN_EDITOR
}

export interface SetTourLineToEdit {
  type: typeof ACTION_TYPES.SET_TOUR_TO_EDIT
  payload: TourLineState
}

export interface SetPublishStatus {
  type: typeof ACTION_TYPES.SET_PUBLISH_STATUS
  payload: boolean
}

export interface MoveDay {
  type: typeof ACTION_TYPES.MOVE_DAY
  dayNum: number
  location: MoveLocation
}

export interface MoveActivity {
  type: typeof ACTION_TYPES.MOVE_ACTIVITY
  activity: IActivity
  location: MoveLocation
}

export interface CloneActivity {
  type: typeof ACTION_TYPES.CLONE_ACTIVITY
  payload: IActivity
}

export interface MoveActivityToDay {
  type: typeof ACTION_TYPES.MOVE_ACTIVITY_TO_DAY
  payload: number
}

export interface CloneDay {
  type: typeof ACTION_TYPES.CLONE_DAY
  payload: IDay
}

export interface SetAdminReview {
  type: typeof ACTION_TYPES.SET_ADMIN_REVIEW
  payload: IAdminReview | null
}

export interface SetReviewStatus {
  type: typeof ACTION_TYPES.SET_REVIEW_STATUS
  payload: ReviewStatus | null
}


export type TourLineActionTypes = SetActiveEditor | SetPhotoEditor | SetItemPhoto | AddDay |
  AddActivity | CloseEditor | RemoveItem | SetTitle | SetEditingComplete | SetFileUploaded | SetTourID | SetQuickSaved |
  SetPhotoSrc | SetSaved | SetDestinations | SetErrorSummary | SetTourPrices | SetInFacilityMode | SetFacilityID |
  SetFacilityInEditor | ClearTourInEditor | SetTourLineToEdit | SetPublishStatus | MoveDay | MoveActivity |
  SetAdminReview | SetReviewStatus | CloneActivity | CloneDay | SetInSettingsMode | MoveActivityToDay









// export interface SetAirTravelPhoto {
//   type: typeof ACTION_TYPES.SET_AIR_PHOTO,
//   airTravel: IAirTravel
// }

// export interface SetDayPhoto {
//   type: typeof SET_DAY_PHOTO
//   photo: IDay
// }