import React, {useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {useQuery} from "@apollo/react-hooks";
import {ALL_TOURS} from "../../graphql/queries/TourQueries";
import {ITourLine} from "../../types/TourLineTypes";
import ViewTour from "./ViewTour";

const ListTours: React.FC = () => {

  const {loading, error, data} = useQuery(ALL_TOURS);
  const [tourID, setTourID] = useState<string | null>(null);

  const listTours = (tours: ITourLine[]) => {
    return (
      tours.map((tour: ITourLine, idx:number) => {
        return <li key={idx} onClick={() => setTourID(tour.id)}>{tour.id}</li>
      })
    );
  };
  return (
    <Row>
      <Col xs={6}>
        <ul className='tour-list'>
        {data && listTours(data.tours)}
        </ul>

      </Col>
      <Col xs={6}>
        {tourID && <ViewTour tourID={tourID} />}
      </Col>
    </Row>
  );
};

export default ListTours;