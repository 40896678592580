import React from "react"
import {Spinner, SpinnerProps} from 'react-bootstrap';

export const getLoadingSpan = () => {
  return <label className='loading-label'>
    <span className={`spinner-grow spinner-grow-sm mr-1`} role="status" />
  </label>
};

export const getLoadingSpinner = (spinnerProps?: SpinnerProps) => {
  const variantType = spinnerProps ? spinnerProps.variant : 'primary';
  const animationType = spinnerProps ? spinnerProps.animation : 'border';
  return <label className='loading-label'>
    <Spinner
      className={`mr-2`}
             variant={variantType}
          animation={animationType}
          role="status" />
  </label>
};