import React, {useEffect, useRef, useState} from 'react';
import {withPopup} from "../common/Popup";
import DateSearch from "./DateSearch";
import {Form, InputGroup} from "react-bootstrap";
import {ISearch} from "../../types/clientStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {formatDate} from "../../utils/formatters";
import {setTSM} from "../../store/actions/clientActions";
import {useOutsideClickNotifier} from "../common/OutsideClickNotifier";

const InputDate: React.FC = () => {
  const search: ISearch = useSelector((state: AppState) => state.client.search);
  const dispatch = useDispatch();

  const [isDatePopupOn, setIsDatePopupOn] = useState(false);
  const selectedDate: Date | null = search.departure;
  const DateSearchPopup = withPopup(DateSearch, null);

  const wrapperRef = useRef(null);
  const [resetOutsideClick, setResetOutsideClick] = useState(false);
  const isClickOutside = useOutsideClickNotifier(wrapperRef, resetOutsideClick);

  let departureDate = '';

  if (selectedDate) {
    departureDate = formatDate(selectedDate, 'MMMM DD, YYYY');
  }

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      setIsDatePopupOn(false);
    }
  };

  const dateRef = useRef<Date | null>();

  useEffect(() => {
    if (!isDatePopupOn) {
      return;
    }
    setResetOutsideClick(false);
  }, [isDatePopupOn]);

  useEffect(() => {
    if (!isClickOutside) {
      return;
    }
    setIsDatePopupOn(false);
    setResetOutsideClick(true);
  }, [isClickOutside]);

  useEffect(() => {
    if (selectedDate !== dateRef.current) {
      setTimeout(() => {
        setIsDatePopupOn(false);
      }, 300);
    }

    dateRef.current = selectedDate;

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  });

  useEffect(() => {
    const today = new Date();
    const offset = today.getTimezoneOffset();
    dispatch(setTSM(-offset));
  }, []);

  return (
    <Form.Group className='search-picker-input'
                controlId="th-search-date"
                onClick={() => setIsDatePopupOn(true)}
                ref={wrapperRef}>
      <InputGroup className="th-search-input-group">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <i className="feather icon-calendar"/>
          </InputGroup.Text>
        </InputGroup.Prepend>

        <Form.Control
          type="text"
          placeholder="Date"
          className="input-icon"
          readOnly={true}
          value={departureDate}
          // onBlur={() => setIsDatePopupOn(true)}
        />
      </InputGroup>

      {isDatePopupOn && <DateSearchPopup/>}

    </Form.Group>
  );
};

export default InputDate;