import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {closeEditor} from "../../../store/actions/tourlineActions";
import {AppState} from "../../../store/reducers";
import {PhotoEditor} from "../../../types/storeTypes";
import {
  AIR_TRAVEL_EDITOR,
  PHOTO_TYPE_AIR_INFO,
  REVIEW_EDITOR,
  REVIEW_VIEWER,
  SUMMARY_EDITOR
} from "../../../constants/actions";

const EditorClose: React.FC = () => {
  const dispatch = useDispatch();
  const photoEditor: PhotoEditor | null = useSelector((state: AppState) => state.tour.photoEditor);
  const activeEditor: string | null = useSelector((state: AppState) => state.tour.activeEditor);
  const inFacilityMode: boolean = useSelector((state: AppState) => state.tour.inFacilityMode);

  const closeEditorOnClick = () => {
    if (activeEditor === AIR_TRAVEL_EDITOR || activeEditor === REVIEW_EDITOR
      || activeEditor === SUMMARY_EDITOR || activeEditor === REVIEW_VIEWER) {
      dispatch(closeEditor());
    }

    if(!photoEditor) {return;}

    if (photoEditor.photoEditorType === PHOTO_TYPE_AIR_INFO || photoEditor.src !== undefined  || inFacilityMode) {
      dispatch(closeEditor());
    } else {
      // eslint-disable-next-line no-restricted-globals
      const b = confirm('Photo not saved. Close anyway?');
      if(b){
        dispatch(closeEditor());
      }
    }
  };

  return (
    <i className='feather icon-x editor-close' onClick={() => closeEditorOnClick()}/>
  )
};

{/*<label className='editor-close'>x</label>*/}


export default EditorClose;