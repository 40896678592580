import React from 'react';
import {Col, Row} from "react-bootstrap";
import MoveActivityToAnotherDay from "./Settings/MoveActivityToAnotherDay";

const ActivitySettings: React.FC = () => {
  return (
    <Row>
      <Col>
        <MoveActivityToAnotherDay/>
      </Col>
    </Row>
  )
};

export default ActivitySettings;