import React from 'react';
import {Dropdown} from "react-bootstrap";
import DEMO from "../../../../../store/constant";
import {PHOTO_TYPE_ACTIVITY} from "../../../../../constants/actions";
import {
  addActivity,
  cloneActivity,
  moveActivity,
  removeItem,
  setInSettingsMode
} from "../../../../../store/actions/tourlineActions";
import {IActivity, IDay, MoveLocation, PhotoEditor} from "../../../../../types/storeTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";

type ActivityOptionsProps = {
  photoEditor: PhotoEditor
}

const ActivityOptions: React.FC<ActivityOptionsProps> = (props) => {
  const {photoEditor} = props;
  const days = useSelector((state: AppState) => state.tour.days);
  const dispatch = useDispatch();

  const activity: IActivity = photoEditor.editor as IActivity;
  const isMoveUpDisabled = activity.number === 1;
  const day: IDay | undefined = days.find((day: IDay) => day.number === activity.dayNumber);
  const isMoveDownDisabled: boolean = day !== undefined && day.activities !== null &&
    activity.number === day.activities.length;

  const removeActivityFromTour = () => {
    if (photoEditor.photoEditorType === PHOTO_TYPE_ACTIVITY) {
      const activity = photoEditor.editor as IActivity;
      const day = days[activity.dayNumber - 1];
      if (day.activities && day.activities.length > 1) {
        dispatch(removeItem(photoEditor));
      } else {
        alert('Each day must have at least an activity.');
      }
    }
  };

  const addActivityAboveOrBelow = (location: MoveLocation) => {
    const activity: IActivity = photoEditor.editor as IActivity;
    // const day: IDay | undefined = days.find((day: IDay) => day.number === activity.dayNumber);
    if (!day) {
      return;
    }
    dispatch(addActivity(day, activity.number, location));
  };

  const moveActivityUpOrDown = (location: MoveLocation) => {
    dispatch(moveActivity(activity, location));
  };

  const handleCloneActivity = () => {
    dispatch(cloneActivity(activity));
  };

  const handleMoveActivityToClick = () => {
    dispatch(setInSettingsMode(true));
  };


  return (
    <React.Fragment>
      <Dropdown.Item as='li' className="dropdown-item" onClick={() => addActivityAboveOrBelow(MoveLocation.ABOVE)}>
        <i className='feather icon-corner-up-left'/>
        <a href={DEMO.BLANK_LINK}> Add activity above </a>
      </Dropdown.Item>
      <Dropdown.Item as='li' className="dropdown-item" onClick={() => addActivityAboveOrBelow(MoveLocation.BELOW)}>
        <i className='feather icon-corner-down-left'/>
        <a href={DEMO.BLANK_LINK}> Add activity below </a>
      </Dropdown.Item>
      <Dropdown.Divider/>

      <Dropdown.Item as='li' className="dropdown-item" disabled={isMoveUpDisabled}
                     onClick={() => moveActivityUpOrDown(MoveLocation.ABOVE)}>
        <i className='feather icon-arrow-up'/>
        <a href={DEMO.BLANK_LINK}> Move activity up </a>
      </Dropdown.Item>
      <Dropdown.Item as='li' className="dropdown-item" disabled={isMoveDownDisabled}
                     onClick={() => moveActivityUpOrDown(MoveLocation.BELOW)}>
        <i className='feather icon-arrow-down'/>
        <a href={DEMO.BLANK_LINK}> Move activity down </a>
      </Dropdown.Item>
      <Dropdown.Divider/>

      <Dropdown.Item as='li' className="dropdown-item" onClick={() => handleCloneActivity()}
        title='Makes a copy of activity and add below this activity.'>
        <i className='feather icon-copy'/>
        <a href={DEMO.BLANK_LINK}> Clone activity </a>
      </Dropdown.Item>
      <Dropdown.Item as='li' className="dropdown-item" onClick={() => handleMoveActivityToClick()}
        title='Move activity into another day in settings tab.'>
        <i className='feather icon-move'/>
        <a href={DEMO.BLANK_LINK}> Move activity into another day </a>
      </Dropdown.Item>
      <Dropdown.Divider/>

      <Dropdown.Item as='li' className="dropdown-item" onClick={removeActivityFromTour}>
        <i className='feather icon-trash'/>
        <a href={DEMO.BLANK_LINK}> Remove </a>
      </Dropdown.Item>

    </React.Fragment>
  );
};

export default ActivityOptions;