import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {useMutation} from "@apollo/react-hooks";
import {
  ActivityInputType,
  ADD_TOUR,
  DayInputType,
  FacilityInputType,
  TEMP_SAVE
} from "../../../graphql/mutations/NewTourSave";
import {setQuickSaved, setSaved, setTourId} from "../../../store/actions/tourlineActions";
import {Destination, IDay} from "../../../types/storeTypes";

const SavingChanges: React.FC = () => {
  const tour = useSelector((state: AppState) => state.tour);
  const tourID = tour.id;
  const dispatch = useDispatch();

  const [isSavedSent, setIsSavedSent] = useState(false); // is new tour added to db.
  const [isQuickSavedSent, setIsQuickSavedSent] = useState(false); // quick save request sent and awaiting response.

  // console.log('saving called ' + tourID);

  //add a new tour on first save
  const [addTour] = useMutation(ADD_TOUR, {
    onCompleted: async data => {
      // console.log(data);
      await dispatch(setTourId(data.addTour._id));
      await dispatch(setSaved(true));

      // dispatch(setQuickSaved(false));
    },
    onError: error => {
      setIsSavedSent(false);
    }
  });

  //update the tour with, updated data in tourline
  const [quickSave] = useMutation(TEMP_SAVE, {
    onCompleted: async data => {
      // console.log(data);
      await dispatch(setQuickSaved(true));
      setTimeout(() => {
        setIsQuickSavedSent(false);
      }, 1500);
    }
  });

  const addNewTour = () => {
    addTour({variables: {}});
  };

  const getPhotoKey = (url: string | null) => {
    if (url) {
      return url.split('?')[0];
    }
    return null;
  };

  const quickSaveTour = () => {
    // const tourPrices = tour.price.discountedPrice > 0 ?
    //   {original: tour.price.originalPrice, market: tour.price.market, price: tour.price.discountedPrice} : null;

    quickSave({
      variables: {
        // agent: agentID,
        tour_id: tourID,
        title: tour.tourTitle,
        airTravel: {photo: getPhotoKey(tour.airTravel.mainPhoto)},
        // countries: getDestinationArray(tour.destinations),
        destinations: tour.destinations,
        days: matchDays(tour.days),
        // price: tourPrices
      }
    });
  };

  const getDestinationArray = (destinations: Destination[] | null) => {
    const arrDestinations: string[] = [];
    if (!destinations) {
      return arrDestinations;
    }

    destinations.forEach((destinantion) => {
      arrDestinations.push(destinantion.name);
    });

    return arrDestinations;
  };

  const matchDays = (days: IDay[]) => {
    const allDays: DayInputType[] = [];

    days.forEach(day => {
      let photo: string | null = null;
      if (day.mainPhoto) {
        photo = day.mainPhoto.split('?')[0];
      }
      // if (tour.photoEditor!.src === null || tour.photoEditor!.src !== 'cdn') {
      //   photo = null;
      // }

      const allActivities: ActivityInputType[] = [];
      if (day.activities) {
        day.activities!.forEach(activity => {
          let activityPhoto: string | null = null;
          if (activity.mainPhoto) {
            activityPhoto = activity.mainPhoto.split('?')[0];
          }

          const facilities: FacilityInputType[] = [];
          if (activity.facilities) {
            activity.facilities.forEach(facility => {
              const facilityInput = {facility: facility.facilityID, icon: facility.icon,
                name: facility.name, ticket: facility.ticket};
              facilities.push(facilityInput);
            });
          }

          const activityInput: ActivityInputType = {
            dayNum: day.number,
            number: activity.number,
            photo: activityPhoto,
            src: '',
            title: activity.title,
            facilities: facilities
          };

          allActivities.push(activityInput);
        });

      }

      const dayInput: DayInputType = {
        number: day.number,
        photo: photo,
        src: 'cdn',
        activities: allActivities
      };
      allDays.push(dayInput);
    });

    return allDays;
  };


  useEffect(() => {
    // console.log('qucik save - ' + tour.isQuickSaved);
    //tour not saved, marked as new in store and save request already not sent.
    if (!tour.isSaved && tour.isNewTour && !isSavedSent) {
      setIsSavedSent(true);
      // setTimeout(() => {
      addNewTour();
      // }, 10000);

    } else if (tourID && !tour.isQuickSaved && !isQuickSavedSent) {
      // console.log('update the same');
      setIsQuickSavedSent(true);
      quickSaveTour();
    }
  }, [tour]);

  const showSavingChanges = () => {
    if (isQuickSavedSent) {
      return;
    }

    return <React.Fragment></React.Fragment>;
  };

  return (
    <React.Fragment>
      {isQuickSavedSent && <p className='saving-changes'><i className='feather icon-save'/> saving changes...</p>}
    </React.Fragment>
  );
};

export default SavingChanges;