export const SET_BOOKING_TOUR = 'SET_BOOKING_TOUR';
export const SET_PERSONAL_DETAILS = 'SET_PERSONAL_DETAILS';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_BILLING_ADDRESS_TYPE = 'SET_BILLING_ADDRESS_TYPE';
export const SET_BILLING_INFO = 'SET_BILLING_INFO';
export const SET_PAYMENT_MODE = 'SET_PAYMENT_MODE';
export const SET_CARD_INFO = 'SET_CARD_INFO';
export const SET_NEWSLETTER_PREFERENCE = 'SET_NEWSLETTER_PREFERENCE';
export const SET_AGREEMENT = 'SET_AGREEMENT';
export const SET_IS_VALIDATED = 'SET_IS_VALIDATED';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_TOTAL = 'SET_TOTAL';
export const SET_INIT_BOOKING_DATA= 'SET_INIT_BOOKING_DATA';
export const RESET_BOOKING = 'RESET_BOOKING';

export const SET_TRAVEL_DOC = 'SET_TRAVEL_DOC';
export const IS_TRAVEL_PHOTO_UPLOADING = 'IS_TRAVEL_PHOTO_UPLOADING';
export const SET_DATA_PASSPORTS = 'SET_DATA_PASSPORTS';
