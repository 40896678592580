import React, {Suspense, useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import HomePage from "./HomePage";

import "bootstrap/dist/css/bootstrap.min.css";
import "./style.scss";
import MainMenu from "./components/common/MainMenu";
import SectionFooter from "./views/layout/SectionFooter";
import routes from "../routes/route";
import Loader from "../App/layout/Loader";
import {useUrlQuery} from "../hooks/UrlQueryHook";

const ClientApp: React.FC = () => {

  const query = useUrlQuery();
  let redirect = query.get('redirect');

  /**
   * to fix css issue on logout reload home
   */
  useEffect(() => {
    if (!redirect || redirect !== 'logout') {
      return;
    }

    window.location.href = '/';
  }, [redirect]);

  const menu = routes.map((route: any, index) => {
    return (route.component) ? (
      // @ts-ignore
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        onChange={(prevState: any, nextState: any) => {
          if (nextState.location.action !== "POP") {
            // window.scrollTo(0, 0);
          }
        }}
        render={props => (
          <route.component {...props} />
        )}
      />
    ) : (null);
  });

  return (
    <React.Fragment>
      <MainMenu/>

        <Suspense fallback={<Loader/>}>
          <div className='client-container'>
            <Switch>
              {menu}

              <Route path="/">
                <HomePage/>
              </Route>
            </Switch>
          </div>

        </Suspense>

      <SectionFooter/>
    </React.Fragment>
  );
};

export default ClientApp;
