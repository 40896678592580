import Resizer from 'react-image-file-resizer';
import {ImageResizeOptions} from "../../types/ImageResize";

export const resizeFile = async (options: ImageResizeOptions) => {
  const {file, maxWidth, maxHeight, compressFormat, quality, rotation, fileName} = options;

  return new Promise<File>(resolve => {
    Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, quality, rotation,
      (uri: any) => {
        resolve(new File([uri], fileName));
      }, 'blob');
  });
};

export const convertFile = async (options: ImageResizeOptions) => {
  const {file, maxWidth, maxHeight, compressFormat, quality, rotation, fileName} = options;

  return new Promise<File>(resolve => {
    Resizer.imageFileResizer(file, maxWidth, maxHeight, compressFormat, quality, rotation,
      (uri: any) => {
        resolve(new File([uri], fileName));
      }, 'blob');
  });
};