import React from "react";
import {Container} from "react-bootstrap";
import SectionMobileAppPromo from "./components/homepage/SectionMobileAppPromo";
import SectionBlogPostList from "./components/homepage/SectionBlogPostList";
import SectionSearch from "./components/homepage/SectionSearch";
// import SectionLocationGrid from "./components/homepage/carouselOld/SectionLocationGrid";

const HomePage: React.FC = () => {
  return (
    <React.Fragment>
      <Container fluid={true}>
        <SectionSearch />
        {/*<SectionLocationGrid />*/}
        <SectionMobileAppPromo />
        <SectionBlogPostList />
      </Container>
    </React.Fragment>
  );
};

export default HomePage;
