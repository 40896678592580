import {combineReducers} from "redux";
import dashboardReducer from './dashboardReducer'
import {tourReducer} from './tourlineReducer'
import {airFareReducer} from "./airFareReducer";
import {clientReducer} from "../../Client/store/reducers/clientReducer";
import {bookingReducer} from "../../Client/store/reducers/bookingReducer";
import {dashboardDataReducer} from "./dashboardDataReducer";

export const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  tour: tourReducer,
  flight: airFareReducer,
  client: clientReducer,
  booking: bookingReducer,
  dashboardData: dashboardDataReducer
});

// export default rootReducer;
export type AppState = ReturnType<typeof rootReducer>