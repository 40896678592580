import React from 'react';
import $ from 'jquery';
import Timeline from "../view/Timeline/Timeline";
import EditableTourline from "../view/EditableTourline/EditableTourline";
import AgencyList from "../view/Agency/AgencyList";
import AgencyAdd from "../view/Agency/AgencyAdd";
import AgencyView from "../view/Agency/AgencyView";
import AgencyEdit from "../view/Agency/AgencyEdit";
import CLIENT_ROUTES from "../constants/client-routes";
import ListTours from "../view/Timeline/ListTours";
import {DASHBOARD_ROUTES} from "../constants/dashboard-routes";
import TestPayment from "../components/dashboard/payment/TestPayment";
import SampleTourline from "../view/SampleTourline";

// const globalAny:any = global;
//
// globalAny.window.jQuery = $;
// globalAny.window.$ = $;
// globalAny.global.jQuery = $;
declare global {
  interface Window { jQuery: any; $: any }
}

export interface Global {
  jQuery: any;
  window: Window;
}
declare var global: Global;

window.jQuery = $;
window.$ = $;
global.jQuery = $;

// const DashboardDefault = React.lazy(() => import('../view/Dashboard/Default'));
const DashboardBasic = React.lazy(() => import('../view/Dashboard/Basic'));
const AllTours = React.lazy(() => import('../view/Dashboard/Tours/AllTours'));
const UpdateTourLine = React.lazy(() => import('../view/EditableTourline/UpdateTour'));
const CloneTourLine = React.lazy(() => import('../view/EditableTourline/CloneTour'));
const AllBookings = React.lazy(() => import('../view/Dashboard/Bookings/AllBookings'));

const CarouselManager = React.lazy(() => import('../components/dashboard/carousel/CarouselManager'));

export const rootRoute = '/admin';

const routes = [
    { path: `${rootRoute}/tourline/create`, exact: true, name: 'Tourline Create', component: EditableTourline},
    { path: `${rootRoute}/tourline/update/:id`, exact: true, name: 'Tourline Update', component: UpdateTourLine},
    { path: `${rootRoute}/tourline/clone/:id`, exact: true, name: 'Tourline Copy', component: CloneTourLine},
    // { path: `${rootRoute}/tourline`, exact: true, name: 'Tourline', component: Timeline },
    { path: `${rootRoute}/sample-tourline`, exact: true, name: 'Sample Tourline', component: SampleTourline },
    { path: `${rootRoute}/tourline/list`, exact: true, name: 'Tours', component: ListTours },
    { path: rootRoute + CLIENT_ROUTES.AGENCY.LIST, exact: true, name: 'Agency List', component: AgencyList },
    { path: rootRoute + CLIENT_ROUTES.AGENCY.ADD, exact: true, name: 'Agency Add', component: AgencyAdd },
    { path: `${rootRoute + CLIENT_ROUTES.AGENCY.VIEW}/:id`, exact: true, name: 'Agency View', component: AgencyView },
    { path: `${rootRoute + CLIENT_ROUTES.AGENCY.EDIT}/:id`, exact: true, name: 'Agency Edit', component: AgencyEdit },

    { path: `${rootRoute + DASHBOARD_ROUTES.TOUR.ALL}`, exact: true, name: 'All Tours', component: AllTours },
    { path: `${rootRoute + DASHBOARD_ROUTES.BOOKING.ALL}`, exact: true, name: 'All Bookings', component: AllBookings },

    { path: `${rootRoute + DASHBOARD_ROUTES.SITE_CONTENT.CAROUSEL}`, exact: true, name: 'Carousel',
      component: CarouselManager },
    { path: `${rootRoute + DASHBOARD_ROUTES.SITE_CONTENT.TEST_PAYMENT}`, exact: true, name: 'Test Payment',
      component: TestPayment },

    { path: rootRoute,  name: 'Default', component: DashboardBasic }
];

const API_BASE = process.env.REACT_APP_API_URI;
export const GRAPHQL = API_BASE + '/graphql';

export const API_ROUTES = {
  BASE: API_BASE,
  LOGIN: API_BASE + "/login",
  REGISTER: API_BASE + "/register",
  FORGOT_PASSWORD: API_BASE + "/forgot-password",
  VALIDATE_PRR: API_BASE + "/validate-prr",
  VALIDATE_MIR: API_BASE + "/validate-mir",
  RESET_PASSWORD: API_BASE + "/reset-password",
  JOIN_AS_MODERATOR: API_BASE + "/join-as-moderator",
  INVITE_MODERATOR: API_BASE + "/invite-moderator",
  S3_GET_URL: API_BASE + "/generate-get-url",
  S3_PUT_URL: API_BASE + "/generate-put-url"
};


export default routes;
