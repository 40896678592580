import React from 'react';
import {Dropdown} from "react-bootstrap";

type OptionsProps = {}

const Options: React.FC<OptionsProps> = (props) => {
  return (
    <div className="editor-options">
      <Dropdown alignRight={true} className="btn-group card-option">
        <Dropdown.Toggle id="dropdown-basic" className="btn-icon">
          <i className="feather icon-more-horizontal"/>
        </Dropdown.Toggle>
        <Dropdown.Menu as='ul' className="list-unstyled card-option">
          {props.children}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Options;