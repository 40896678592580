import React from 'react';
import {Col, Dropdown} from "react-bootstrap";
import DEMO from "../../../../store/constant";
import {useDispatch} from "react-redux";
import {resetFlightEditor} from "../../../../store/actions/airFareActions";

const AirEditorOptions: React.FC = () => {
  const dispatch = useDispatch();

  const handleResetEditor = () => {
    dispatch(resetFlightEditor());
  };

  return (
    <div className="editor-options air-editor-options">
      <Dropdown alignRight={true} className="btn-group card-option">
        <Dropdown.Toggle id="dropdown-flight" className="btn-icon">
          <i className='feather icon-more-horizontal flight-option '/>
        </Dropdown.Toggle>
        <Dropdown.Menu as='ul' className="list-unstyled card-option">
          <Dropdown.Item as='li' className="dropdown-item" onClick={handleResetEditor}>
            <i className='feather icon-delete'/>
            <a href={DEMO.BLANK_LINK}> Reset Editor </a>
          </Dropdown.Item>

        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default AirEditorOptions;