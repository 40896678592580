import React, {useEffect} from 'react';
import {STORAGE_USER} from "./constants/values";

const AuthCheck: React.FC = () => {

  useEffect(() => {
    window.addEventListener("storage", checkAuthChange);

    return () => {
      window.removeEventListener("storage", (e: StorageEvent) => {});
    }
  });

  /**
   * logout from other tabs
   * @param e
   */
  const checkAuthChange = (e: StorageEvent) => {
    if (e.key === STORAGE_USER && e.oldValue !== null) {
        window.location.href = '/';
    }
  };

  return null;
};

export default AuthCheck;