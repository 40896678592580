import React from 'react';
import {Card} from "react-bootstrap";
import EditorClose from "../EditorClose";
import PhotoEditorTitle from "../PhotoEditorTitle";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";
import {PHOTO_TYPE_ACTIVITY} from "../../../../constants/actions";
import ActivityEditor from "./ActivityEditor";
import PhotoEditors from "./PhotoEditors";
import EditorOptions from "./EditorOptions";

const MainPhotoEditor: React.FC = () => {
  const photoEditor = useSelector((state: AppState) => state.tour.photoEditor);
  const editorType = photoEditor && photoEditor.photoEditorType;
  const renderEditor = () => {
    if (editorType && editorType === PHOTO_TYPE_ACTIVITY) {
      return <ActivityEditor/>
    }

    return <PhotoEditors/>
  };

  return (
    <Card className='editors'>
      {/*<Card.Header>*/}

      {/*</Card.Header>*/}
      <Card.Body className='wide-card-body'>
        {/*<Row>*/}
        {/*<Col xs={12}>*/}
        <label>
          <PhotoEditorTitle/>
        </label>
        <EditorClose/>
        <EditorOptions/>

        {renderEditor()}

        {/*</Col>*/}
        {/*</Row>*/}
      </Card.Body>
    </Card>
  );
};



export default MainPhotoEditor;