import React, {PropsWithChildren} from 'react';
import {IDestination} from "../../../../types/clientStoreTypes";
import {useDispatch} from "react-redux";
import {setDestination} from "../../../../store/actions/clientActions";
import {useRedirectToListing} from "../../../../hooks/redirects/useRedirectToListing";

type DestinationTitleProps = {
  destination: IDestination
}

const DestinationTitle: React.FC<DestinationTitleProps> = (props: PropsWithChildren<DestinationTitleProps>) => {
  const {destination} = props;

  const dispatch = useDispatch();
  const [redirectToListing, setRedirectToListing] = useRedirectToListing(false);

  const handleOnListTitleClick = () => {
    dispatch(setDestination(destination));
    setRedirectToListing(true);
  };

  return (
    <span className="list-title" onClick={handleOnListTitleClick}>
      {redirectToListing}
      <span className="list-title-link">
        {destination.name}
      </span>
    </span>
  )
};

export default DestinationTitle;