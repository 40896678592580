export const SET_AIR_PHOTO = 'SET_AIR_PHOTO';
export const SET_DAY_PHOTO = 'SET_DAY_PHOTO';
export const SET_ACTIVITY_PHOTO = 'SET_DAY_PHOTO';
export const SET_EDITOR = 'SET_EDITOR';
export const PHOTO_EDITOR = 'PHOTO_EDITOR';
export const AIR_TRAVEL_EDITOR = 'AIR_TRAVEL_EDITOR';
export const SUMMARY_EDITOR = 'SUMMARY_EDITOR';
export const NO_EDITOR = 'NO_EDITOR';
export const REVIEW_EDITOR = 'REVIEW_EDITOR';
export const REVIEW_VIEWER = 'REVIEW_VIEWER';

export const SET_PHOTO_EDITOR = 'SET_PHOTO_EDITOR';
export const PHOTO_TYPE_AIR_INFO = 'PHOTO_TYPE_AIR_INFO';
export const PHOTO_TYPE_DAY = 'PHOTO_TYPE_DAY';
export const PHOTO_TYPE_ACTIVITY = 'PHOTO_TYPE_ACTIVITY';
export const SET_ITEM_PHOTO = 'SET_ITEM_PHOTO';

export const ADD_DAY = 'ADD_DAY';
export const ADD_ACTIVITY = 'ADD_ACTIVITY';
export const CLOSE_EDITOR = 'CLOSE_EDITOR';
export const REMOVE_ITEM = 'REMOVE_ITEM';

export const SET_TITLE = 'SET_TITLE';
export const SET_COMPLETE = 'SET_COMPLETE';
export const SET_FILE_UPLOADED = 'SET_FILE_UPLOADED';

export const SET_TOUR_ID = 'SET_TOUR_ID';
export const SET_SAVED = 'SET_SAVED';
export const SET_QUICK_SAVED = 'SET_QUICK_SAVED';
export const SET_PHOTO_SRC = 'SET_PHOTO_SRC';
export const SET_DESTINATIONS = 'SET_DESTINATIONS';
export const SET_ERRORS = 'SET_ERRORS';

export const SET_PRICES = 'SET_PRICES';

export const SET_IN_FACILITY_MODE = 'SET_IN_FACILITY_MODE';
export const SET_IN_SETTINGS_MODE = 'SET_IN_SETTINGS_MODE';
export const SET_FACILITY_ID = 'SET_FACILITY_ID';
export const SET_FACILITY_IN_EDITOR = 'SET_FACILITY_IN_EDITOR';

export const CLEAR_TOUR_IN_EDITOR = 'CLEAR_TOUR_IN_EDITOR';
export const SET_TOUR_TO_EDIT = 'SET_TOUR_TO_EDIT';
export const SET_PUBLISH_STATUS = 'SET_PUBLISH_STATUS';

export const MOVE_DAY = 'MOVE_DAY';
export const MOVE_ACTIVITY = 'MOVE_ACTIVITY';
export const CLONE_ACTIVITY = 'CLONE_ACTIVITY';
export const CLONE_DAY = 'CLONE_DAY';

export const SET_ADMIN_REVIEW = 'SET_ADMIN_REVIEW';
export const SET_REVIEW_STATUS = 'SET_REVIEW_STATUS';

export const MOVE_ACTIVITY_TO_DAY = 'MOVE_ACTIVITY_TO_DAY';
