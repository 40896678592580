import {FooterDestination} from "../../Client/types/HomeTypes";

export const TopDestinations: FooterDestination[] = [
  {
    title: {id: '5e4107eaa95565251a10116a', name: 'United Arab Emirates', type: 'country', info: ''},
    items: [
      {id: '5f5206b0402ba1228838c956', name: 'Dubai', type: 'country', info: ''},
      {id: '5f5206b0402ba1228838c956', name: 'Emirates', type: 'country', info: ''},
      {id: '5f5206b0402ba1228838c956', name: 'Dubai', type: 'country', info: ''},
      {id: '5f5206b0402ba1228838c956', name: 'Emirates', type: 'country', info: ''},
      {id: '5e4107baa95565251a1010d4', name: 'Cruise tours', type: 'country', info: ''}
    ],
  },
  {
    title: {id: '5e4107e1a95565251a101151', name: 'Sri Lanka', type: 'country', info: ''},
    items: [
      {id: '5e41108d280b8227041d2d19', name: 'Sri Lanka', type: 'country', info: ''},
      {id: '5e4107e1a95565251a101151', name: 'Sigiriya', type: 'country', info: ''},
      {id: '5e41108d280b8227041d2d19', name: 'Sri Lanka', type: 'country', info: ''},
      {id: '5e4107e1a95565251a101151', name: 'Sigiriya', type: 'country', info: ''}
    ],
  },
  {
    title: {id: '5e4107dfa95565251a10114b', name: 'Singapore', type: 'country', info: ''},
    items: [
      {id: '5e44f90d783d3a1fa5fc405f', name: 'Singapore Flyer', type: 'cz', info: ''},
      {id: '5e44f9bb783d3a1fa5fc4061', name: 'Universal Studios Singapore', type: 'cz', info: ''},
      {id: '5e4107dfa95565251a10114b', name: 'Singapore', type: 'country', info: ''},
      {id: '5e4107d0a95565251a10111a', name: 'Malaysia', type: 'country', info: ''}
    ],
  },
];