import React, {useState} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import moment, {Moment} from 'moment';
import {Col} from "react-bootstrap";
import Datetime from "react-datetime";
import {ActiveDates, IFlight} from "../../../../../types/flightStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {setFlight} from "../../../../../store/actions/airFareActions";
import {AppState} from "../../../../../store/reducers";

type FlyDateProps = {
  flight: IFlight
  // date: Date
  dateType: string // arrival or departure
}

export const DATE_TYPE = {
  DEPARTURE: 'departure',
  ARRIVAL: 'arrival'
};

const FlyDateTime: React.FC<FlyDateProps> = (props) => {
  const selectedDay = props.dateType === DATE_TYPE.DEPARTURE ? props.flight.departureDateTime :
    props.flight.arrivalDateTime;

  const [selectedDateTime, setSelectedDateTime] = useState(selectedDay);

  let activeDates: ActiveDates | null = useSelector((state: AppState) => state.flight.activeDates);
  activeDates = activeDates ? activeDates : {start: new Date(), end: null};
  const minSelectableDate = activeDates.start;

  const dispatch = useDispatch();

  const handleDateChange = (day: Date) => {
    if (!day) {return}

    const newDate = new Date(day.getTime());
    newDate.setFullYear(day.getFullYear());
    newDate.setMonth(day.getMonth());
    newDate.setDate(day.getDate());

    newDate.setHours(selectedDay.getHours());
    newDate.setMinutes(selectedDay.getMinutes());

    if (props.dateType === DATE_TYPE.DEPARTURE) {
      dispatch(setFlight({...props.flight, departureDateTime: newDate}));
    } else {
      dispatch(setFlight({...props.flight, arrivalDateTime: newDate}));
    }
  };

  const handleTimeChange = async (date: string | Moment) => {

    if (typeof date === 'object') {
      const newTime = date as Moment;

      const newDate = new Date(selectedDay.getTime());
      newDate.setHours(newTime.hours());
      newDate.setMinutes(newTime.minutes());

      // await setSelectedDateTime(newDate);

      if (props.dateType === DATE_TYPE.DEPARTURE) {
        dispatch(setFlight({...props.flight, departureDateTime: newDate}));
      } else {
        dispatch(setFlight({...props.flight, arrivalDateTime: newDate}));
      }
    }
  };

  //after validating time
  const resetTime = () => {
    const selectedTime = new Date(selectedDateTime);
    selectedTime.setHours(0);
    selectedTime.setMinutes(0);
    setSelectedDateTime(selectedTime);

    alert('invalid time!')
  };

  const handleOnFocusOutTime = (date: any) => {
    console.log(date);

    if (typeof date === 'string' && date.length === 4 ) {

      const hours = parseInt(date.substring(0,2));
      const mins = parseInt(date.substring(2));

      if (!(hours >= 0 && hours < 24 && mins >= 0 && mins < 60)) {
        resetTime();
      }
    } else if (typeof date !== 'object') {
      resetTime();
    }
  };


  const formatDate = (date: Date, format: string) => {
    return moment(date).format('DD/MM/YYYY')
  };

  return (
    <React.Fragment>
      <Col md={4} className='pr-0 mb-2'>
        <DayPickerInput
          value={selectedDay}
          onDayChange={(day) => handleDateChange(day)}
          // format={'DD/MM/YY'}
          formatDate={formatDate}
          dayPickerProps={{selectedDays: selectedDay, disabledDays: {before: minSelectableDate}}}
        />
      </Col>
      <Col md={4} className='mb-2'>
        <Datetime className='date-pick' timeFormat={'HHmm'} dateFormat={false}
                  defaultValue={selectedDateTime}
                  // value={selectedDateTime}
                  inputProps={{placeholder: 'time', required: true}}
                  onBlur={handleOnFocusOutTime}
                  onChange={handleTimeChange}
        />
      </Col>
    </React.Fragment>
  );
};

export default FlyDateTime;