import React from "react";
import { Card, Button } from "react-bootstrap";
import TableCustomSearch from "../../components/tables/TableCustomSearch";
import { withApollo } from "react-apollo";

// @ts-ignore
import BootstrapTable from "react-bootstrap-table-next";
// @ts-ignore
import paginationFactory from "react-bootstrap-table2-paginator";
// @ts-ignore
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { AgencyListQuery } from "../../graphql/queries/AgencyQueries";
import { AgencyType } from "../../types/AgencyType";
import CLIENT_ROUTES from "../../constants/client-routes";
import {isSuperUser} from "../../utils/authCheck/authCheck";
import {Redirect} from 'react-router-dom';

// TODO: define interfaces to state & props
interface StateType {
  tableData: Array<AgencyType>;
  isAuthorized: boolean
}
class AgencyList extends React.Component<any, StateType> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableData: [],
      isAuthorized: true
    };
  }

  columns = [
    {
      dataField: "_id",
      text: "#",
      sort: true
    },
    {
      dataField: "name",
      text: "Name",
      sort: true
    },
    {
      dataField: "email",
      text: "Email",
      sort: true
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true
    },
    {
      dataField: "actions",
      text: "Actions"
    }
  ];

  loadTable() {
    this.props.client
      .query({
        query: AgencyListQuery,
        fetchPolicy: "network-only"
      })
      .then((results: any) => {
        console.log("results", results);
        // this.deleteComplete();
        this.setState({ tableData: results.data.agencies });
      })
      .catch((error: any) => {
        console.log("Error: ", error);
      });
  }

  deleteClick(id: string) {
    console.log("delete click", id);
  }

  viewClick(id: string) {
    this.props.history.push(CLIENT_ROUTES.AGENCY.VIEW + "/" + id);
  }

  editClick(id: string) {
    this.props.history.push(CLIENT_ROUTES.AGENCY.EDIT + "/" + id);
  }

  componentDidMount(): void {
    if (!isSuperUser()) {
        this.setState({isAuthorized: false})
    }

    this.loadTable();
  }

  render() {
    const tableData = this.state.tableData.map(
      (agency: AgencyType, index: number) => {
        let row: any = {};
        Object.assign(row, agency);
        const id = row._id;

        row.actions = (
          <React.Fragment>
            <Button
              variant="light"
              className="float-right table-action-button"
              onClick={() => this.deleteClick(id)}
              title={"Remove"}
            >
              {/*<Feather.Trash2 className="feather text-danger" size={18} />*/}
              Delete
            </Button>

            <Button
              variant="light"
              className="float-right table-action-button"
              onClick={() => this.editClick(id)}
              title={"Edit"}
            >
              {/*<Feather.Edit3 className="feather" size={18} />*/}
              Edit
            </Button>
            <Button
              variant="light"
              className="float-right table-action-button"
              onClick={() => this.viewClick(id)}
              title={"View"}
            >
              {/*<Feather.Eye className="feather" size={18} />*/}
              View
            </Button>
          </React.Fragment>
        );

        return row;
      }
    );

    if (!this.state.isAuthorized) {
      return <Redirect to='/admin' />
    }

    return (
      <Card>
        <Card.Header>
          <Card.Title as="h5">Agency List</Card.Title>
        </Card.Header>
        <Card.Body>
          <ToolkitProvider
            keyField="_id"
            data={tableData}
            columns={this.columns}
            search
          >
            {(props: any) => (
              <div>
                <TableCustomSearch {...props.searchProps} />
                <hr />
                <BootstrapTable
                  bootstrap4
                  {...props.baseProps}
                  pagination={paginationFactory()}
                />
              </div>
            )}
          </ToolkitProvider>
        </Card.Body>
      </Card>
    );
  }
}

export default withApollo(AgencyList);
