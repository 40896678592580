import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import iosIcon from "../../assets/images/apple-app.png";
import androidIcon from "../../assets/images/play-store.png";
import appImage from "../../assets/images/feedback/mobile.png";

const SectionMobileAppPromo: React.FC = () => {
  return (
    <Row className="th-mobile-app-promo">
      <Col lg={7} md={6} xs={12}>
        <p className="th-mobile-app-promo-heading pt-md-5 pt-1 mt-md-5 mb-md-5 text-center">
          Download Our App Today & Experience Endless Possibilities.
        </p>
        <Row>
          <Col xs={6} sm={{span:4, offset:2}} className="text-right">
            <a href="#" target="_blank">
              <Image src={iosIcon} alt="Download iOS App" fluid/>
            </a>
          </Col>
          <Col xs={6} sm={4} className="text-left">
            <a href="#" target="_blank">
              <Image src={androidIcon} alt="Download Android App" fluid/>
            </a>
          </Col>
        </Row>
      </Col>
      <Col lg={4} md={6} xs={12} className='pt-md-3 pt-5'>
        <Image src={appImage} alt="Mobile App Tour" fluid/>
      </Col>
    </Row>
  );
};

export default SectionMobileAppPromo;
