import * as ACTION_TYPES from "../../constants/actions/DashboardActions";
import {
  CarouselTabKeys,
  DashboardDataActionTypes,
  DashboardDataState,
  ICarouselPosition
} from "../../types/dashboardStoreTypes";

const initCarouselPositions: ICarouselPosition[] = [
  {positionNum: 1, isComplete: false, position: 'A'},
  {positionNum: 2, isComplete: false, position: 'B'},
  {positionNum: 3, isComplete: false, position: 'C'},
]

export const dashboardDataInitialState: DashboardDataState = {
  tours: null,
  bookings: null,
  carouselPositions: null,
  carouselActiveKey: CarouselTabKeys.positionA,
  carouselTileInfo: null,
  activeCarouselTileID: null
};

export function dashboardDataReducer(state = dashboardDataInitialState,
                                     action: DashboardDataActionTypes): DashboardDataState {
  switch (action.type) {
    case ACTION_TYPES.SET_TOURS: {
      return {
        ...state,
        tours: action.payload
      }
    }
    case ACTION_TYPES.SET_BOOKINGS: {
      return {
        ...state,
        bookings: action.payload
      }
    }
    case ACTION_TYPES.SET_CAROUSEL_POSITIONS: {
      return {
        ...state,
        carouselPositions: action.payload
      }
    }
    case ACTION_TYPES.SET_CAROUSEL_ACTIVE_KEY: {
      return {
        ...state,
        carouselActiveKey: action.payload
      }
    }
    case ACTION_TYPES.SET_CAROUSEL_INFO: {
      return {
        ...state,
        carouselTileInfo: action.payload
      }
    }
    case ACTION_TYPES.RESET_CAROUSEL_TILE: {
      return {
        ...state,
        carouselPositions: initCarouselPositions.slice(),
        carouselActiveKey: CarouselTabKeys.positionA,
        carouselTileInfo: null,
        activeCarouselTileID: null
      }
    }
    case ACTION_TYPES.SET_ACTIVE_CAROUSEL_ID: {
      return {
        ...state,
        activeCarouselTileID: action.payload
      }
    }

    default:
      return {
        ...state
      };
  }
}