import React from 'react';
import {Col, Row} from "react-bootstrap";

const BottomFooter: React.FC = () => {
  return (
    <Col xs={12} className="footer-section-2 pt-5">
      <Row>
        <Col xs={12} md={{span: 10, offset: 1}}>
          <div className="footer-links">
            <a href="#" className="footer-link">About ToursHarbor.com</a>
            <a href="#" className="footer-link">Frequesntly asked questions</a>
            <a href="#" className="footer-link">Careers</a>
            <a href="#" className="footer-link">Press centre</a>
            <a href="#" className="footer-link">Privacy and Cookies</a>
          </div>
        </Col>
        <Col xs={12} className="mt-4 mb-3">
          Copyright © 2020 toursharbor.com. All rights reserved.
        </Col>
      </Row>
    </Col>
  )
};

export default BottomFooter;