import React from "react";
import { Button, Image } from "react-bootstrap";
import cruiseDeal from '../../assets/images/client/cruise-deal.webp';

const HomePromoBanner: React.FC = () => {
  return (
    <div className="th-promo-banner-1">
      <div className='promo-text'>
        <p>Cruise Deals</p>
      </div>
      <Image className='home-promo-img' src={cruiseDeal} fluid />
       <Button className="th-promo-banner-1-btn">
         Read More
       </Button>
    </div>
  );
};

export default HomePromoBanner;
