import {ITourLine} from "../types/TourLineTypes";
import {getDestinationWithNTags} from "../components/dashboard/Tour/DestinationTags";
import {IRecentTour} from "../types/dashboard/moderatorTypes";
import {Airline, AirPort} from "../types/FlightTypes";

const FEATHER_PREFIX = "icon";

export const getFacilityIconClass = (icon: string) => {
  if (icon.substring(0, 4) !== FEATHER_PREFIX) { //tour icon
    return `ic ic-${icon}`;
  }
  return `feather ${icon}`;
};

export const getTourTitle = (tour: ITourLine | IRecentTour) => {
  if (tour.destinations && tour.destinations.length > 0) {
    return `[MISSING TITLE: ${getDestinationWithNTags(tour.destinations, 1)}]`;
  }

  // return '[missing title!]';
  return '[MISSING TITLE!]';
};


/**
 * remove graphql __typename recursively and return obj without __typename
 * @param obj
 */
export const removeTypeName = (obj: any) => {
  let objDataOnly;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (typeof obj[property] === "object" && !Array.isArray(obj[property])) {
        obj[property] = removeTypeName(obj[property]);
      } else if (Array.isArray(obj[property])) {
        obj[property] = removeTypeNameArray(obj[property]);
      }
      const {__typename, ...objData} = obj;

      objDataOnly = objData;
    }
  }
  return objDataOnly;
};

/**
 * remove graphql __typename from entire array
 * @param arr
 */
export const removeTypeNameArray = (arr: any[]) => {
  return arr.map(element => removeTypeName(element));
};

/**
 * return options for Airline select dropdown
 */
export const getAirlineOptions = (airlines: Airline[] | null) => {
  // {value: 'CMB', label: 'CMB'},
  if (!airlines) {
    return [{value: '', label: 'Select airline'}];
  }

  return airlines.map((airline: Airline) => {
    return {value: airline.designator, label: airline.name}
  });
};

/**
 * getAirlineNameByCode
 * @param airlines
 * @param designator
 */
export const getAirlineNameByCode = (airlines: Airline[] | null, designator: string) => {
  if (!airlines) {
    return '';
  }

  const airline: Airline | undefined = airlines.find((airline: Airline) => airline.designator === designator);

  if (!airline) {
    return '';
  }

  return airline.name;
};

/**
 * return options for Airports select dropdown
 * @param airports
 */
export const getAirportOptions = (airports: AirPort[] | null) => {
  if (!airports) {
    return [{value: '', label: 'Select airport'}];
  }

  return airports.map((airport: AirPort) => {
    return {value: airport.code, label: `${airport.city} - ${airport.country} (${airport.code})`}
  });
};

/**
 * Get Airport Name by codec
 * @param airports
 * @param code
 */
export const getAirportNameByCode = (airports: AirPort[] | null, code: string) => {
  if (!airports) {
    return '';
  }

  const airport: AirPort | undefined = airports.find((airport: AirPort) => airport.code === code);

  if (!airport) {
    return '';
  }

  return airport.name;
};

export const isValidEmail = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};