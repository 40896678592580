import React from 'react';
import Select from "react-select";
import {customDropdownStylesNoColor} from "../../../../../constants/dropdownStyles";
import {flyClasses} from "../../../../../constants/values";
import {IAirFare} from "../../../../../types/flightStoreTypes";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../../store/reducers";
import {setSeatClass} from "../../../../../store/actions/airFareActions";

const SeatClass: React.FC = () => {
  const activeFare: IAirFare | null = useSelector((state: AppState) => state.flight.activeFare);
  const dispatch = useDispatch();

  let initSeatClass = flyClasses[0];
  if (activeFare) {
    const seatClass = flyClasses.find(seatClass => seatClass.value === activeFare.seatClass);
    if (seatClass) {
      initSeatClass = seatClass;
    }
  }

  const handleOnSeatClassChange = (option: any) => {
    if (option) {
      dispatch(setSeatClass(option.value))
    }
  };

  return (
    <Select
      styles={customDropdownStylesNoColor}
      className="basic-single"
      classNamePrefix="select"
      defaultValue={initSeatClass}
      name="color"
      onChange={(option) => option && handleOnSeatClassChange(option)}
      options={flyClasses}/>
  );
};

export default SeatClass;