import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../store/reducers";
import {IAirTravel, PhotoEditor} from "../../types/storeTypes";
import {setActiveEditor, setPhotoEditor} from "../../store/actions/tourlineActions";
import {AIR_TRAVEL_EDITOR, PHOTO_EDITOR, PHOTO_TYPE_AIR_INFO} from "../../constants/actions";
import EditorActiveIcon from "./EditorActiveIcon";
import {ActiveDates, IAirFare} from "../../types/flightStoreTypes";
import AirFareInfo from "../../utils/AirFareInfo";


interface AirTravelEditorProps {
  country: string
}

const AirTravelEdit: React.FC<AirTravelEditorProps> = (props) => {

  const airTravel: IAirTravel = useSelector((state: AppState) => state.tour.airTravel);
  const photoEditor: PhotoEditor | null = useSelector((state: AppState) => state.tour.photoEditor);
  const activeFare: IAirFare | null = useSelector((state: AppState) => state.flight.activeFare);
  const activeDates:  ActiveDates | null = useSelector((state: AppState) => state.flight.activeDates);

  const [isSubTimelineVisible, setSubTimelineVisibility] = useState(false);
  const dispatch = useDispatch();

  const {country} = props;

  const setEditor = async () => {
    await dispatch(setActiveEditor(PHOTO_EDITOR));
    dispatch(setPhotoEditor({
      editor: airTravel,
      photoEditorType: PHOTO_TYPE_AIR_INFO
    }));
  };

  const showAirInfoEditor = () => {
    dispatch(setActiveEditor(AIR_TRAVEL_EDITOR));
  };


  const mainPhoto = airTravel ? airTravel.mainPhoto : null;
  let styles: any = {styles: {}};
  if (mainPhoto) {
    // console.log('main photo found: ' + mainPhoto);
    // style = {style: `{{backgroundImage: url${mainPhoto}}}`}
    styles = {style: {backgroundImage: `url(${mainPhoto})`}};
    // styles = {backgroundImage: `url(${mainPhoto})`}
  }

  

  return (
    <div className='item'>
      <div className={`expander ${isSubTimelineVisible ? 'expanded' : ''}`}
           onClick={() => setSubTimelineVisibility(!isSubTimelineVisible)}>
        <i className={`feather ${isSubTimelineVisible ? 'icon-minus' : 'icon-plus'}  color-white`}/>
      </div>

      {/*<div className='item-content air-travel air-travel-edit' style={styles? styles: {}}>*/}
      <div className='item-content air-travel air-travel-edit' {...styles} onClick={setEditor}>
        <p className='topic-label'>
          Flight Details
        </p>
      </div>

      {photoEditor && photoEditor.photoEditorType === PHOTO_TYPE_AIR_INFO &&
      <EditorActiveIcon iconClass={'main-item-refresh'}/>}

      <div className={`sub-timeline air-info-line edit ${!isSubTimelineVisible ? 'hide' : ''} `}>
        <span className='main-connector'/>

        {activeFare && <AirFareInfo activeFare={activeFare} activeDates={activeDates}/>}

        {/*{airTravel.airInfo.map((point, index) => (*/}
        {/*  <AirInfoItem title={point.info} key={index} index={index}/>*/}
        {/*))}*/}

        <i className='feather icon-plus add-air-info' title='Add airfare' onClick={() => showAirInfoEditor()}/>

      </div>

    </div>
  );

};

export default AirTravelEdit;