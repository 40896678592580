import React, {useEffect} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import AirCalendar from "./AirCalendar";
// import {FLIGHT_TYPES} from "../../../../types/FlightTypes";
import {useDispatch, useSelector} from "react-redux";
import {DAILY_DEPARTURE, FIXED_DATE} from "../../../../constants/actions/FlightActions";
import {setActiveDateType, setActiveFare, setAirlines, setAirports} from "../../../../store/actions/airFareActions";
import {AppState} from "../../../../store/reducers";
import Ticket from "./FlightData/Ticket";
import FlightList from "./FlightData/FlightList";
import WarningMessage from "../../../../utils/warning/WarningMessage";
import AirEditorOptions from "./AirEditorOptions";
import {useAirlines, useAirports} from "../../../data/AirlineHook";
import {Airline, AirPort} from "../../../../types/FlightTypes";
import {removeTypeNameArray} from "../../../../utils/common";

const FlightDataEditor: React.FC = () => {


  // const [flightType, setFlightType] = useState(FLIGHT_TYPES.FIXED);
  const flightData = useSelector((state: AppState) => state.flight);
  const tourID : string | null = useSelector((state: AppState) => state.tour.id);

  const activeDateType = flightData.activeDateType;
  const dispatch = useDispatch();

  const airlines: Airline[] | null = useAirlines(); //data hook
  const airports: AirPort[] | null = useAirports(); //data hook

  useEffect(() => {
    if (airlines && !flightData.airlines) {
      dispatch(setAirlines(removeTypeNameArray(airlines)));
    }
  }, [airlines]);

  useEffect(() => {
    if (airports) {
      dispatch(setAirports(removeTypeNameArray(airports)));
    }
  }, [airports]);

  const setFlightType = (activeDateType: typeof FIXED_DATE | typeof DAILY_DEPARTURE) => {
    dispatch(setActiveDateType(activeDateType));
  };


  const addNewFare = () => {
    if(flightData.activeDates ) {
      if (!flightData.activeFare || flightData.activeFare.fareID === null) {
        dispatch(setActiveFare(null));
      } else {
        alert('please save / remove current fare');
      }
    } else {
      alert('please select date(s) to add fare');
    }
  };

  if (!tourID) {
    return (
      <Row>
        <Col>
          <WarningMessage message={'To continue, upload a photo to \"Flight Details\" '}/>
        </Col>
      </Row>
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col className='tour-theme-elements radio-sm pt-2'>
          {/*<hr className='flight-hr'/>*/}

          <Form.Group className="d-inline">
            <div className="radio d-inline radio-primary">
              <Form.Control type="radio" name="flight-type" id="flight-in-1" defaultChecked={activeDateType === FIXED_DATE}
                            value={FIXED_DATE}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFlightType(FIXED_DATE)}/>
              <Form.Label htmlFor="flight-in-1" className="cr">Fixed dates</Form.Label>
            </div>
          </Form.Group>
          <Form.Group className="d-inline">
            <div className="radio d-inline radio-primary">
              <Form.Control type="radio" name="flight-type" id="flight-in-2" defaultChecked={activeDateType === DAILY_DEPARTURE}
                            value={DAILY_DEPARTURE}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFlightType(DAILY_DEPARTURE)}/>
              <Form.Label htmlFor="flight-in-2" className="cr">Daily departure (range)</Form.Label>
            </div>
          </Form.Group>
        </Col>
        <Col className='flight-options'>
          {/*<i className='feather icon-more-horizontal flight-option'/>*/}
          <AirEditorOptions/>
          <i className='feather icon-plus add-flight' onClick={addNewFare}/>
        </Col>
      </Row>

      <Row className='tour-theme-elements fly-dates pt-1'>
        <Col md={5}>
          {tourID && <AirCalendar tourID={tourID}/>}
        </Col>
        <Col md={7}>
          {tourID && !flightData.activeFare && <FlightList tourID={tourID}/>}

          <Row className='mt-3'/>

          {tourID && flightData.activeFare && <Ticket tourID={tourID}/>}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default FlightDataEditor;