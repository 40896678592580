import gql from "graphql-tag";
import {
  AirTravelFragment,
  FullTourFragment,
  TourDealFragment,
  TourFragment
} from "../../Client/graphql/fragments/TourFragment";

export const ALL_TOURS = gql`
  query allTours {
    tours{
      id:_id
      title
      airTravel{
        photo
      }
      days{
        number
        photo
        activities{
          number
          title
          photo
        }
      }
    }
  }
`;


export const LIST_TOURS = gql`
  query allTours {
    tours{
      id:_id
      title
      destinations {
        name
      }
      days{
        number
      }
      editors {
        updatedAt
        bookmark
        user {
          username
        }
      }
      published
      reviewStatus
      updatedAt
      createdAt
    }
  }
`;

export const GET_TOUR = gql`
  query Tour ($id: String!){
    tour(id: $id){
      _id
      title
      price{
        original
        market
        price
      }
      airTravel{
        photo
        airFare{
          fareID:_id
          availableType: activeDateType
          availableStartDate
          availableEndDate
          seatClass
          flights {
            orderNumber
            departurePort
            departureDateTime
            arrivalPort
            arrivalDateTime
            carrier
          }
          price {
            adult
            kid:master
          }
        }
      }
      days{
        number
        photo
        activities{
          number
          title
          photo
          facilities {
            facility
            icon
            name
            ticket
          }
        }
      }
    }
  }
`;

export const GET_TOUR_DEALS = gql`
  ${FullTourFragment}
  ${TourDealFragment}
  
  query getAllDeals($id: String, $aot: String, $departure: String, $months: Int){
    tourDeal(id: $id, aot: $aot departure: $departure, months: $months) {
      tour {
        ...FullTourFields
      }
      deals {
        ...TourDealFields
      }
      aot
    }
  }
`;

export const GET_TOUR_TO_EDIT = gql`
  ${FullTourFragment}
  
  query getAllDeals($id: String!){
    tourToEdit(id: $id) {
      ...FullTourFields
      published
    }
  }
`;

export const GET_TOUR_APPROVAL_STATUS = gql`
  query getTourApprovalStatus($id: String!) {
    getTourApprovalStatus(id: $id){
      id:_id
      published
      reviewStatus
    }
  }
`;

export const GET_SAMPLE_TOUR = gql`
  ${FullTourFragment}

  query getSampleTour {
    sampleTour {
      ...FullTourFields
    }
  }
`;

export const SIMILAR_TOURS = gql`
  ${TourFragment}
  ${AirTravelFragment}

  
 query similarTours($aot: String!, $query: SearchInput!) {
  similarTours(aot: $aot, query: $query) {
    ...TourFields
    ...AirTravelFields
  }
}
`;
