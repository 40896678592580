import React from 'react';
import {Form, InputGroup} from "react-bootstrap";
import {IAirFare} from "../../../../../types/flightStoreTypes";
import {useDispatch} from "react-redux";
import {setBaggageSize} from "../../../../../store/actions/airFareActions";

type BaggageSizeProps = {
  activeFare: IAirFare
}

const BaggageSize: React.FC<BaggageSizeProps> = (props) => {
  const activeFare = props.activeFare;
  const baggageSize = activeFare.baggage ? activeFare.baggage : 0;
  const dispatch = useDispatch();

  const handleBaggageSizeChange = (baggage: string) => {
    dispatch(setBaggageSize(parseFloat(baggage)));
  };

  return (
    <InputGroup className="" size='sm'>
      <Form.Control type='number' className='baggage-input' min={0} placeholder='baggage'
                    defaultValue={baggageSize}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleBaggageSizeChange(e.target.value) }/>
      <InputGroup.Append>
        <InputGroup.Text>Kg</InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};

export default BaggageSize;