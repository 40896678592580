import React from 'react';
import {Col, Row} from "react-bootstrap";
import MobileAppDownload from "../payment/feedback/MobileAppDownload";
import MyRecentBookings from "./recent-bookings/MyRecentBookings";

const MenuMyBookings: React.FC = () => {
  return (
    <Row className='my-recent-bookings'>
      <Col xs={12} lg={8} xl={{span: 7, offset: 1}} className='app-download order-md-1 order-2'>
        <div className='mt-4 mb-4 pr-md-5 pr-3 pl-3 app-download-container'>
          <MobileAppDownload/>
        </div>
      </Col>
      <Col xs={12} lg={4} xl={4} className='order-md-2 order-0 pl-md-5 pr-md-5 pl-xl-4 pr-xl-4'>
        <Row>
          <Col xs={12} xl={{span: 8, offset: 2}} className='pl-5 pr-5 pr-md-0 pl-md-0'>
            <MyRecentBookings/>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MenuMyBookings;