import React, {PropsWithChildren} from 'react';
import {IDestination} from "../../../../types/clientStoreTypes";
import {useDispatch} from "react-redux";
import {useRedirectToListing} from "../../../../hooks/redirects/useRedirectToListing";
import {setDestination} from "../../../../store/actions/clientActions";

type DestinationItemProps = {
  destination: IDestination
}

const DestinationItem: React.FC<DestinationItemProps> = (props: PropsWithChildren<DestinationItemProps>) => {
  const {destination} = props;

  const dispatch = useDispatch();
  const [redirectToListing, setRedirectToListing] = useRedirectToListing(false);

  const handleOnListTitleClick = () => {
    dispatch(setDestination(destination));
    setRedirectToListing(true);
  };

  return (
    <li onClick={handleOnListTitleClick}>
      {redirectToListing}
      <span className="list-item-link">
        {destination.name}
      </span>
    </li>
  )
};

export default DestinationItem;