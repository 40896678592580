import React from 'react';
import {Col, Row} from "react-bootstrap";
import {ITourLine} from "../../../../types/TourLineTypes";
import Duration from "../../tourRowItems/Duration";
import TourDestinations from "../../tourRowItems/TourDestinations";
import {getDateFormatted} from "../../../../utils/CommonFunctions";
import NumberFormat from "react-number-format";
import {smallCents} from "../../../utils/uiComponents";
import {IMyBooking} from "../../../types/bookingProps";
import {getDateTime} from "../../../utils/util";

type TourSummaryProps = {
  booking: IMyBooking
  // tour: ITourLine
  // departure: Date
  // installments: number
}

const TourSummary: React.FC<TourSummaryProps> = (props) => {
  const {tour, departure, installments, total} = props.booking;

  return (
    <Row className='tour-summary'>
      <Col xs={12} className='pl-1 pr-1'>
        <p className='title text-left mt-1'>{tour.title}</p>
      </Col>

      <Col xs={8} className='pl-1 pr-0'>
        <Row>
          <Col xs={12} className='pr-0'>
            <Duration numDays={tour.days.length}/>
            <label className='departure ml-1'>
              Departure Date: {getDateFormatted(getDateTime(departure), "DD/MM/YYYY")}
            </label>
          </Col>
          <Col xs={12}>
            <TourDestinations destinations={tour.destinations}/>
          </Col>
        </Row>
      </Col>

      <Col xs={4} className='pl-1 pr-1 booking-summary-pricing'>
        <p className="th-search-result-item-pricing-installment-amount">
              <span className="th-search-result-item-pricing-installment-amount-currency">
                LKR
              </span>{" "}
          <NumberFormat value={total / installments}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={smallCents}/>
        </p>
        {installments > 1 && <p className="th-search-result-item-pricing-installment-months"> x {installments} Months
        </p>}
      </Col>



    </Row>
  );
};

export default TourSummary;