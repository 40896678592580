import * as ACTION_TYPES from "../../constants/actions/FlightActions";
import {
  ActiveDates,
  AddFlight,
  CloneActiveFare,
  IAirFare,
  IFlight, InFlightFacilities,
  ITicketPrice,
  ResetFlightEditor,
  SetActiveDates,
  SetActiveDateType,
  SetActiveFare,
  SetAirlines,
  SetAirports,
  SetBaggageSize,
  SetFlight,
  SetFlightID,
  SetInFlightFacilities,
  SetTicketClass,
  SetTicketPrice
} from "../../types/flightStoreTypes";
import {Airline, AirPort} from "../../types/FlightTypes";

/**
 * set date type Fixed | Range
 * @param dateType
 */
export function setActiveDateType (dateType: typeof ACTION_TYPES.FIXED_DATE | typeof ACTION_TYPES.DAILY_DEPARTURE)
  : SetActiveDateType {
  return {
    type: ACTION_TYPES.SET_ACTIVE_DATE_TYPE,
    payload: dateType
  }
}

/**
 * set selected date(s)
 * @param activeDates
 */
export function setActiveDates(activeDates: ActiveDates | null) : SetActiveDates {
  return {
    type: ACTION_TYPES.SET_ACTIVE_DATE,
    payload: activeDates
  }
}

/**
 * set active fare on + button or existing fare onClick
 * @param airFare
 */
export function setActiveFare (airFare: IAirFare | null): SetActiveFare {
  return {
    type: ACTION_TYPES.SET_ACTIVE_FARE,
    payload: airFare
  }
}

/**
 * adds new flight to trip
 */
export function addFlight (): AddFlight {
  return {
    type: ACTION_TYPES.ADD_FLIGHT
  }
}

/**
 * set updated flight with new input, and replace previous object
 * @param flight
 */
export function setFlight(flight: IFlight) : SetFlight {
  return {
    type: ACTION_TYPES.SET_FLIGHT,
    payload: flight
  }
}

/**
 * set ticket price of adult and kid
 * @param ticketPrice
 */
export function setTicketPrice(ticketPrice: ITicketPrice): SetTicketPrice {
  return {
    type: ACTION_TYPES.SET_TICKET_PRICE,
    payload: ticketPrice
  }
}

/**
 * set seat class of flight (Economy, Business etc.)
 * @param seatClass
 */
export function setSeatClass (seatClass: string): SetTicketClass {
  return {
    type: ACTION_TYPES.SET_TICKET_CLASS,
    payload: seatClass
  }
}

/**
 * on save add flight ID to store.
 * @param flightId
 */
export function setFlightID (flightId: string): SetFlightID {
  return {
    type: ACTION_TYPES.SET_FLIGHT_ID,
    payload: flightId
  }
}

/**
 * clone airFare to create new one quickly
 * @param airFare
 */
export function cloneActiveFare (airFare: IAirFare): CloneActiveFare {
  return {
    type: ACTION_TYPES.CLONE_ACTIVE_FARE,
    payload: airFare
  }
}

/**
 * remove active airFare from current editor and set active air fare to null
 */
export const resetFlightEditor = (): ResetFlightEditor => {
  return {
    type: ACTION_TYPES.RESET_FLIGHT_EDITOR
  }
};

/**
 * set airline list to store
 * @param airlines
 */
export const setAirlines = (airlines: Airline[]): SetAirlines => {
  return {
    type: ACTION_TYPES.SET_AIRLINES,
    payload: airlines
  }
};

/**
 * set airport list to store
 * @param airports
 */
export const setAirports = (airports: AirPort[]): SetAirports => {
  return {
    type: ACTION_TYPES.SET_AIRPORTS,
    payload: airports
  }
};

/**
 * set max baggage size
 * @param baggageSize
 */
export const setBaggageSize = (baggageSize: number): SetBaggageSize => {
  return {
    type: ACTION_TYPES.SET_BAGGAGE_SIZE,
    payload: baggageSize
  }
};

/**
 * set InFlightFacilities wifi, meals
 * @param facilities
 */
export const setInFlightFacilities = (facilities: InFlightFacilities): SetInFlightFacilities => {
  return {
    type: ACTION_TYPES.SET_IN_FLIGHT_FACILITIES,
    payload: facilities
  }
};
























