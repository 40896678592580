import React from "react";
import { Row, Col } from "react-bootstrap";
import BlogPostPreview, { IBlogPostPreviewTypes } from "./BlogPostPreview";
import blogImage from "../../assets/images/pix/dubai.jpg";
import blogImage2 from "../../assets/images/pix/dubai-2.jpg";
import blogImage3 from "../../assets/images/pix/cruise.jpg";

const SectionBlogPostList: React.FC = () => {
  const posts: IBlogPostPreviewTypes[] = [
    {
      imgSrc: blogImage,
      imgAlt: "Dubai Travel Guide",
      postTitle: "Dubai Travel Guide: Everything You Need to Know!",
      postBody:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      postUrl: "#"
    },
    {
      imgSrc: blogImage2,
      imgAlt: "Dubai Travel Guide",
      postTitle: "Dubai Travel Guide: Everything You Need to Know!",
      postBody:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      postUrl: "#"
    },
    {
      imgSrc: blogImage3,
      imgAlt: "Dubai Travel Guide",
      postTitle: "Best cruises in 2020: Everything You Need to Know!",
      postBody:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      postUrl: "#"
    },
    {
      imgSrc: blogImage,
      imgAlt: "Dubai Travel Guide",
      postTitle: "Dubai Travel Guide: Everything You Need to Know!",
      postBody:
        "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. The passage is attributed to an unknown typesetter in the 15th century who is thought to have scrambled parts of Cicero's De Finibus Bonorum et Malorum for use in a type specimen book.",
      postUrl: "#"
    }
  ];

  return (
    <Row className="th-blog-post-preview-list mb-5">
      <Col xl={{ span: 10, offset: 1 }} lg={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1}}>
        <h2 className="th-blog-post-preview-list-heading text-center text-capitalize">
          FROM THE BLOG
        </h2>
        <Row className="pl-3 pr-3 pl-lg-7 pr-lg-7">
          {posts.map((post: IBlogPostPreviewTypes, i: number) => {
            return (
              <Col sm={6} md={6} lg={3} xl={3} key={i}>
                <BlogPostPreview {...post} />
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default SectionBlogPostList;
