import gql from "graphql-tag";
import {FacilityFragment} from "../fragments/FacilityFragment";

export const ALL_FACILITIES = gql`
 ${FacilityFragment}
 
 query allFacilities{
    facilities{
      ...FacilityFields
    }
  }
`;

export const DESTINATION_FACILITIES = gql`
 query allDestinations{
    destinations{
      facility:_id
      name
    }
  }
`;

export const CAROUSEL_FACILITIES = gql`
 query allDestinations{
    destinations{
      facilityID:_id
      name
    }
  }
`;