import React from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
// @ts-ignore
import { ValidationForm } from "react-bootstrap4-form-validation";
// @ts-ignore
import { TextInput } from "react-bootstrap4-form-validation";
// @ts-ignore
import { SelectGroup } from "react-bootstrap4-form-validation";

// @ts-ignore
import validator from "validator";

import { AgencyType } from "../../types/AgencyType";
import { countries } from "../../constants/countries";
import { tourWithApolloClient } from "../../graphql/TourWithApolloClient";
import { AgencyAddMutation } from "../../graphql/mutations/AgencyMutations";
import {generatePIN} from "../../utils/CommonFunctions";

interface StateType extends AgencyType {
  pin?: string; // TODO: removing "?" will give a typescript error
}

interface PropType {
  client: any;
}

const initialState: StateType = {
  name: "",
  phone: "",
  email: "",
  address: "",
  country: "",
  // fax: '',
  description: "",
  remarks: "",
  pin: generatePIN(),
};

class AgencyAdd extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = initialState;
  }

  // :TODO add authorization

  handleChange = (e: any) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleSubmit = (e: any, formData: any, inputs: any) => {
    console.log("form data valid");
    // console.log("typeof formData", typeof formData); // todo: add type
    // console.log("typeof inputs", typeof inputs); // todo: add type

    e.preventDefault();
    //alert(JSON.stringify(formData, null, 2));
    // this.setState({ showModal: true });

    console.log(this.props.client);

    this.props.client
      .mutate({
        mutation: AgencyAddMutation,
        variables: {
          name: this.state.name,
          phone: [this.state.phone],
          email: this.state.email,
          address: this.state.address,
          country: this.state.country,
          description: this.state.description,
          remarks: this.state.remarks,
          pin: this.state.pin
        }
      })
      .then((results: any) => {
        console.log(results);
        if (results.data.addAgency) {
          // this.clearForm();
        } else {
          // TODO: show error
        }
      })
      .catch((error: any) => {
        console.log("Error: ", error);
      });
  };

  handleErrorSubmit = (e: any, formData: any, errorInputs: any) => {
    //console.log(errorInputs);
    console.log("form data invalid");

    // console.log("typeof e", typeof e); // todo: add type
    // console.log("typeof formData", typeof formData); // todo: add type
    // console.log("typeof inputs", typeof errorInputs); // todo: add type
  };

  render() {
    return (
      <Card>
        <Card.Header>
          <Card.Title as="h5">Agency Add</Card.Title>
        </Card.Header>
        <Card.Body>
          <ValidationForm
            onSubmit={this.handleSubmit}
            onErrorSubmit={this.handleErrorSubmit}
          >
            <Row>
              <Col md={6}>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="name">Agency Name</Form.Label>
                    <TextInput
                      name="name"
                      id="name"
                      placeholder="Agency Name"
                      required
                      value={this.state.name}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label htmlFor="phone">Phone</Form.Label>
                    <TextInput
                      name="phone"
                      id="phone"
                      placeholder="Phone"
                      required
                      value={this.state.phone}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label htmlFor="email">Email Address</Form.Label>
                    <TextInput
                      name="email"
                      id="email"
                      placeholder="Email"
                      validator={validator.isEmail}
                      errorMessage={{ validator: "Please enter a valid email" }}
                      required
                      value={this.state.email}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label htmlFor="address">Address</Form.Label>
                    <TextInput
                      name="address"
                      id="address"
                      placeholder="Address"
                      required
                      multiline
                      rows="3"
                      value={this.state.address}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label htmlFor="country">Country</Form.Label>
                    <SelectGroup
                      name="country"
                      id="country"
                      value={this.state.country}
                      required
                      errorMessage="Please select a country."
                      onChange={this.handleChange}
                    >
                      <option value="">Please select a country...</option>
                      {countries.map((country: any, i: number) => {
                        return (
                          <option value={country.key} key={i}>
                            {country.name}
                          </option>
                        );
                      })}
                    </SelectGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="description">Description</Form.Label>
                    <TextInput
                      name="description"
                      id="description"
                      placeholder="Description"
                      required
                      multiline
                      rows="3"
                      value={this.state.description}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="remarks">Remarks</Form.Label>
                    <TextInput
                      name="remarks"
                      id="remarks"
                      placeholder="Remarks"
                      multiline
                      rows="3"
                      value={this.state.remarks}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col md={1}></Col>
              <Col md={1}>
                <div className="vl"></div>
              </Col>
              <Col md={4}>
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="username">Username</Form.Label>
                    <TextInput
                      name="username"
                      id="username"
                      type="email"
                      placeholder="Username"
                      readOnly
                      required
                      value={this.state.email}
                      autoComplete="off"
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12">
                    <Form.Label htmlFor="pin">PIN</Form.Label>
                    <TextInput
                      name="pin"
                      id="pin"
                      type="text"
                      placeholder="PIN"
                      required
                      minLength="6"
                      value={this.state.pin}
                      onChange={this.handleChange}
                      autoComplete="off"
                    />
                  </Form.Group>
                </Form.Row>
              </Col>
              <Col md={12}>
                <Form.Row>
                  <Form.Group as={Col} sm={12} className="mt-3">
                    <Button type="submit">Submit</Button>
                  </Form.Group>
                </Form.Row>
              </Col>
            </Row>
          </ValidationForm>
        </Card.Body>
      </Card>
    );
  }
}

export default tourWithApolloClient(AgencyAdd);
