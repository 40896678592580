import React, {useEffect, useState} from 'react';
import {Col, FormControl, Row} from "react-bootstrap";
import Facility from "./FacilityItem";
import PriceOptions, {FACILITY_TICKET} from "./PriceOptions";
import {FacilityData} from "./AllFacilities";
import {useDispatch, useSelector} from "react-redux";
import {setFacilityID, setItemPhoto} from "../../../../store/actions/tourlineActions";
import {AppState} from "../../../../store/reducers";
import {IActivity, IFacility} from "../../../../types/storeTypes";

type FacilityItemEditorProps = {
  facility: FacilityData
  // facilityInUpdate: IFacility | null
}

const FacilityItemEditor: React.FC<FacilityItemEditorProps> = (props) => {
  const facility = props.facility;
  // const facilityInUpdate = props.facilityInUpdate;
  const dispatch = useDispatch();

  //only if facility item on tourline clicked.
  const facilityInUpdate: IFacility | null = useSelector((state: AppState) => state.tour.facilityInEditor);

  const number = facilityInUpdate ? facilityInUpdate.number : 0;
  const name = facilityInUpdate ? facilityInUpdate.name : '';
  const newFacility: IFacility = {number: number, facilityID: facility.id, icon: facility.icon, name: name,
    ticket: facility.ticket};
  const [editorFacility, setEditorFacility] = useState<IFacility>(newFacility);

  const [facilityName, setFacilityName] = useState(name);

  const photoEditor = useSelector((state: AppState) => state.tour.photoEditor);

  useEffect(() => {
    // console.log(facilityInUpdate);
    const number = facilityInUpdate ? facilityInUpdate.number : 0;
    const name = facilityInUpdate ? facilityInUpdate.name : '';
    const newFacility: IFacility = {number: number, facilityID: facility.id, icon: facility.icon, name: name,
      ticket: facility.ticket};
    setEditorFacility(newFacility);
  }, [facilityInUpdate]);

  const handleClose = () => {
    dispatch(setFacilityID(null));
  };

  useEffect(() => {
    if (!facilityInUpdate) {
      return;
    }
    setFacilityName(facilityInUpdate.name);
  }, [facilityInUpdate]);

  const handleNameChange = async (name: string) => {
    if (photoEditor && name.trim().length > 0) {
      let activity = photoEditor.editor as IActivity;
      let facilities : IFacility[] = activity.facilities ? activity.facilities.slice() : [];

      const facilityNumber = editorFacility.number === 0 ? facilities.length + 1 : editorFacility.number;

      const editNameFacility = {...editorFacility, name: name, number: facilityNumber};
      await setEditorFacility(editNameFacility);

      facilities[editNameFacility.number - 1] = editNameFacility;
      activity.facilities = facilities;
      dispatch(setItemPhoto(photoEditor));
    }
  };

  const handleEnterKeyPress = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (facilityName.length > 0){
        await handleNameChange(facilityName)
      }
    }
  };

  const handleTicketOptionChange = async (option: string) => {
    if (photoEditor) {
      let activity = photoEditor.editor as IActivity;
      let facilities : IFacility[] = activity.facilities ? activity.facilities.slice() : [];

      const ticketOption = option !== FACILITY_TICKET.NOT_APPLICABLE ? option : null;

      const editTicketFacility: IFacility = {...editorFacility, ticket: ticketOption};
      await setEditorFacility(editTicketFacility);

      facilities[editTicketFacility.number - 1] = editTicketFacility;
      activity.facilities = facilities;
      dispatch(setItemPhoto(photoEditor));
    }
  };

  const handleRemoveFacility = async () => {
    if (photoEditor) {
      let activity = photoEditor.editor as IActivity;
      let facilities : IFacility[] = activity.facilities ? activity.facilities.slice() : [];

      if (facilities.length > 0) {
        const facilityIndex = facilities.findIndex(facility => facility.number === editorFacility.number);
        facilities.splice(facilityIndex, 1);
        activity.facilities = facilities;

        handleClose();
        dispatch(setItemPhoto(photoEditor));
      }

    }
  };

  return (
    <Row className='pl-3 pr-3'>
      <Col xs={12} className='editor-window pr-0'>
        <Row className='pr-3'>
          <Col xs={12} className='window-controls mb-2 mr-2'>
            <i className='feather icon-x-circle window-close' onClick={handleClose}/>
          </Col>
        </Row>
        <Row>
          <Col xs={10} className='pr-0'>
            <Facility id={facility.id} icon={facility.icon} title={facility.name}/>
          </Col>
          <Col className='pr-1 mt-0'>
            {editorFacility.number > 0 && <i className='feather icon-trash text-danger' onClick={handleRemoveFacility}/>}
          </Col>
        </Row>
        <Row>
          <Col xs={10} className='tour-theme-elements mt-1 mb-2 pr-1'>
            {/*key is the trick to update defaultValue on same category, facilities update.*/}
            <FormControl key={editorFacility.number} type='text'

                         placeholder='info' value={facilityName}
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFacilityName(e.target.value)}
                         onBlur={(e: React.ChangeEvent<HTMLInputElement>) => handleNameChange(e.target.value)}
                         onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleEnterKeyPress(e)}/>
          </Col>
          <Col xs={2} className='mt-1 mb-2 pl-0 text-left'>
            <PriceOptions setTicketOption={handleTicketOptionChange}/>
          </Col>
        </Row>
      </Col>

    </Row>
  );
};

export default FacilityItemEditor;