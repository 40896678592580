import gql from "graphql-tag";

export const TravelDocFragment = gql`
  fragment TravelDocFields on TravelDoc {
   _id
    imagePassports
     dataPassports{
      fullName
      passportNumber
    }
  }   
`;