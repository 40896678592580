import {IActivity, IDay, MoveLocation, TourLineState} from "../../types/storeTypes";
import {sampleActivity} from "../reducers/tourlineReducer";

const reassignDayNumbers = (days: IDay[]) => {
  days.forEach((day: IDay, index: number) => {
    day.number = index + 1;
    const activities: IActivity[] | null = day.activities && day.activities.slice();

    day.activities = activities && activities.map((activity: IActivity, activityIdx: number ) => ({ ...activity,
      dayNumber: day.number}));
  });

  return days;
};

const reassignActivityNumbers = (activities: IActivity[]) => {
  return activities.map((activity: IActivity, index: number) => ({...activity, number: index + 1}));
};

export const addNewDay = (days: IDay[], dayNum?: number, location?: MoveLocation) => {
  //above means take selected day's place and it's number
  const dayNumber = dayNum && location ? (location === MoveLocation.ABOVE ? dayNum : dayNum + 1) : days.length + 1;

  const newDayActivity: IActivity = {...sampleActivity, dayNumber: dayNumber};
  const newDay: IDay = {number: dayNumber, activities: [newDayActivity], mainPhoto: null};
  days.splice(dayNumber - 1, 0, newDay);

  return reassignDayNumbers(days.slice());
};

export const moveSelectedDay = (days: IDay[], dayNum: number, location: MoveLocation) => {
  //ABOVE is up, BELOW is down
  const dayNumber = location === MoveLocation.ABOVE ? (dayNum > 1 ? dayNum - 1 : dayNum) :
    (dayNum !== days.length ? dayNum + 1 : dayNum);
  days.splice(dayNumber - 1, 0, days.splice(dayNum - 1, 1)[0]);
  return reassignDayNumbers(days.slice());
};

export const addNewActivity = (days: IDay[], newActivityDay: IDay, activityNum?: number, location?: MoveLocation) => {
  let newActivityNumber = newActivityDay.activities ? newActivityDay.activities.length + 1: 0;
  if (activityNum && location) {
    newActivityNumber = location === MoveLocation.ABOVE ? activityNum : activityNum + 1;
  }

  const newActivity: IActivity = {...sampleActivity, dayNumber: newActivityDay.number, number: newActivityNumber};
  const activities = newActivityDay.activities ? newActivityDay.activities.slice() : [];

  activities.splice(newActivityNumber - 1, 0, newActivity);
  newActivityDay.activities = reassignActivityNumbers(activities);

  const daysWithNewActivity = days.slice();
  daysWithNewActivity[newActivityDay.number - 1] = newActivityDay;
  return daysWithNewActivity;
};

export const moveSelectedActivity = (state: TourLineState, activity: IActivity, location: MoveLocation) => {
  let day: IDay | undefined = state.days.find((day: IDay) => day.number === activity.dayNumber);
  day = Object.assign({}, day);

  if (!day || !day.activities) {
    return state;
  }

  //ABOVE is up, BELOW is down
  const activityNumber = activity.number; // previous num
  const activities = day.activities.slice();

  // activityNum new number
  const activityNum = location === MoveLocation.ABOVE ? (activityNumber > 1 ? activityNumber - 1 : activityNumber) :
    (activityNumber !== activities.length ? activityNumber + 1 : activityNumber);

  activities.splice(activityNum - 1, 0, activities.splice(activityNumber - 1, 1)[0]);
  day.activities = reassignActivityNumbers(activities.slice());

  const daysWithUpdatedActivity = state.days.slice();
  daysWithUpdatedActivity[activity.dayNumber - 1] = day;
  state.days = daysWithUpdatedActivity;
  state.isQuickSaved = false;

  state.photoEditor = state.photoEditor ? {...state.photoEditor, editor: day.activities[activityNum - 1]} : null;
  return state;
};

/**
 * Makes a copy of activity and add below this activity.
 * @param state
 * @param activityToClone
 */
export const cloneActivityInDay = (state: TourLineState, activityToClone: IActivity): TourLineState => {
  let day: IDay | undefined = state.days.find((day: IDay) => day.number === activityToClone.dayNumber);
  const allDays: IDay[] = state.days.slice();

  if (!day) {
    return state;
  }

  day = Object.assign({}, day);

  if (!day.activities) {
    return state;
  }

  const clonedActivity: IActivity = Object.assign({}, activityToClone);
  let allActivities: IActivity[] = day.activities.slice();
  allActivities.splice(activityToClone.number, 0, clonedActivity);

  day.activities = reassignActivityNumbers(allActivities);

  allDays.splice(activityToClone.dayNumber - 1, 1, day);
  state.days = allDays;
  state.isQuickSaved = false;

  return closeEditors(state);
}

/**
 * close editors and returns state
 * @param state
 */
const closeEditors = (state: TourLineState): TourLineState => {
  state.photoEditor = null;
  state.activeEditor = null;
  return state;
}

/**
 * Makes a copy of the day with it's activities and add below this day.
 * @param state
 * @param dayToClone
 */
export const cloneDayInTourline = (state: TourLineState, dayToClone: IDay): TourLineState => {
  const clonedDay: IDay = Object.assign({}, dayToClone);
  const allDays: IDay[] = state.days.slice();

  clonedDay.activities = dayToClone.activities && dayToClone.activities.slice();
  allDays.splice(dayToClone.number, 0, clonedDay);
  state.days = reassignDayNumbers(allDays);

  state.isQuickSaved = false;
  return closeEditors(state);
}

export const moveActivityToAnotherDay = (state: TourLineState, toDayIndex: number) => {
  if (!state.photoEditor) {
    return state;
  }

  const allDays = state.days.slice();

  const activity: IActivity = state.photoEditor.editor as IActivity
  let previousDay: IDay | undefined = allDays.find((day: IDay) => day.number === activity.dayNumber);
  let moveToDay: IDay | undefined = allDays.find((day: IDay) => day.number === toDayIndex + 1);
  previousDay = Object.assign({}, previousDay);
  moveToDay = Object.assign({}, moveToDay);

  if (!moveToDay || !previousDay || !previousDay.activities) {
    return state;
  }

  previousDay.activities.splice(activity.number - 1, 1);
  previousDay.activities = reassignActivityNumbers(previousDay.activities.slice());

  const moveToDayActivities: IActivity[] = moveToDay.activities ? moveToDay.activities.slice() : [];
  activity.number = moveToDayActivities.length + 1;
  activity.dayNumber = moveToDay.number;
  moveToDayActivities.push(activity);
  moveToDay.activities = moveToDayActivities;

  allDays.splice(previousDay.number - 1, 1, previousDay);
  allDays.splice(moveToDay.number - 1, 1, moveToDay);
  state.days = allDays;

  state.isQuickSaved = false;
  return closeEditors(state);
}