import React, {useEffect, useState} from 'react';
import DayPicker, {DateUtils, DayModifiers, Modifier} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {Modifiers} from "react-day-picker/types/common";
import {FIXED_DATE} from "../../../../constants/actions/FlightActions";
import {useDispatch, useSelector} from "react-redux";
import {ActiveDates, IAirFare} from "../../../../types/flightStoreTypes";
import {AppState} from "../../../../store/reducers";
import {setActiveDates} from "../../../../store/actions/airFareActions";
import {useQuery} from "@apollo/react-hooks";
import {ALL_AIR_TICKETS} from "../../../../graphql/mutations/AirFareMutations";

type DateRange = {
  from: Date
  to: Date
}

type AirCalendarProps = {
  tourID: string
}

const AirCalendar: React.FC<AirCalendarProps> = (props) => {
  const activeDateType = useSelector((state: AppState) => state.flight.activeDateType);
  const dispatch = useDispatch();

  // const tourID = '5e09bf2612a6112a343ec5ec';
  // const tourID : string | null = useSelector((state: AppState) => state.tour.id);
  const {tourID} = props;

  // from db fix date available
  const [selectedDays, setSelectedDays] = useState<Date[]>([]);

  //from db, date range available
  const [rangeTickets, setRangeTickets] = useState<DateRange[]>([]);

  const {loading, error, data} = useQuery(ALL_AIR_TICKETS, {
    variables: {tourID: tourID},
    skip: !tourID,
    // fetchPolicy: "network-only",
    onCompleted: async data => {
      // console.log(data);
      // if (data) {
      //   const fixedDateTickets: Date[] = [];
      //   const rangeTypeTickets: DateRange[] = [];
      //
      //   data.airTickets.forEach((ticket: IAirFare) => {
      //     if(ticket.availableType === FIXED_DATE) {
      //       fixedDateTickets.push(new Date(parseInt(ticket.availableStartDate + '')))
      //       // fixdDateTickets.push(ticket.availableStartDate);
      //     } else { //range type
      //       const ticketRange = {from: new Date(parseInt(ticket.availableStartDate + '')),
      //       to: new Date(parseInt(ticket.availableEndDate + ''))};
      //       rangeTypeTickets.push(ticketRange);
      //     }
      //   });
      //
      //
      //   setSelectedDays(fixedDateTickets);
      //   setRangeTickets(rangeTypeTickets);
      // }
    },
    onError: error => {
      alert('problem loading tickets.');
    },
  });

  useEffect(() => {
    if (data) {
      const fixedDateTickets: Date[] = [];
      const rangeTypeTickets: DateRange[] = [];

      data.airTickets.forEach((ticket: IAirFare) => {
        if(ticket.availableType === FIXED_DATE) {
          fixedDateTickets.push(new Date(parseInt(ticket.availableStartDate + '')))
          // fixdDateTickets.push(ticket.availableStartDate);
        } else { //range type DAILY_DEPARTURE
          const ticketRange = {from: new Date(parseInt(ticket.availableStartDate + '')),
            to: new Date(parseInt(ticket.availableEndDate + ''))};
          rangeTypeTickets.push(ticketRange);
        }
      });

      setSelectedDays(fixedDateTickets);
      setRangeTickets(rangeTypeTickets);
    }
  }, [data]);

  const dispatchSelectedDates = (activeDates: ActiveDates | null) => {
    dispatch(setActiveDates(activeDates));
  };


  //new fixed date
  const [newDateSelection, setNewDateSelection] = useState<Date | null>(null);

  //new date range
  const [range, setRange] = useState<DateRange | null>(null);


  const handleDayRangeClick = async (day: Date) => {
    // console.log(range);

    if (!range) { //first click on range
      await setRange({from: day, to: day});
    }

    if (range) {
      const rangeSet = DateUtils.addDayToRange(day, range);
      setRange(rangeSet);
      // console.log(rangeSet);
      dispatchSelectedDates({start: rangeSet.from, end: rangeSet.to});
    }
  };

  const handleDayClick = async (day: Date, modifiers: DayModifiers) => {
    // const selected = modifiers.selected;

    if (newDateSelection && DateUtils.isSameDay(newDateSelection, day)) {
      await setNewDateSelection(null);
      dispatchSelectedDates(null);

    } else {
      await setNewDateSelection(day);
      dispatchSelectedDates({start: day, end: null});

    }
  };

  useEffect(() => {
    // to reset other selection
    if (activeDateType === FIXED_DATE) {
      setRange(null);
    } else {
      setNewDateSelection(null);
    }
  });

  let selectedDatesByType: Modifier | Modifier[] = [];
  let modifiers: Partial<Modifiers> = {};
  let onDateClickHandler: typeof handleDayClick | typeof handleDayRangeClick = handleDayRangeClick;

  if (activeDateType === FIXED_DATE) {
    selectedDatesByType = [...selectedDays];

    modifiers = {single: selectedDays};
    if (newDateSelection) {
      selectedDatesByType.concat(newDateSelection);
      modifiers = {single: selectedDays, newSelection: newDateSelection};
    }
    onDateClickHandler = handleDayClick;
  } else { //range
    selectedDatesByType = [...rangeTickets];

    // console.log(rangeTickets);

    const rangeFromDates: Date[] = [];
    const rangeToDates: Date[] = [];

    rangeTickets.forEach(rangeTicket => {
      rangeFromDates.push(rangeTicket.from);
      rangeToDates.push(rangeTicket.to);
    });
    // modifiers = {start: [rangeB.from], end: [rangeB.to]};
    modifiers = {start: rangeFromDates, end: rangeToDates};

    if (range) {
      selectedDatesByType.push(range);
      // selectedDatesByType.push({from: range.from, to: range.to});
      // modifiers = {start: [range.from, rangeB.from], end: [range.to, rangeB.to]};
      // modifiers = {start: [range.from, ...rangeFromDates], end: [range.to, ...rangeToDates]};
      modifiers = {...modifiers, startNew: [range.from], endNew: [range.to]};
    }

    // console.log(modifiers);

    onDateClickHandler = handleDayRangeClick;
  }

  // const updateDatesFromData = () => {
  //   const fixedDateTickets: Date[] = [];
  //   const rangeTypeTickets: DateRange[] = [];
  //
  //   data.airTickets.forEach((ticket: IAirFare) => {
  //     if (ticket.availableType === FIXED_DATE) {
  //       fixedDateTickets.push(new Date(parseInt(ticket.availableStartDate + '')));
  //       // fixdDateTickets.push(ticket.availableStartDate);
  //     } else { //range type
  //       const ticketRange = {
  //         from: new Date(parseInt(ticket.availableStartDate + '')),
  //         to: new Date(parseInt(ticket.availableEndDate + ''))
  //       };
  //       rangeTypeTickets.push(ticketRange);
  //     }
  //   });
  //
  //   setSelectedDays(fixedDateTickets);
  //   setRangeTickets(rangeTypeTickets);
  // };

  return (
    <React.Fragment>
      {loading && <span className="spinner-border spinner-border-sm mr-1 position-absolute" role="status"/>}

      {/*{data && updateDatesFromData()}*/}

      <DayPicker
        modifiers={modifiers}
        selectedDays={selectedDatesByType}
        className="Selectable"
        modifiersStyles={{backgroundColor: 'orange'}}
        onDayClick={onDateClickHandler}
      />
    </React.Fragment>
  );
};

export default AirCalendar;