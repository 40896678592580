import React from 'react';
import {Col, Row} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {setFacilityInEditor} from "../../store/actions/tourlineActions";
import {IFacility} from "../../types/storeTypes";
import {FACILITY_TICKET} from "./Editors/FacilityEditors/PriceOptions";
import {getFacilityIconClass} from "../../utils/common";

type FacilityProps = {
  facility: IFacility
}

const Facility: React.FC<FacilityProps> = (props) => {
  const {icon, name, ticket} = props.facility;
  const dispatch = useDispatch();

  const handleOnFacilityClick = () => {
    dispatch(setFacilityInEditor(props.facility))
  };

  return (
    <Row className='facility' onClick={handleOnFacilityClick}>
      {/*<Col xs={2} className='text-center ml-1 pr-0'>*/}
      {/*  /!*<Image src={getIcon(props.icon)} className='facility-icon'/>*!/*/}
      {/*  /!*<i className={`feather icon-${icon} facility-icon`}/>*!/*/}
      {/*  <i className={`${getFacilityIconClass(icon)} facility-icon`}/>*/}

      {/*</Col>*/}
      {/*<Col xs={9} className='pl-1 pr-0'>*/}
      {/*  <p className='title'>{name}*/}
      {/*    {ticket === FACILITY_TICKET.NOT_INCLUDED && getRemarkInfo(ticket)}*/}
      {/*  </p>*/}
      {/*</Col>*/}

      <Col xs={12}>
        <label>
          <i className={`${getFacilityIconClass(icon)} facility-icon`}/>
        </label>
        <p className='title'>{name}
          {ticket === FACILITY_TICKET.NOT_INCLUDED && getRemarkInfo(ticket)}
        </p>
      </Col>
    </Row>
  );
};

const getRemarkInfo = (ticket: string) => {
  const remarksString = <span className='remarks'> ( <i className={`feather icon-credit-card facility-icon`}/> {ticket} )
    </span>;

  return remarksString;
};

export default Facility;