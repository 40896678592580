import React from 'react';
import {Form} from "react-bootstrap";
import {IAirFare} from "../../../../../types/flightStoreTypes";
import {useDispatch} from "react-redux";
import {setInFlightFacilities} from "../../../../../store/actions/airFareActions";

type InFlightFacilitiesProps = {
  activeFare: IAirFare
}

const InFlightFacilities: React.FC<InFlightFacilitiesProps> = (props) => {
  const {wifi, meals} = props.activeFare;
  const dispatch = useDispatch();

  const handleMealsCheck = (isChecked: boolean) => {
    console.log(isChecked)
    dispatch(setInFlightFacilities({meals: isChecked, wifi: wifi}));
  };

  const handleWifiCheck = (isChecked: boolean) => {
    console.log(isChecked)
    dispatch(setInFlightFacilities({meals: meals, wifi: isChecked}));
  };

  return (
    <React.Fragment>
      <div className="checkbox d-inline">
        <Form.Control type="checkbox" name="checkbox-inline-1" id="checkbox-in-1"
                      defaultChecked={meals}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMealsCheck(e.target.checked)} />
        <Form.Label htmlFor="checkbox-in-1" className="cr">Meals</Form.Label>
      </div>
      <div className="checkbox d-inline ml-2">
        <Form.Control type="checkbox" name="checkbox-inline-1" id="checkbox-in-2"
                      defaultChecked={wifi}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWifiCheck(e.target.checked)} />
        <Form.Label htmlFor="checkbox-in-2" className="cr">Wifi</Form.Label>
      </div>
    </React.Fragment>
  );
};

export default InFlightFacilities;