import React, {useEffect, useState} from 'react';
import {FormControl, Tab, Tabs} from "react-bootstrap";
import PhotoEditors from "./PhotoEditors";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";
import {setInFacilityMode, setInSettingsMode, setItemPhoto} from "../../../../store/actions/tourlineActions";
import {IActivity} from "../../../../types/storeTypes";
import FacilityEditor from "../FacilityEditors/FacilityEditor";
import ActivitySettings from "../ActivityEditors/ActivitySettings";

// export const activityPlaceHolderText = 'Place or Activity Name';
export const activityPlaceHolderText = 'Click to change Place or Activity Name';

export const PHOTO_KEY = 'PHOTO';
export const FACILITIES_KEY = 'FACILITIES';
export const SETTINGS_KEY = 'ACTIVITY_SETTINGS';

const ActivityEditor: React.FC = () => {

  const [activityName, setActivityName] = useState(activityPlaceHolderText);
  const [isNameEditMode, setNameEditMode] = useState(false);
  const [isNameInputFocused, setNameInputFocused] = useState(false);

  const photoEditor = useSelector((state: AppState) => state.tour.photoEditor);
  const inFacilityMode = useSelector((state: AppState) => state.tour.inFacilityMode);
  const inSettingsMode = useSelector((state: AppState) => state.tour.inSettingsMode);
  const dispatch = useDispatch();

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      hideNameEdit();
    }
  };

  const handleTitleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setNameEditMode(false);

      // console.log(activityName);

      const newTitle = activityName.length > 0 ? activityName : activityPlaceHolderText;

      if (photoEditor) {
        let editorActivity = photoEditor.editor as IActivity;
        photoEditor.editor = {...editorActivity, title: newTitle};
        dispatch(setItemPhoto(photoEditor));
      }
    }
  };

  useEffect(() => {
    if (photoEditor) { //set activity name from state
      let editorActivity = photoEditor.editor as IActivity;
      const title = editorActivity.title !== '' ? editorActivity.title : activityPlaceHolderText;
      setActivityName(title);
    }
  }, [photoEditor]);

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  });

  const handleTitleParagraphClick = () => {
    setNameEditMode(true);
  };

  const hideNameEdit = () => { //click anywhere else in the container when input is visible, but not focused.
    if (!isNameInputFocused && isNameEditMode) {
      setNameEditMode(false);
    }
  };

  const handleChangeTitle = (newTitle: string) => {
    const title = newTitle.length > 0 ? newTitle : activityPlaceHolderText;
    setActivityName(title);
  };

  const changeActivityName = (newTitle: string) => {
    newTitle = newTitle.length > 0 ? newTitle : activityPlaceHolderText;
    setActivityName(newTitle);

    setNameEditMode(false);

    if (photoEditor) {
      let editorActivity = photoEditor.editor as IActivity;
      // editorActivity.title = newTitle;
      photoEditor.editor = {...editorActivity, title: newTitle};
      dispatch(setItemPhoto(photoEditor));
    }
  };

  const showActivityTab = (activeKey: string) => {
    if (activeKey === FACILITIES_KEY) {
      dispatch(setInFacilityMode(true));
      return;
    } else if (activeKey === SETTINGS_KEY) {
      dispatch(setInSettingsMode(true));
      return;
    }

    dispatch(setInFacilityMode(false));
    dispatch(setInSettingsMode(false));
  };


  return (
    <div onClick={hideNameEdit}>
      <div className={`activity-name-edit ${isNameEditMode ? 'in-edit-mode' : ''}`}>
        {!isNameEditMode &&
        <p className='activity-title' onClick={handleTitleParagraphClick}>
          {activityName}
        </p>}
        {isNameEditMode &&
        <FormControl type='text' size='sm'
                     defaultValue={activityName}
                     onBlur={(e: React.ChangeEvent<HTMLInputElement>) => changeActivityName(e.target.value)}
                     onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
                       e.target.select();
                       setNameInputFocused(true);
                     }}
                     autoFocus
                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeTitle(e.target.value)}
                     onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => handleTitleInputKeyPress(e)}
                     placeholder={'Place or Activity Name'}/>}
      </div>

      <Tabs id={'activity-pills'} variant="pills" activeKey={inFacilityMode ? FACILITIES_KEY : inSettingsMode ?
        SETTINGS_KEY : PHOTO_KEY}
            className="mb-3 activity-pills"
            onSelect={(key: string) => showActivityTab(key)}>
        <Tab eventKey={PHOTO_KEY} title={<span><i className='feather icon-camera'/> Photo</span>}>
          <PhotoEditors/>
        </Tab>
        <Tab eventKey={FACILITIES_KEY} title={<span><i className='feather icon-award'/> Facilities</span>}>
          <FacilityEditor/>
        </Tab>
        <Tab eventKey={SETTINGS_KEY} title={<span><i className='feather icon-sliders'/> Settings</span>}>
          <ActivitySettings/>
        </Tab>
      </Tabs>
    </div>
  );
};

export default ActivityEditor;