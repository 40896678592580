export const ADD_FLIGHT = 'ADD_FLIGHT';
export const SET_ACTIVE_FARE = 'SET_ACTIVE_FARE';
export const SET_FLIGHT = 'SET_FLIGHT';
export const SET_AIR_FARE = 'SET_AIR_FARE';

export const FIXED_DATE = 'FIXED_DATE';
export const DAILY_DEPARTURE = 'DAILY_DEPARTURE';

export const SET_ACTIVE_DATE_TYPE = 'SET_ACTIVE_DATE_TYPE';
export const SET_ACTIVE_DATE = 'SET_ACTIVE_DATE';
export const SET_TICKET_PRICE = 'SET_TICKET_PRICE';
export const SET_TICKET_CLASS = 'SET_TICKET_CLASS';
export const SET_FLIGHT_ID = 'SET_FLIGHT_ID';
export const CLONE_ACTIVE_FARE = 'CLONE_ACTIVE_FARE';

export const RESET_FLIGHT_EDITOR = 'RESET_FLIGHT_EDITOR';

export const SET_AIRLINES = 'SET_AIRLINES';
export const SET_AIRPORTS = 'SET_AIRPORTS';

export const SET_BAGGAGE_SIZE = 'SET_BAGGAGE_SIZE';
export const SET_IN_FLIGHT_FACILITIES = 'SET_IN_FLIGHT_FACILITIES';



