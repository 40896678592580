const CLIENT_ROUTES = {
    HOME: "/",
    AGENCY: {
        BASE: "/agency",
        LIST: "/agency/list",
        VIEW: "/agency/view",
        ADD: "/agency/add",
        EDIT: "/agency/edit"
    }
};

export default CLIENT_ROUTES;
