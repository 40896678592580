import {gql} from "apollo-boost";

export const RECENT_BOOKING_FRAGMENT = gql`
   fragment RecentBookingFields on Booking {
    _id
    orderID
    isTravelDocComplete
    tour{
      title
      days{
        photo
        number
        activities{
          title
        }
      }
      destinations{
        facility
        name
      }
    }
    total
    totalPaid
    installments
    departure
    pax{
      adults
      children
    }
    createdAt
  }
`;

export const MY_BOOKING_FRAGMENT = gql`
   fragment MyBookingFields on Booking {
    _id
    orderID
    isTravelDocComplete
    agency {
      name
    }
    tour{
      title
      days{
        photo
        number
        activities{
          title
          photo
        }
      }
      destinations{
        facility
        name
      }
    }
    total
    totalPaid
    installments
    departure
    arrival
    pax{
      adults
      children
    }
    isCancelled
    createdAt
  }
`;

export const FULL_BOOKING_FRAGMENT = gql`
   fragment FullBookingFields on Booking {
    _id
    orderID
    isTravelDocComplete
    agency {
      name
    }
    tour{
      title
      airTravel{
        photo
      }
      days{
        photo
        number
        activities{
          title
          photo
          title
          facilities{
            icon
            name
            facility
            ticket
          }
        }
      }
      destinations{
        facility
        name
      }
    }
    
    airFare{
      activeDateType
      availableStartDate
      availableEndDate
      seatClass
      flights{
        departurePort
        departureDateTime
        arrivalPort
        arrivalDateTime
        carrier
      }
    }
    total
    totalPaid
    installments
    departure
    arrival
    pax{
      adults
      children
    }
    isCancelled
    invitees
    createdAt
  }
`;
