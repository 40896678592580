export const getDateTime = (date: Date | string) => {
  if (date instanceof Date) {
    return date;
  }
  // return new Date(date);
  return new Date(parseInt(date + ''));
};

export const addDaysToDate = (date: Date, daysToAdd: number) => {
  const addDate = new Date(date);
  addDate.setDate(addDate.getDate() + daysToAdd);
  return addDate;
};

/**
 * get passed in array shuffled
 * @param a
 */
export const shuffleArray = (a: any[]) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}