import React, {useEffect, useState} from 'react';
import Facilities from "./Facilities";
import {IActivity} from "../../types/TourLineTypes";
import {retrieveFileURL} from "../../utils/TourLine";

type ActivityProps = {
  activity: IActivity
}

const Activity: React.FC<ActivityProps> = (props) => {
  const [inFacilityView, setInFacilityView] = useState(false);
  const {activity} = props;
  const [fileURL, setFileURL] = useState(null);

  let styles: any = {styles: {}};

  useEffect(() => {
    const mainPhoto = activity ? activity.photo : null;
    if(mainPhoto) {
      retrieveFileURL(mainPhoto).then(url => {
        setFileURL(url)
      });
      console.log(fileURL);
    }
  }, [activity]);

  styles = {style: {backgroundImage: `url(${fileURL})`}};

  return (
    <div className='sub-item' >
      <div className='connector'>

      </div>
      <div className={`sub-item-content ${inFacilityView ? 'facility-mode' : ''}`}
           onClick={() => {!inFacilityView && setInFacilityView(true)}} {...styles}>
        {!inFacilityView &&
        <p className='topic-label'>
          {activity.title}
        </p>}

        {inFacilityView && <Facilities facilities={activity.facilities} onClose={() => {inFacilityView && setInFacilityView(false)} }/>}
      </div>
    </div>
  )
};

export default Activity;






// class IActivity extends Component<ActivityProps> {
//
//   state = {
//     inFacilityView: false
//   };
//
//   setInFacilityView = () => {
//     if(!this.state.inFacilityView){
//       this.setState({inFacilityView: true});
//     }
//   };
//
//   setInActivityView = () => {
//     this.setState({inFacilityView: false});
//   };
//
//   render() {
//     const inFacilityView = this.state.inFacilityView;
//
//     return (
//       <div className='sub-item'>
//         <div className='connector'>
//
//         </div>
//         <div className={`sub-item-content ${inFacilityView ? 'facility-mode' : ''}`}
//              onClick={() => this.setInFacilityView()}>
//
//           {!inFacilityView && this.props.title}
//
//           {inFacilityView && <Facility onClose={() => this.setInActivityView()}/>}
//         </div>
//       </div>
//     )
//   }
// }