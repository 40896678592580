import {API_ROUTES} from "../routes/routes";
import axios from 'axios';

export const uploadImage = async (file: File | null, path?: string, bucket?: string, suffix?: string) => {
  if (!file) {
    return;
  }
  const companyId = "tourline";
  const folderPath = path ? path : companyId;
  const bucketType = bucket ? bucket: null;

  const contentType = file.type; // eg. image/jpeg or image/svg+xml
  // console.log(contentType);
  const fileNameParts = file.name.split('.');
  let filename = fileNameParts[0];
  let filenameExtension = '.' + fileNameParts[fileNameParts.length - 1];
  const fileSuffix = suffix ? '_' + suffix : '';

  const generatePutUrl = API_ROUTES.S3_PUT_URL;
  // const filename = folderPath + "/" + new Date().getTime().toString() + ".jpg";
  filename = folderPath + "/" + filename.split(' ').join('-') + '_'
    + new Date().getTime().toString() + fileSuffix + filenameExtension;
  const options = {
    params: {
      Key: filename,
      ContentType: contentType,
      bucket: bucketType
    },
    headers: {
      "Content-Type": contentType
    }
  };

  const generatePutRes = await axios.get(generatePutUrl, options);
  const {data: {putURL}} = generatePutRes;

  try {
    await axios.put(putURL, file, options);
  } catch (e) {
    throw e;
  }

  const s3FileURL = await retrieveFile(filename, bucketType);
  // console.log('retrieve file 2: ' + s3FileURL);

  return s3FileURL;
};

const retrieveFile = async (key: string, bucketType: string | null) => {
  const filename = key;
  const generateGetUrl = API_ROUTES.S3_GET_URL;
  const options = {
    params: {
      Key: filename,
      ContentType: "image/jpeg",
      bucket: bucketType
    }
  };
  const res = await axios.get(generateGetUrl, options);

  const {data: getURL} = res;
  // console.log('retrieve file 1: ' + getURL);
  return getURL;
};

