import React, {FormEvent, useEffect, useState} from "react";
import { NavLink, Redirect } from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import axios from "axios";
import { API_ROUTES } from "../../../routes/routes";
import { Button, Form } from "react-bootstrap";
import USER_ROLES from "../../../constants/roles";
import RandomBackground from "../../../utils/RandomBackground";
import {getLoadingSpan} from "../../../utils/loading/loading";
import {STORAGE_USER} from "../../../constants/values";
import {useDispatch, useSelector} from "react-redux";
import {AppState} from "../../../store/reducers";
import {setRedirect} from "../../../Client/store/actions/clientActions";
import {NETWORK_ERROR} from "../../../constants/errors/errorMessages";

type ForgotProps = {
  from?: any
}

interface ForgotState {
  email: string | null;
  isLoginError: boolean;
  isEmailSent: boolean;
  message: string | null;
  redirect: boolean;
  redirectTo: string;
  processing: boolean;
}

const Forgot: React.FC<ForgotProps> = (props: ForgotProps) => {
  const [loginState, setLoginState] = useState<ForgotState>({
    email: null,
    isEmailSent: false,
    isLoginError: false,
    message: null,
    redirect: false,
    redirectTo: "/",
    processing: false
  });

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginState({ ...loginState, email: event.target.value });
  };

  const handleForgotResponse = (data: any) => {
    if (!loginState.isEmailSent) {
        setLoginState({...loginState, isEmailSent: true});
    }
  };

  const removeLoggedUser = () => {
    localStorage.removeItem("user");
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    removeLoggedUser();

    setLoginState({
      ...loginState,
      message: "",
      isLoginError: false,
      processing: true
    });

    const auth = {
      email: loginState.email,
      login_type: "password_reset"
    };

    axios
      .post(API_ROUTES.FORGOT_PASSWORD, auth)
      .then((res: any) => {
        setLoginState({ ...loginState, processing: false });

        if (res.data) {
          setLoginState({ ...loginState, isLoginError: false });
          handleForgotResponse(res.data);
        }
      })
      .catch((err: any) => {
        setLoginState({ ...loginState, processing: false });

        const message = err.response ? err.response.data : NETWORK_ERROR;
        removeLoggedUser();

        if (!err.response) {
          setLoginState({
            ...loginState,
            message: message,
            isLoginError: true
          });
        }

        if (err.response && err.response.status === 403) {
          setLoginState({
            ...loginState,
            message: message,
            isLoginError: true
          });
        }

        if (err.response && err.response.status === 400) {
          setLoginState({
            ...loginState,
            message: "Problem occurred. Try again!",
            isLoginError: true
          });
        }
      });
  };

  const renderRedirect = () => {
    if (loginState.redirect) {
      return <Redirect to={loginState.redirectTo} />;
      //   props.history.push(loginState.redirectTo);
    }
  };

  const renderEmailSentMessage = () => {
    return (
      <p className="mt-2 mb-3 text-info font-weight-bold">
        Reset link has been sent to your email.
      </p>
    )
  };


  return (
    <Aux>
      {renderRedirect()}

      <div className="auth-wrapper aut-bg-img-side container-fluid th-no-padding align-items-stretch">
        <div className="row align-items-center w-100 align-items-stretch bg-white">
          <RandomBackground className="d-none d-lg-flex col-lg-8 aut-bg-img align-items-center d-flex justify-content-center">
            <div className="col-md-8">
              <h1 className="text-white mb-5">Travel made Affordable.</h1>
              <p className="text-white">
                Find most exiting vacation plans & relaxing tours at lowest
                rate. Best tour deals from Tours Harbor.
              </p>
            </div>
          </RandomBackground>
          <div className="col-lg-4 align-items-stret h-100 align-items-lg-center d-flex justify-content-center">
            <div className=" auth-content text-center">
              <div className="mb-4">
                <i className="feather icon-unlock auth-icon text-warning" />
              </div>
              <h3 className="mb-4">Reset Password</h3>
              <Form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="enter your email"
                    required
                    onChange={handleEmailChange}
                  />
                </div>

                <div className="error-container">
                  {loginState.isLoginError && (
                    <p className="login-error">{loginState.message}</p>
                  )}
                </div>
                <Button
                  type="submit"
                  className="btn btn-primary shadow-2 mb-4"
                  disabled={loginState.processing || loginState.isEmailSent}
                >
                  {loginState.processing && getLoadingSpan()}
                  Reset Password
                </Button>
              </Form>

              {loginState.isEmailSent && renderEmailSentMessage()}

              <p className="mb-0 text-muted">
                Log in to your account{" "}
                <NavLink to="/login">Login</NavLink>
              </p>

            </div>
          </div>
        </div>
      </div>
    </Aux>
  );

};



export default Forgot;
