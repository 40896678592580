import React from "react";
import {getFacilityIconClass} from "../../utils/common";

type SearchResultItemTagProps = {
  text: string
  iconClass?: string
  tagClass?: string
}

const SearchResultItemTag: React.FC<SearchResultItemTagProps> = (props) => {
  const {iconClass, text, tagClass} = props;
  return (
    <div className={`th-search-result-item-tag ${tagClass}`}>
      {iconClass && <i className={getFacilityIconClass(iconClass)}/>}
      {text}
    </div>
  );
};

export default SearchResultItemTag;
