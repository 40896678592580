import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import EditorClose from "../EditorClose";
import {closeEditor, setAdminReview} from "../../../../store/actions/tourlineActions";
import {useDispatch, useSelector} from "react-redux";
import {IAdminReview} from "../../../../types/storeTypes";
import {AppState} from "../../../../store/reducers";
import {showAlert} from "../../../../utils/showAlert";
import {getLoadingSpan} from "../../../../utils/loading/loading";
import {useMutation} from "@apollo/react-hooks";
import {CREATE_APPROVE_REJECT} from "../../../../graphql/mutations/TourApproval";
import {TourApprovalInput} from "../../../../types/TourLineTypes";
import {ReviewStatus} from "../../../../constants/values";

const CreateAdminReview: React.FC = () => {
  const dispatch = useDispatch();

  const [isSubmitReviewInProgress, setIsSubmitReviewInProgress] = useState(false);
  const adminReview: IAdminReview | null = useSelector((state: AppState) => state.tour.adminReview);
  const tourID: string | null = useSelector((state: AppState) => state.tour.id);

  const isApproved = adminReview ? adminReview.approved : true;

  const [createReview, {loading, data, error, called}] = useMutation(CREATE_APPROVE_REJECT);

  useEffect(() => {
    //set approval to true in store. otherwise approve is checked but @adminReview is null when
    // submit without a message.
    handleApproveRejectCheck(true);
  }, []);

  useEffect(() => {
    if (data) {
      setIsSubmitReviewInProgress(false);
    }
  }, [data]);

  const getAdminReviewObj = () => {
    if (adminReview) {
      return Object.assign({}, adminReview);
    }

    const newReview: IAdminReview = {approved: true, rejectReasons: '', comments: ''};
    return newReview;
  };

  const handleApproveRejectCheck = (isApproved: boolean) => {
    const review: IAdminReview = getAdminReviewObj();
    review.approved = isApproved;
    dispatch(setAdminReview(review));
  };

  const handleOnRejectReasonChanged = (rejectReason: string) => {
    const review: IAdminReview = getAdminReviewObj();
    review.rejectReasons = rejectReason;
    dispatch(setAdminReview(review));
  };

  const handleOnCommentsAdded = (comments: string) => {
    const review: IAdminReview = getAdminReviewObj();
    review.comments = comments;
    dispatch(setAdminReview(review));
  };


  const isValidReview = () => {
    if (!adminReview) {
      showAlert('Select approve or reject.');
      return false;
    }

    if (!adminReview.approved && adminReview.rejectReasons.length === 0) {
      showAlert('Enter reason for reject.');
      return false;
    }

    if (!tourID) {
      showAlert('Tour ID not found!');
      return false;
    }

    return true;
  };

  const handleOnSubmitReview = () => {
    if (!adminReview || !tourID) {
      return;
    }

    setIsSubmitReviewInProgress(true);

    if (!isValidReview()) {
      setIsSubmitReviewInProgress(false);
      return;
    }

    const approvalInput: TourApprovalInput = {
      tour: tourID,
      status: adminReview.approved ? ReviewStatus.APPROVED : ReviewStatus.REJECTED,
      rejectReasons: adminReview.rejectReasons.length > 0 ? adminReview.rejectReasons : undefined ,
      comments: adminReview.comments.length > 0 ? adminReview.comments : undefined
    };

    createReview({
      variables: {
        review: approvalInput
      }
    })
  };


  const getApproveRemoveRadio = () => {
    return(
      <Col xs={12} className='mt-3'>
        <Form.Check
          type='radio'
          inline
          name='admin-review-option-group'
          label='Approve'
          id='approve'
          className='form-check'
          custom
          checked={isApproved}
          onChange={() => handleApproveRejectCheck(true)}
        />
        <Form.Check
          type='radio'
          inline
          name='admin-review-option-group'
          label='Reject'
          id='reject'
          className='form-check'
          checked={!isApproved}
          custom
          onChange={() => handleApproveRejectCheck(false)}
        />
      </Col>
    )
  };

  return (
    <Card className='editors review-editor'>
      <Card.Body className='wide-card-body'>

        <EditorClose/>

        <Row>
          <Col xs={11} className='pl-0 pr-0'>
            <p className='activity-title'>
              Admin review.
            </p>
          </Col>
        </Row>

        <Row>
          {/*<Col xs={12}>*/}
          {/*  <p className='mt-3 mb-3 review-time text-right'>Requested at:*/}
          {/*    <span className='font-italic'> September 05, 2020 1703h</span>*/}
          {/*  </p>*/}
          {/*</Col>*/}

          {getApproveRemoveRadio()}

          {!isApproved && <Col xs={12} className='mt-3'>
            <Form.Group controlId="rejectReasons">
              <Form.Label className='form-label reason-title'>Reasons for Reject:</Form.Label>
              <Form.Control as="textarea" rows="2"
                            value={adminReview ? adminReview.rejectReasons : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnRejectReasonChanged(e.target.value)}/>
            </Form.Group>
          </Col>
          }

          <Col xs={12} className='mt-3'>
            <Form.Group controlId="reviewComments">
              <Form.Label className='form-label reason-title'>Comments / Improvements:</Form.Label>
              <Form.Control as="textarea" rows="2"
                            value={adminReview ? adminReview.comments : ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnCommentsAdded(e.target.value)}/>
            </Form.Group>

          </Col>

          <Col xs={12} className='text-right mt-3'>
            <Button variant='secondary' size='sm' onClick={() => dispatch(closeEditor())}>Close</Button>
            <Button variant='primary' className='font-weight-bold' size='sm'
                    disabled={isSubmitReviewInProgress || loading}
                    onClick={handleOnSubmitReview}>
              {(isSubmitReviewInProgress || loading)  && getLoadingSpan()}
              {(data && !isSubmitReviewInProgress && !loading) && <i className='feather icon-check'/>}
              Submit
            </Button>
            {error && <p className='text-right text-danger'>problem saving review!</p>}
          </Col>
        </Row>

      </Card.Body>
    </Card>

  );
};

export default CreateAdminReview;