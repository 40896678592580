import React from 'react';
import appPreview from '../../../assets/images/feedback/mobile.png';
import QRCode from '../../../assets/images/feedback/QR.png';
import playIcon from '../../../assets/images/feedback/android_download.png';
import appleIcon from '../../../assets/images/feedback/ios_download.png';
import {Col, Image, Row} from "react-bootstrap";
import {ANDROID_APP, APPLE_APP} from "../../../constants/values";
import {Link} from "react-router-dom";

const MobileAppDownload: React.FC = () => {
  return (
    <Row>
      <Col md={5} xs={12} className='mt-4 mb-4'>
        <Image src={appPreview} className='app-preview'/>
      </Col>
      <Col md={7} xs={12} className='mt-4 mb-4 pt-md-5'>
        <p className='keep-track'>Always keep track of your itinerary</p>
        <p className='download-apps'>Download our Android / iOS app, so you can have the tour timeline always with
          you.</p>
        <p className='app-guide'>It will be automatically backed-up when you connect to wifi network.<br/>
          You can also download it manually in my booking page in mobile app.<br/>
          Find mobile app user guide <span className='download-guide'>
            <a href='#'>
            here (.pdf)
            </a>
        </span>
        </p>

        <Row>
          <Col md={{span: 4, offset: 1}} xs={6} className='text-center mt-2'>
            <Row>
              <Col xs={12}>
                <Image src={QRCode} className='appQR'/>
              </Col>
              <Col xs={12}>
                <a href={ANDROID_APP} target='_blank'>
                  <Image src={playIcon} className='store-icon'/><br/>
                  <label className='app-link'>Click here</label>
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={{span: 4}} xs={6} className='text-center mt-2'>
            <Row>
              <Col xs={12}>
                <Image src={QRCode} className='appQR'/>
              </Col>
              <Col xs={12}>
                <a href={APPLE_APP} target='_blank'>
                  <Image src={appleIcon} className='store-icon'/><br/>
                  <label className='app-link'>Click here</label>
                </a>
              </Col>
            </Row>

          </Col>
        </Row>
      </Col>


    </Row>
  );
};

export default MobileAppDownload;