import gql from "graphql-tag";
import {FacilityFragment} from "../fragments/FacilityFragment";

export const GET_AIRLINES = gql`
 query listAirlines {
   airlines{
     name
     designator
   }
 }
`;

export const GET_AIRPORTS = gql`
 query listAirports {
   airports{
     name
     code
     city
     country
   }
 }
`;