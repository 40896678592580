import React from 'react';

type AirInfoProps = {
  title: string,
}

const AirInfo: React.FC<AirInfoProps> = (props) => {

  return (
    <div className='sub-item air-info-item' >
      <div className='connector'>

      </div>
      <div className={'sub-item-content air-info-content'}>
       <p>{props.title}</p>
      </div>
    </div>
  )
};

export default AirInfo;