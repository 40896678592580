import gql from "graphql-tag";

export const ADD_TOUR = gql`
  mutation createTour(
    $title: String, 
    $destinations: [DestinationTagInput]) 
    {
      addTour(
      title: $title,
      destinations: $destinations
      ) {
        _id
        title
        agency{
          name
        }
      }
    }
`;

export const TEMP_SAVE = gql`
  mutation tempSaveTour(
    $tour_id: String!,
    $title: String, 
    $airTravel: AirTravelInput,
    $destinations: [DestinationTagInput],
    $days: [DayInput]
  ) {
      tempSave(
        _id: $tour_id, 
        title: $title, 
        airTravel: $airTravel,
        destinations: $destinations, 
        days: $days
      ) {
         _id
          title
          destinations{
            facility
            name
          }
          days{
            number
            photo
            src
            activities{
              number
              photo
              src
            }
          }
          agency{
            name
          }
          published
      }
  }
`;

export const SET_PRICES_MUTATION = gql`
  mutation setPrices($id: String!, $price: PriceInput!) {
    setPrices(_id: $id, price: $price){
      _id
      price{
        original
        market
        price
      }
      published
    }
  }
`;


export type FacilityInputType = {
  facility: string
  icon: string
  name: string | null
  ticket: string | null
}

export type ActivityInputType = {
  dayNum: number
  number: number
  photo: string | null
  src: string | null
  title: string
  facilities: FacilityInputType[] | null
}

export type DayInputType = {
  number: number
  photo: string | null
  src: string | null
  activities: ActivityInputType[]
}

