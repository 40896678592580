import React, {RefObject, useEffect, useState} from 'react';
import {Col, FormControl, InputGroup, Row} from "react-bootstrap";

type FacilitySearchProps = {
  setQuery: ((query: string) => void)
}

const FacilitySearch: React.FC<FacilitySearchProps> = (props) => {
  // let searchInputRef: RefObject<FormControl<'input'>>  = React.createRef<FormControl<'input'>>();
  let searchInputRef: RefObject<any> = React.createRef<any>();
  // let sr: RefObject<any> = React.createRef<any>();

  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [focused]);

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === '/') {
      event.preventDefault();
      // console.log('handling key down');
      setFocused(true);

      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }

    }
  };

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
  });

  const handleQueryChange = (query: string) => {
    props.setQuery(query.trim());
  };

  return (
    <Row>
      <Col className='facilities-search'>
        <InputGroup className="mb-3 shadow">
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1" className={`${focused ? 'focused' : ''}`}>
              <i className='feather icon-search'/>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl className={`facility-search ${focused ? 'focused' : ''}`}
                       type="text"
                       placeholder='Search (press "/" to start search)'
                       ref={searchInputRef}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleQueryChange(e.target.value)}
                       onBlur={() => setFocused(false)}
          />
        </InputGroup>
      </Col>
    </Row>
  );
};

export default FacilitySearch;