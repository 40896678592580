import React, {useEffect, useRef} from 'react';
import {useOutsideClickNotifier} from "./OutsideClickNotifier";

type MenuOverlayProps = {
  onOutsideClick: () => void
}

const MenuOverlay: React.FC<MenuOverlayProps> = (props) => {
  const myBookingsLinkWrapperRef = useRef(null);
  const isClickOutsideMyBookings = useOutsideClickNotifier(myBookingsLinkWrapperRef, false);

  useEffect(() => {
    if (!isClickOutsideMyBookings) {
      return;
    }
    props.onOutsideClick();
  }, [isClickOutsideMyBookings]);

  return (
    <div className='menu-overlay-popup' ref={myBookingsLinkWrapperRef}>
      {props.children}
    </div>
  );
};

export default MenuOverlay;