import * as ACTION_TYPES from "../../constants/actions/FlightActions";
import {DAILY_DEPARTURE, FIXED_DATE} from "../../constants/actions/FlightActions";
import {
  ActiveDates,
  AirFareState,
  FlightActionTypes,
  IAirFare,
  IFlight,
  ITicketPrice
} from "../../types/flightStoreTypes";
import {getDateTime, getOverNightDifferenceInDays} from "../../utils/CommonFunctions";
import {removeTypeName} from "../../utils/common";

export const SampleFlight: IFlight = {
  orderNumber: 0,
  departurePort: 'CMB',
  departureDateTime: new Date(), //Both date and time from two UI elements
  arrivalPort: 'CMB',
  arrivalDateTime: new Date(),
  carrier: 'UL' //Air Line UL-Sri Lankan Air Lines
};

const SampleAirFare: IAirFare = {
  fareID: null,
  flights: null,
  seatClass: 'Economy',
  meals: true,
  wifi: false,
  price: {adult: 0, kid: 0},
  availableStartDate: new Date(),
  availableEndDate: null,
  availableType: FIXED_DATE,
  baggage: 25
};

export const airFareInitialState: AirFareState = {
  tourID: null,
  activeFare: null,
  airTours: [],
  activeDates: null,
  activeDateType: ACTION_TYPES.FIXED_DATE,
  airlines: null,
  airports: null
};

const newActiveFare = (activeFare: IAirFare | null, activeDates: ActiveDates, activeDateType: string) => {
  activeFare = {...SampleAirFare};
  // activeFare = JSON.parse(JSON.stringify(SampleAirFare));
  const flight = {...SampleFlight};
  // const flight = JSON.parse(JSON.stringify(SampleFAirFare));

  flight.orderNumber = 1;
  flight.departureDateTime = activeDates.start;
  flight.arrivalDateTime = activeDates.start;
  activeFare.flights = [flight];

  activeFare.availableStartDate = activeDates.start;
  activeFare.availableEndDate = activeDates.end;
  activeFare.availableType = activeDateType;

  return activeFare;
};

const cloneActiveFare = (airFare: IAirFare, activeDates: ActiveDates, activeDateType: string) => {
  // const activeFare = Object.assign({}, removeTypeName(airFare));
  const activeFare = Object.assign({}, airFare);

  activeFare.availableStartDate = (activeDateType === DAILY_DEPARTURE || !airFare.flights) ? activeDates.start :
    airFare.flights[0].departureDateTime;
  activeFare.availableEndDate = activeDates.end;
  activeFare.availableType = activeDateType;
  activeFare.fareID = null;

  return activeFare;
};

const addNewFlight = (activeFare: IAirFare) => {
  if (activeFare) {
    if (!activeFare.flights) {
      activeFare.flights = [];
    }

    const flights = activeFare.flights.slice();

    if (flights) {
      const flight: IFlight = {...SampleFlight};
      flight.orderNumber = flights.length + 1;

      if (flights.length > 0) { //to get data from previous flight
        flight.departurePort = flights[flights.length - 1].arrivalPort;
        flight.arrivalPort = flights[flights.length - 1].departurePort;
        flight.carrier = flights[flights.length - 1].carrier;
        flight.departureDateTime = new Date(flights[flights.length - 1].arrivalDateTime.getTime());
        flight.arrivalDateTime = new Date(flight.departureDateTime.getTime());
        // flight.arrivalDateTime.setDate(flight.arrivalDateTime.getDate() + 1) // testing option
      } else {
        // :TODO departureDateTime from activeDates
      }

      flights.push(flight);
      activeFare.flights = flights;
    }
  }

  return activeFare;
};

/**
 * increase arrival date to future time than departure datetime
 * @param departure
 * @param arrival
 * @param departureArrivalDateDifference - is the difference between times as days if they are overnight different.
 */
const increaseDateToHigherTime = (departure: Date, arrival: Date, departureArrivalDateDifference: number) => {
  arrival = new Date(arrival);
  arrival.setFullYear(departure.getFullYear());
  arrival.setMonth(departure.getMonth());

  arrival.setDate(departure.getDate() + departureArrivalDateDifference);

  if (arrival.getTime() >= departure.getTime()) {
    return arrival;
  }

  return new Date(departure.getTime());
};

const updateFlight = (activeFare: IAirFare, updatedFlight: IFlight) => {
  if (activeFare.flights) {
    const flights = activeFare.flights.slice();
    const prevFlightIndex = flights.findIndex(element => element.orderNumber === updatedFlight.orderNumber);

    if (updatedFlight.arrivalDateTime.getTime() < updatedFlight.departureDateTime.getTime()) {

      const prevFlight = flights[prevFlightIndex];
      const departureArrivalDateDifference =
        getOverNightDifferenceInDays(prevFlight.departureDateTime, prevFlight.arrivalDateTime);

      updatedFlight.arrivalDateTime = increaseDateToHigherTime(updatedFlight.departureDateTime,
        updatedFlight.arrivalDateTime, departureArrivalDateDifference);

      // updatedFlight.arrivalDateTime = new Date(updatedFlight.departureDateTime.getTime());
    }

    flights.splice(prevFlightIndex, 1, updatedFlight);
    activeFare.flights = flights;
  }

  if (updatedFlight.orderNumber === 1) {
    activeFare.availableStartDate = updatedFlight.departureDateTime;
  }

  return activeFare;
};

const updateTicketPrice = (activeFare: IAirFare, ticketPrice: ITicketPrice) => {
  activeFare.price = ticketPrice;
  return activeFare;
};

const updateTicketClass = (activeFare: IAirFare, seatClass: string) => {
  activeFare.seatClass = seatClass;
  return activeFare;
};

export function airFareReducer(state = airFareInitialState, action: FlightActionTypes): AirFareState {
  switch (action.type) {
    case ACTION_TYPES.SET_ACTIVE_DATE_TYPE: {
      const activeFare: IAirFare | null = state.activeFare;

      if (activeFare) {
        activeFare.availableType = action.payload;
      }
      return {
        ...state,
        activeDateType: action.payload,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.SET_ACTIVE_DATE: {
      let activeFare: IAirFare | null = state.activeFare;
      const activeDates = action.payload;
      if (activeFare && activeDates) {
        activeFare.availableStartDate = activeDates.start;
        activeFare.availableEndDate = activeDates.end;

        if (activeFare.fareID) { // click another day to view available fares.
          // console.log('existing fare');
          activeFare = null;
        }
      }

      return {
        ...state,
        activeDates: action.payload,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.SET_ACTIVE_FARE: {
      if (!state.activeDates) {
        return {
          ...state
        };
      }

      let activeFare: IAirFare | null = state.activeFare;
      if (!activeFare) { // add new itinerary.
        activeFare = newActiveFare(activeFare, state.activeDates, state.activeDateType);
      }

      if (action.payload) { //clicked a date on calendar
        activeFare = action.payload;
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.ADD_FLIGHT: {
      let activeFare: IAirFare | null = state.activeFare;
      if (activeFare) {
        activeFare = addNewFlight(activeFare);
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.SET_FLIGHT: //update flight with mutated data
    {
      let activeFare: IAirFare | null = state.activeFare;
      if (activeFare) {
        activeFare = updateFlight(activeFare, action.payload);
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.SET_TICKET_PRICE: {
      let activeFare: IAirFare | null = state.activeFare;
      if (activeFare) {
        activeFare = updateTicketPrice(activeFare, action.payload);
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.SET_TICKET_CLASS: {
      let activeFare: IAirFare | null = state.activeFare;
      if (activeFare) {
        activeFare = updateTicketClass(activeFare, action.payload);
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.SET_FLIGHT_ID: // on done click
    {
      let activeFare: IAirFare | null = state.activeFare;
      if (activeFare) {
        activeFare.fareID = action.payload;
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.CLONE_ACTIVE_FARE: {
      if (!state.activeDates) {
        return {
          ...state
        };
      }

      let activeFare: IAirFare | null = state.activeFare;
      if (!activeFare) { // add new itinerary.
        activeFare = cloneActiveFare(action.payload, state.activeDates, state.activeDateType);
      }

      return {
        ...state,
        activeFare: activeFare
      };
    }
    case ACTION_TYPES.RESET_FLIGHT_EDITOR: {
      return {
        ...state,
        activeFare: null
      };
    }
    case ACTION_TYPES.SET_AIRLINES: {
      return {
        ...state,
        airlines: action.payload
      }
    }
    case ACTION_TYPES.SET_AIRPORTS: {
      return {
        ...state,
        airports: action.payload
      }
    }
    case ACTION_TYPES.SET_BAGGAGE_SIZE: {
      return {
        ...state,
        activeFare: state.activeFare ? {...state.activeFare, baggage: action.payload} : state.activeFare
      }
    }
    case ACTION_TYPES.SET_IN_FLIGHT_FACILITIES: {
      return {
        ...state,
        activeFare: state.activeFare ? {...state.activeFare, meals: action.payload.meals, wifi: action.payload.wifi} :
          state.activeFare
      }
    }
    default:
      return {
        ...state
      };
  }
}