import React from 'react';
import {Destination} from "../../../types/storeTypes";
import SearchResultItemTag from "./SearchResultItemTag";

type TourDestinationsProps = {
  destinations: Destination[]
  maxDestinationsToShow?: number
}

const TourDestinations: React.FC<TourDestinationsProps> = (props) => {
  const {destinations, maxDestinationsToShow} = props;
  const MAX_DESTINATIONS_TO_SHOW = 3;

  const maxToShow = maxDestinationsToShow ? maxDestinationsToShow : MAX_DESTINATIONS_TO_SHOW;

  const getDestinationTags = () => {
    let destinationTags = [];

    let i = 0;
    for (i; i < destinations.length; i++) {
      if (i === maxToShow) {
        destinationTags.push(<SearchResultItemTag text={destinations.length - 3 + '+'} key={i}/>);
        break;
      }
      destinationTags.push(
        <SearchResultItemTag text={destinations[i].name} iconClass="icon-map-pin" key={i}/>
      );
    }

    return destinationTags;
  };


  return (
    <div className="th-search-result-item-locations">
      {getDestinationTags()}
    </div>
  );
};

export default TourDestinations;