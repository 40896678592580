import React, {PropsWithChildren} from 'react';
import {Col, Row} from "react-bootstrap";
import {UISizes} from "../../../../types/UISizes";
import {OutlineLabel} from "../OutlineLabel";

type MenuDropdownLabelListProps = {
  onItemSelected?: () => void
}

const CurrencyDropdownLabelList: React.FC<MenuDropdownLabelListProps> =
  (props: PropsWithChildren<MenuDropdownLabelListProps>) => {

    const handleOnOptionClicked = () => {
      props.onItemSelected && props.onItemSelected();
    }

    return (
      <div className='installment-select-container'>
        <Row className=' px-3'>
          <Col xs={12}>
            <OutlineLabel onClick={handleOnOptionClicked} size={UISizes.sm} minWidth={14} active={true}
                          fontBold={true} className='clickable'>
              <OutlineLabel.Label>Sri Lankan Rupees ( Rs. )</OutlineLabel.Label>
            </OutlineLabel>

            <OutlineLabel size={UISizes.sm} minWidth={14} disabled={true}>
              <OutlineLabel.Label>United States Dollars </OutlineLabel.Label> (<OutlineLabel.Icon
              iconClass='icon-dollar-sign'/>)
            </OutlineLabel>
          </Col>
        </Row>
      </div>
    )
  };

export default CurrencyDropdownLabelList;