export const CDN_PATH = 'https://toursharbor.s3';

export const airPorts = [
  {value: 'CMB', label: 'CMB'},
  {value: 'DPS', label: 'DPS'},
  {value: 'SIN', label: 'SINGAPORE'},
  {value: 'KUL', label: 'KUL'},
  {value: 'CHN', label: 'CHN'},
  {value: 'JFK', label: 'JFK', isFixed: true},
  {value: 'JIA', label: 'JIA'},
  {value: 'LON', label: 'LON'},
  {value: 'BKK', label: 'BKK'},
  {value: 'LHR', label: 'LHR', isDisabled: true},
];

  export const countries = [
    {value: 'USA', label: 'United States of America'},
    {value: 'LK', label: 'Sri Lanka'},
    {value: 'SG', label: 'Singapore'},
    {value: 'IN', label: 'India'},
  ];
  
  export const banks = [
    {value: 'Sampath', label: 'Sampath Bank'},
    {value: 'HNB', label: 'HNB'},
    {value: 'Commercial', label: 'Commercial Bank of Ceylon'},
    {value: 'HSBC', label: 'HSBC'},
    {value: 'NTB', label: 'Nations Trust Bank'},
  ];

export const findCountryByValue = (countryValue: string) => {
  return countries.find(country => country.value === countryValue);
};

export const findBankByValue = (bankValue: string) => {
  return banks.find(bank => bank.value === bankValue);
};

export const findAirPortByValue = (airPortValue: string) => {
  return airPorts.find(airPort => airPort.value === airPortValue);
};

export const airCarriers = [
  {value: 'Sri Lankan Air Lines', label: 'Sri Lankan Air Lines'},
  {value: 'Singapore Air Lines', label: 'Singapore Air Lines'},
  {value: 'Cathy Pacific', label: 'Cathy Pacific'},
  {value: 'Quantas', label: 'Quantas'},
  {value: 'Thai Air Ways', label: 'Thai Air Ways'},
];

export const flyClasses = [
  {value: 'Economy', label: 'Economy'},
  {value: 'Economy flexi', label: 'Economy flexi'},
  {value: 'Business class', label: 'Business class'},
  {value: 'First class', label: 'First class'},
];

export const TABLE_FILTERS = {
  recent: 'recent',
  bookmarked: 'bookmarked',
  active: 'active',
  all: 'all',
};

export const tourTableFilters = [
  {value: TABLE_FILTERS.recent, label: 'Recent'},
  {value: TABLE_FILTERS.bookmarked, label: 'Bookmarked Only', isDisabled: true},
  {value: TABLE_FILTERS.active, label: 'Active Only'},
  {value: TABLE_FILTERS.all, label: 'All Tours (A - Z)'},
];

export const INCLUDED = 'included';
export const NOT_INCLUDED = 'NOT included';
export const NA = 'N/A'; //not applicable

export const APP_NAME = 'Tours Harbor';
export const PROCESSING_TIMEOUT = 500;
export const STORAGE_USER = 'user';

export enum ReviewStatus {
  REQUESTED = 'requested',
  APPROVED = 'approved',
  REJECTED = 'rejected'
}

export enum ModeratorInvitationStatus {
  INVITED = 'invited',
  JOINED = 'joined',
  BLOCKED = 'blocked',
  EXPIRED = 'expired'
}

export enum MyBookingsFilter {
  ALL = 'all',
  UPCOMING = 'upcoming',
  COMPLETED ='completed',
  CANCELLED = 'cancelled',
}