import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk';
import {rootReducer} from './reducers';
import {composeWithDevTools} from 'redux-devtools-extension';

export default function configureStore(preloadedState?: any) {
  // return createStore(rootReducer, preloadedState, compose(applyMiddleware(thunkMiddleware)));
  // return createStore(rootReducer, preloadedState, composeWithDevTools(applyMiddleware(thunkMiddleware)));

  let persistedState = {};
  const localStoreState = localStorage.getItem('rx'); //rx = redux Storage
  if (localStoreState) {
    persistedState = JSON.parse(localStoreState);
  }

  const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(thunkMiddleware))); //dev
  // const store = createStore(rootReducer, persistedState, compose(applyMiddleware(thunkMiddleware))); //production

  store.subscribe(() => {
    localStorage.setItem('rx', JSON.stringify(store.getState()));
  });
  return store;
}