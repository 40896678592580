import React from 'react';
import {useDispatch} from "react-redux";
import {AIR_TRAVEL_EDITOR} from "../../constants/actions";
import {setActiveEditor} from "../../store/actions/tourlineActions";

type AirInfoProps = {
  title: string,
}

const AirInfoItem: React.FC<AirInfoProps> = (props) => {
  const  dispatch = useDispatch();

  return (
    <div className='sub-item air-info-item'>
      <div className='connector'/>
      <div className='sub-item-content air-info-content' onClick={() => dispatch(setActiveEditor(AIR_TRAVEL_EDITOR))}>
        <p>{props.title}</p>
      </div>
    </div>
  )
};

export default AirInfoItem;