import React from 'react';
import Facility from "./FacilityItem";
import FacilityItemEditor from "./FacilityItemEditor";
import {CategorizedFacilities, FacilityData} from "./AllFacilities";
import {useSelector} from "react-redux";
import {AppState} from "../../../../store/reducers";

const FacilityGroup: React.FC<CategorizedFacilities> = (props) => {
  const {category, facilities} = props;
  const facilityEditorID: string | null = useSelector((state: AppState) => state.tour.facilityEditorID);

  const renderFacilityList = () => {
    return(
      facilities.map((facility: FacilityData, index) => {
        if (facilityEditorID && facilityEditorID === facility.id) {
          return <FacilityItemEditor facility={facility} key={index}/>
        }
        return <Facility id={facility.id} icon={facility.icon} title={facility.name} key={index} />
      })
    )
  };

  return (
    <div className='facility-group pl-1 pr-1'>
      <p className="facility-category">{category}</p>
      {renderFacilityList()}
    </div>
  );
};

export default FacilityGroup;