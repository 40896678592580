import gql from "graphql-tag";

export const AirFareFragment = gql`
  fragment AirFareFields on AirFare{
    tour{
       _id
    }
    availableType: activeDateType
    availableStartDate
    availableEndDate
    seatClass
    flights{
      orderNumber
      departurePort
      departureDateTime
      arrivalPort
      arrivalDateTime
      carrier
    }
    price {
      adult
      kid:master
    }    
    meals
    wifi
    baggage  
  }    
`;