import React, {FormEvent, useState} from "react";
import { NavLink, Redirect } from "react-router-dom";
import Aux from "../../../hoc/_Aux";
import { API_ROUTES } from "../../../routes/routes";
import axios from "axios";
import USER_ROLES from "../../../constants/roles";
import { Form } from "react-bootstrap";
import RandomBackground from "../../../utils/RandomBackground";
import {usernameRegex} from "./SignUp2";
import {getLoadingSpan} from "../../../utils/loading/loading";
import {STORAGE_USER} from "../../../constants/values";

const HostSignUp: React.FC = () => {
  const [signUpState, setSignUpState] = useState<SignUpState>({
    host: null,
    username: null,
    password: null,
    email: null,
    isLoginError: false,
    isUsernameInvalid: false,
    tocAgreed: false,
    message: null,
    redirect: false,
    processing: false
  });

  const handleHostNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignUpState({ ...signUpState, host: event.target.value.trim() });
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.match(usernameRegex) || value === '') {
      setSignUpState({ ...signUpState, username: event.target.value, isUsernameInvalid: false });
      return;
    }

    setSignUpState({ ...signUpState, username: event.target.value, isUsernameInvalid: true });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignUpState({ ...signUpState, password: event.target.value });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignUpState({ ...signUpState, email: event.target.value });
  };

  const handleOnTermsAgreementChange = () => {
    setSignUpState({ ...signUpState, tocAgreed: !signUpState.tocAgreed });
  };

  const   handleRegistration = (data: any) => {
    // const user = { auth: true, token: "a", role: "admin" };
    const user = {
      auth: data.auth,
      token: data.token,
      role: data.role,
      name: data.name
      // company: data.company
    };

    // if (
    //   user.role !== USER_ROLES.SUPER_ADMIN &&
    //   user.role !== USER_ROLES.AGENCY_ADMIN &&
    //   user.role !== USER_ROLES.AGENCY_USER
    // ) {
    //   setSignUpState({
    //     ...signUpState,
    //     message: "Unauthorized user account!",
    //     isLoginError: true
    //   });
    //   return;
    // }

    localStorage.setItem(STORAGE_USER, JSON.stringify(user));
    // if (this.props.history.length > 1) {
    //   this.props.history.goBack();
    // }

    // if (data.role === ROLES.owner) {
    //   this.props.history.push("/");
    // } else {
    //   //role agent
    //   this.props.history.push("/pos");
    // }

    setSignUpState({ ...signUpState, redirect: true });
    // this.props.history.push("/");
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setSignUpState({
      ...signUpState,
      message: "",
      isLoginError: false,
      processing: true
    });

    if (!signUpState.tocAgreed) {
      alert('please check \'I agree terms & conditions\' to continue');
      setSignUpState({
        ...signUpState,
        processing: false
      });
      return;
    }

    if (signUpState.isUsernameInvalid) {
      return;
    }

    const auth = {
      username: signUpState.username,
      password: signUpState.password,
      email: signUpState.email,
      hostname: signUpState.host,
      role: "host",
      login_type: "password"
    };

    axios
      .post(API_ROUTES.REGISTER, auth)
      .then((res: any) => {
        if (res.data.auth) {
          setSignUpState({ ...signUpState, isLoginError: false });
          handleRegistration(res.data);
        }
      })
      .catch((err: any) => {
        if (!err.response) {
          setSignUpState({
            ...signUpState,
            message: "Problem occurred. Try again!",
            isLoginError: true
          });
        }

        const message = err.response.data;
        localStorage.removeItem("user");

        if (err.response.status === 403) {
          setSignUpState({
            ...signUpState,
            message: message,
            isLoginError: true
          });
        }

        if (err.response.status === 400) {
          setSignUpState({
            ...signUpState,
            message: "Problem occurred. Try again!",
            isLoginError: true
          });
        }
      });
  };

  const renderRedirect = () => {
    if (signUpState.redirect) {
      return <Redirect to={"/admin/tourline/create"} />;
    }
  };

  return (
    <Aux>
      {renderRedirect()}

      <div className="auth-wrapper aut-bg-img-side container-fluid th-no-padding align-items-stretch">
        <div className="row align-items-center w-100 align-items-stretch bg-white">
            <div className="col-md-8 host-ad pt-5">
              <h1 className="text-white mb-4 font-weight-bold bg-title">Get your tours onboard. <br/> It's Free!</h1>
              <p className="text-white no-fees">
                No membership fees, no commissions.
              </p>
              <h4 className='mt-5 steps-title'>You are just 3 steps away:</h4>
              <p className='steps'>1. Sign up now.<br/> 2. Host you tours.<br/> 3. Start earning.</p>
              <p className='bottom-title'>Reach millions of travellers worldwide in few minutes &<br/> sell like a pro
                with Tours Harbor.
              </p>
            </div>
          <div className="col-lg-4 align-items-stret h-100 align-items-lg-center d-flex justify-content-center">
            <div className=" auth-content text-center">
              <div className="mb-4">
                <i className="feather icon-user-plus auth-icon" />
              </div>
              <h3 className="mb-4">Sign up</h3>
              <Form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Hostname / Your company name"
                    required
                    onChange={handleHostNameChange}
                  />
                </div>

                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Username"
                    required
                    onChange={handleUsernameChange}
                  />
                </div>
                <div className="username-error-container">
                  {signUpState.isUsernameInvalid && (
                    <p className="username-error text-left">username can contain a-z, A-Z, numbers and - only</p>
                  )}
                </div>
                <div className="input-group mt-3 mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    required
                    onChange={handleEmailChange}
                  />
                </div>
                <div className="input-group mb-4">
                  <input
                    type="password"
                    className="form-control"
                    required
                    placeholder="password"
                    onChange={handlePasswordChange}
                  />
                </div>
                <div className="form-group text-left">
                  <div className="checkbox checkbox-fill d-inline">
                    <Form.Control type="checkbox" name="checkbox-fill-2" id="checkbox-fill-2"
                                  defaultChecked={signUpState.tocAgreed}
                                  onChange={handleOnTermsAgreementChange}
                    />
                    <Form.Label htmlFor="checkbox-fill-2" className="cr">
                      I agree the <a href="#!">terms & conditions</a> of Tours Harbor.
                    </Form.Label>

                  </div>
                </div>
                <div className="error-container">
                  {signUpState.isLoginError && (
                    <p className="login-error">{signUpState.message}</p>
                  )}
                </div>
                <button type="submit" className="btn btn-primary shadow-2 mb-4">
                  {signUpState.processing && getLoadingSpan()}
                  Sign up
                </button>
              </Form>
              <p className="mb-0 text-muted">
                Already have a host account? <NavLink to="/login">Login</NavLink>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Aux>
  );
};

interface SignUpState {
  host: string | null;
  username: string | null;
  password: string | null;
  email: string | null;
  isLoginError: boolean;
  isUsernameInvalid: boolean;
  message: string | null;
  redirect: boolean;
  tocAgreed: boolean;
  processing: boolean;
}

export default HostSignUp;
