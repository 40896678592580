import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActiveEditor} from "../../../store/actions/tourlineActions";
import {SUMMARY_EDITOR} from "../../../constants/actions";
import {AppState} from "../../../store/reducers";

const SaveTourLabel: React.FC = () => {
  const dispatch = useDispatch();
  const errorList: string[] = useSelector((state: AppState) => state.tour.errorSummary);

  const isPublishReady = errorList.length === 0;

  return (
    <p className={`publish-changes ${isPublishReady ? 'publish-ready' : ''}`}
      onClick={() => dispatch(setActiveEditor(SUMMARY_EDITOR))}>
      <i className='feather icon-check-circle'/> Publish Now
    </p>
  )
};

export default SaveTourLabel;