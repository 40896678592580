import React, {PropsWithChildren} from 'react';
import {UISizes} from "../../../types/UISizes";
import Icon from "../atoms/Icon";
import Label from "../atoms/Label";

type LabelTagProps = {
  size?: UISizes.sm | UISizes.md | UISizes.lg
  fontBold?: boolean
  active?: boolean
  disabled?: boolean
  minWidth?: number
  className?: string
  onClick?: () => void
}

const OutlineLabel = (props: PropsWithChildren<LabelTagProps>) => {
  const {size, fontBold, active, disabled, minWidth, className} = props;

  return (
    <div className={`px-2 py-1 outline-label ${className} mb-2
      ${size} ${active ? 'active' : ''}
      ${minWidth ? 'w-' + minWidth : ''}
      ${disabled ? 'disabled' : ''} 
      ${fontBold ? 'bold' : ''}`}
         onClick={props.onClick}>
      {props.children}
    </div>
  )
}

OutlineLabel.Icon = Icon;
OutlineLabel.Label = Label;

export {OutlineLabel};